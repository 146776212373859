import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Button } from '@mui/material';
import Textarea from 'rc-textarea';
import { AppSettings } from '../states/SettingsState';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EditMarkdown({
  onClose,
  onSubmit,
  markdown,
  minHeight,
}) {
  const [value, setValue] = React.useState(0);

  const [markdownText, setMarkdownText] = React.useState(markdown);

  const setting = useRecoilValue(AppSettings);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="mb-5">
      <Box
        sx={{ width: '100%', minHeight: minHeight || '20rem' }}
        className="border-[#1682FC] border">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Markdown" {...a11yProps(0)} />
            <Tab label="Preview" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              '& textarea:focus': {
                boxShadow: 'none',
              },
            }}>
            <Textarea
              className="bg-primary"
              style={{ width: '100%', border: '0px' }}
              onFocus={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setMarkdownText(e.target.value);
              }}
              value={markdownText}
              autoSize
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div id="auto-generate-doc" className="p-4">
            <MarkdownPreview
              source={markdownText}
              className="mb-6 bg-primary"
              wrapperElement={{
                'data-color-mode': setting.theme,
              }}
            />
          </div>
        </TabPanel>
      </Box>
      <Box className="flex justify-end my-2 space-x-2">
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={() => onSubmit(markdownText)}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
