import { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { QUERY } from '../../constants/AppConstants';
import { AppSettings } from '../../states/SettingsState';
import ABIService from '../../services/API/ABIService';
import Messages from '../../constants/Messages';
import TelemetryService from '../../services/TelemetryService';
import { TelemetryEvents } from '../../constants/EventsConstants';
import { IsShareLoadingState } from '../../states/LoadingState';
import CollectionService from '../../services/CollectionService';
import IconWrapper from '../../components/IconWrapper';
import useTabs from '../../hooks/useTabs';
import DocsServices from '../../services/DocsServices';
import { CollectionListState } from '../../states/CollectionState';

export function ShareABIs({ isShareIconWithBtn, selectedCollection }) {
  const [settings] = useRecoilState(AppSettings);
  const matches = useMediaQuery('(min-width:751px)');
  const isShareLoading = useRecoilValue(IsShareLoadingState);
  const [clipboardShareABIData, setClipboardShareABIData] = useState('');
  const [clipboardShareDocsData, setClipboardShareDocsData] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const setIsShareLoading = useSetRecoilState(IsShareLoadingState);

  const { selectedTab } = useTabs();
  const collection = CollectionService.findCollection(selectedTab);

  // all ABI list
  const collectionList = useRecoilValue(CollectionListState);

  const handleOpenShareABI = () => {
    setShowDialog(true);
  };

  const handleCloseShareABI = () => {
    setShowDialog(false);
  };

  const copyFunction = (isClipboardShareABI) => {
    if (isClipboardShareABI) {
      navigator.clipboard.writeText(clipboardShareABIData);
      TelemetryService.sendEvent(TelemetryEvents.SHARE_ABI_COPY, {
        url: clipboardShareABIData,
      });
    } else {
      navigator.clipboard.writeText(clipboardShareDocsData);
      TelemetryService.sendEvent(TelemetryEvents.SHARE_DOCS_COPY, {
        url: clipboardShareDocsData,
      });
    }
    toast.success(Messages.INFO.COPY_CLIPBOARD);
  };

  const shareCollection = async (_c) => {
    const { abi, parentId, id, meta, documentation } = _c;

    const name = meta?.name;
    const address = CollectionService?.getActiveContractAddress(meta);
    const chainId = CollectionService?.getActiveChain(meta);

    TelemetryService.sendEvent(TelemetryEvents.SIDE_BAR_SHARE_CLICK, {
      id,
      name,
      chainId,
      parentId,
      address,
    });
    try {
      setIsShareLoading(true);
      const options = {
        meta: { ...meta, documentation },
        abi,
        parentToken: parentId,
        token: id,
      };
      const response = await ABIService.shareABI(options);
      setIsShareLoading(false);
      setClipboardShareABIData(
        `${window.location.origin}${window.location.pathname}?${
          QUERY.SHARE_QUERY
        }=${get(response, 'data.token')}`
      );
      setClipboardShareDocsData(
        `${window.location.origin}/docs${window.location.pathname}?${
          QUERY.SHARE_QUERY
        }=${get(response, 'data.token')}`
      );
    } catch (e) {
      setIsShareLoading(false);
    }
  };

  useEffect(() => {
    if (showDialog) {
      DocsServices.setDocumentationInAbi({
        collectionList,
        activeCollection: selectedCollection || collection,
      });
      setTimeout(() => {
        shareCollection(selectedCollection || collection);
      }, 0);
    }
  }, [showDialog]);

  return (
    <>
      <Tooltip title="Share ABI">
        <IconButton className="!p-0" color="primary" aria-label="Share ABI">
          {isShareIconWithBtn && (
            <Button
              variant="contained"
              size="medium"
              className="inline-flex w-full items-center text-sm ml-2 h-10"
              // endIcon={
              //   <IconWrapper>
              //     <ShareIcon className="!w-full !h-full mb-1" />
              //   </IconWrapper>
              // }
              onClick={(event) => {
                event.stopPropagation();
                handleOpenShareABI();
              }}
              disabled={isEmpty(collection)}>
              Share ABI
            </Button>
          )}
          {!isShareIconWithBtn && (
            <div className="h-4 justify-center flex">
              <ShareIcon
                className="!w-full !h-full"
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenShareABI();
                }}
              />
            </div>
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth={`${matches ? 'sm' : 'xs'}`}
        open={showDialog}
        onClose={(e) => {
          e.stopPropagation();
          handleCloseShareABI();
        }}>
        <Box className="ml-1 flex space-between">
          <p className=" text-center mt-2 ml-2 font-bold mr-auto text-lg">
            Share Docs
          </p>
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={(e) => {
                e.stopPropagation();
                handleCloseShareABI();
              }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        <div className="pl-3 pt-3 p-4 ">
          <p className="pt-2 pl-4 md:text-[0.95rem] text-[0.75rem]">
            Sharing this Docs is now as easy as copy pasting this link. Share
            this link with your friends now
          </p>
        </div>

        {/* <div className="pb-5">
          <div className="pb-0 pl-7 pr-7 pt-0">
            <Box
              className={` ${
                settings.theme === 'dark'
                  ? 'border border-opacity-25 border-white'
                  : 'border-2 border-opacity-20 border-black'
              } border-dotted float-left h-12 w-full mb-2 flex`}>
              <div className="flex items-center ml-2">
                <LinkIcon
                  className="ml-auto items-center text-xl"
                  fontSize={`${matches ? 'large' : 'small'}`}
                />
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!clipboardShareABIData && !isShareLoading) {
                      return;
                    }

                    navigator.clipboard.writeText(clipboardShareABIData);
                    toast.success(Messages.INFO.COPY_CLIPBOARD);
                    TelemetryService.sendEvent(TelemetryEvents.SHARE_ABI_COPY, {
                      url: clipboardShareABIData,
                    });
                  }}
                  className={`pl-2 ${
                    clipboardShareABIData && !isShareLoading && 'cursor-pointer'
                  } text-[0.59rem] md:text-base font-bold`}>
                  {isShareLoading
                    ? 'Generating Link'
                    : clipboardShareABIData || 'Sorry, Unable to Share'}
                </p>
              </div>

              {!isShareLoading && clipboardShareABIData ? (
                <div>
                  <Divider orientation="vertical" flexItem></Divider>
                  <div className=" pl-2 pr-1">
                    <Tooltip
                      title="Copy Link"
                      className="h-11 w-12 mt-2 items-center">
                      <IconButton
                        className=""
                        variant="contained"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyFunction(true);
                        }}>
                        <div className="flex justify-center">
                          <IconWrapper>
                            <CopyAllIcon className="flex justify-center mb-[0.6rem]" />
                          </IconWrapper>
                        </div>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="p-2"> </div>
              )}
            </Box>
          </div>
        </div> */}

        <div className="pb-5">
          <div className="pb-0 pl-7 pr-7 pt-0">
            <Box
              className={` ${
                settings.theme === 'dark'
                  ? 'border border-opacity-25 border-white'
                  : 'border-2 border-opacity-20 border-black'
              } border-dotted float-left h-12 w-full mb-2 flex`}>
              <div className="flex items-center ml-2">
                <LinkIcon
                  className="ml-auto items-center text-xl"
                  fontSize={`${matches ? 'large' : 'small'}`}
                />
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!clipboardShareDocsData && !isShareLoading) {
                      return;
                    }

                    navigator.clipboard.writeText(clipboardShareDocsData);
                    toast.success(Messages.INFO.COPY_CLIPBOARD);
                    TelemetryService.sendEvent(
                      TelemetryEvents.SHARE_DOCS_COPY,
                      {
                        url: clipboardShareDocsData,
                      }
                    );
                  }}
                  className={`pl-2 ${
                    clipboardShareDocsData &&
                    !isShareLoading &&
                    'cursor-pointer'
                  } text-[0.59rem] md:text-base font-bold`}>
                  {isShareLoading
                    ? 'Generating Link'
                    : clipboardShareDocsData || 'Sorry, Unable to Share'}
                </p>
              </div>

              {!isShareLoading && clipboardShareDocsData ? (
                <div>
                  <Divider orientation="vertical" flexItem></Divider>
                  <div className=" pl-2 pr-1">
                    <Tooltip
                      title="Copy Link"
                      className="h-11 w-12 mt-2 items-center">
                      <IconButton
                        className=""
                        variant="contained"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyFunction();
                        }}>
                        <div className="flex justify-center">
                          <IconWrapper>
                            <CopyAllIcon className="flex justify-center mb-[0.6rem]" />
                          </IconWrapper>
                        </div>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="p-2"> </div>
              )}
            </Box>
          </div>
        </div>

        <div className="justify-between items-center rounded-none bg-primary pl-8 pt-0 flex border-t border-secondary">
          <div>
            <p className=" text-[0.67rem] md:text-base items-center flex items-top ml-0 ">
              How can we improve? Give us your feedback here
            </p>
          </div>
          <div className="items-center flex items-top ">
            <a
              href="https://twitter.com/web3client"
              target="_blank"
              rel="noreferrer"
              className="text-sm mr-3 ml-auto items-center w-4 ">
              <TwitterIcon
                fontSize={`${matches ? 'medium' : 'small'}`}
                className="mr-3 ml-auto items-center w-4 text-xl"
              />
            </a>
            <a href="https://t.me/web3client" target="_blank" rel="noreferrer">
              <TelegramIcon
                fontSize={`${matches ? 'medium' : 'small'}`}
                className="mr-3 ml-2 items-center text-xl"
              />
            </a>

            <a
              href="https://discord.gg/5gzhtx94hM"
              target="_blank"
              rel="noreferrer"
              className="text-sm mr-3 ml-auto items-center">
              <div className="text-sm mr-3 ml-auto items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={`${matches ? '23px' : '21px'}`}
                  height={`${matches ? '43px' : '39px'}`}
                  viewBox="0 0 512 512"
                  fill="#5865f2"
                  aria-label="Discord"
                  role="img"
                  version="1.1">
                  <path
                    d="m386 137c-24-11-49.5-19-76.3-23.7c-.5 0-1 0-1.2.6c-3.3 5.9-7 13.5-9.5 19.5c-29-4.3-57.5-4.3-85.7 0c-2.6-6.2-6.3-13.7-10-19.5c-.3-.4-.7-.7-1.2-.6c-23 4.6-52.4 13-76 23.7c-.2 0-.4.2-.5.4c-49 73-62 143-55 213c0 .3.2.7.5 1c32 23.6 63 38 93.6 47.3c.5 0 1 0 1.3-.4c7.2-9.8 13.6-20.2 19.2-31.2c.3-.6 0-1.4-.7-1.6c-10-4-20-8.6-29.3-14c-.7-.4-.8-1.5 0-2c2-1.5 4-3 5.8-4.5c.3-.3.8-.3 1.2-.2c61.4 28 128 28 188 0c.4-.2.9-.1 1.2.1c1.9 1.6 3.8 3.1 5.8 4.6c.7.5.6 1.6 0 2c-9.3 5.5-19 10-29.3 14c-.7.3-1 1-.6 1.7c5.6 11 12.1 21.3 19 31c.3.4.8.6 1.3.4c30.6-9.5 61.7-23.8 93.8-47.3c.3-.2.5-.5.5-1c7.8-80.9-13.1-151-55.4-213c0-.2-.3-.4-.5-.4Zm-192 171c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38zm125 0c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38z"
                    fill="var(--color-text-primary)"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </Dialog>
    </>
  );
}
