import {
  Drawer,
  Tooltip,
  IconButton,
  //   MenuItem,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useEffect, useState } from 'react';
// import set from 'lodash/set';
import { useRecoilValue } from 'recoil';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import get from 'lodash/get';
import MarkdownPreview from '@uiw/react-markdown-preview';
import IconWrapper from '../../components/IconWrapper';
// import CollectionService from '../../services/CollectionService';
// import useTabs from '../../hooks/useTabs';
import { AppSettings } from '../../states/SettingsState';
import Messages from '../../constants/Messages';
// import getClone from '../../utils/getClone';
import EditMarkdown from '../../components/EditMarkdown';
import useTabs from '../../hooks/useTabs';
import CollectionService from '../../services/CollectionService';
import { CollectionListState } from '../../states/CollectionState';
import { DOCS } from '../../constants/AppConstants';
import DocsServices from '../../services/DocsServices';

const AutoGenerateDocsMethod = ({ openDrawer, setOpenDrawer }) => {
  const setting = useRecoilValue(AppSettings);

  const [isEditAbiName, setIsEditAbiName] = useState(false);
  const [isEditAbiDescription, setIsEditAbiDescription] = useState(false);
  const [isEditAbiDetails, setIsEditAbiDetails] = useState(false);

  const [isEditMethodName, setIsEditMethodName] = useState(false);
  const [isEditMethodDescription, setIsEditMethodDescription] = useState(false);
  const [isEditMethodDetails, setIsEditMethodDetails] = useState(false);

  const { selectedTab } = useTabs();

  // all ABI list
  const collectionList = useRecoilValue(CollectionListState);

  const currentCollection = CollectionService.findCollection(selectedTab);
  const currentMethod = CollectionService.findCollectionMethod(selectedTab);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(isOpen);
  };

  //  --------   handle Abi Name  start--------------
  const onAbiNameClose = () => {
    setIsEditAbiName(false);
  };
  const onAbiNameSubmit = (data) => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.ABI_NAME,
      value: data,
      currentCollection,
      currentMethod,
      collectionList,
    });
    setIsEditAbiName(false);
  };
  //  --------   handle Abi Name  end--------------

  //  --------   handle Abi description  start--------------
  const onAbiDescriptionClose = () => {
    setIsEditAbiDescription(false);
  };
  const onAbiDescriptionSubmit = (data) => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.ABI_DESCRIPTION,
      value: data,
      currentCollection,
      currentMethod,
      collectionList,
    });

    setIsEditAbiDescription(false);
  };
  //  --------   handle Abi description start--------------

  //  --------   handle Abi details  start--------------
  const onAbiDetailsClose = () => {
    setIsEditAbiDetails(false);
  };
  const onAbiDetailsSubmit = (data) => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.ABI_DETAILS,
      value: data,
      currentCollection,
      currentMethod,
      collectionList,
    });

    setIsEditAbiDetails(false);
  };
  //  --------   handle Abi details  start--------------

  //  --------   handle Method name  start--------------
  const onMethodNameClose = () => {
    setIsEditMethodName(false);
  };
  const onMethodNameSubmit = (data) => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.METHOD_NAME,
      value: data,
      currentCollection,
      currentMethod,
      collectionList,
    });

    setIsEditMethodName(false);
  };
  //  --------   handle Method name  start--------------

  //  --------   handle Method description  start--------------
  const onMethodDescriptionClose = () => {
    setIsEditMethodDescription(false);
  };
  const onMethodDescriptionSubmit = (data) => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.METHOD_DESCRIPTION,
      value: data,
      currentCollection,
      currentMethod,
      collectionList,
    });

    setIsEditMethodDescription(false);
  };
  //  --------   handle Method description  start--------------

  //  --------   handle Method details  start--------------
  const onMethodDetailsClose = () => {
    setIsEditMethodDetails(false);
  };
  const onMethodDetailsSubmit = (data) => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.METHOD_DETAILS,
      value: data,
      currentCollection,
      currentMethod,
      collectionList,
    });

    setIsEditMethodDetails(false);
  };
  //  --------   handle Method details  start--------------

  const handleRegenerateDocs = () => {
    DocsServices.setDocumentationInMethod({
      updateStatus: DOCS.REGENERATE_ABI_AND_METHOD,
      currentCollection,
      currentMethod,
      collectionList,
    });
  };

  //  display markdown start
  const markdownAbiName = get(
    currentCollection,
    'documentation.content.abiName'
  );
  const markdownAbiDescription = get(
    currentCollection,
    'documentation.content.abiDescription'
  );
  const markdownAbiDetails = get(
    currentCollection,
    'documentation.content.abiDetails'
  );

  const markdownMethodName = get(
    currentCollection,
    `documentation.content.methodName.${get(currentMethod, 'id')}`
  );
  const markdownMethodDescription = get(
    currentCollection,
    `documentation.content.methodDescription.${get(currentMethod, 'id')}`
  );
  const markdownMethodDetails = get(
    currentCollection,
    `documentation.content.methodDetails.${get(currentMethod, 'id')}`
  );

  const fullMarkdownData = `${markdownAbiName} ${markdownAbiDescription} ${markdownAbiDetails} ${markdownMethodName} ${markdownMethodDescription} ${markdownMethodDetails}`;

  //  display markdown end

  useEffect(() => {
    if (!openDrawer) return;
    DocsServices.setDocumentationInAbi({
      collectionList,
      activeCollection: currentCollection,
    });
  }, [openDrawer]);

  function renderDrawer() {
    return (
      <div className="lg:w-[42vw] w-[85vw] flex flex-col h-full p-6 bg-primary">
        <div className="flex items-center mb-5">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <div className="font-bold text-xl">Documentation</div>
              <CopyToClipboard
                text={fullMarkdownData}
                className="cursor-pointer flex items-center ml-2"
                onCopy={() => {
                  toast.success(Messages.INFO.COPY_CLIPBOARD);
                }}>
                <div className="text-xs text-slate-400 ml-2">
                  <Tooltip title="Copy">
                    <FileCopyIcon fontSize="14px" className="mt-0.5" />
                  </Tooltip>
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex ml-auto space-x-3 p-2">
            <Tooltip title="Regenerate Documentation">
              <Link
                className="cursor-pointer flex items-center"
                onClick={handleRegenerateDocs}>
                <ArticleIcon />
                <Typography className="!ml-2">
                  Regenerate Documentation
                </Typography>
              </Link>
            </Tooltip>
            <Tooltip title="close">
              <IconWrapper className="flex">
                <IconButton aria-label="close" onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </IconWrapper>
            </Tooltip>
          </div>
        </div>

        <div className="group">
          {isEditAbiName ? (
            <EditMarkdown
              onClose={onAbiNameClose}
              onSubmit={onAbiNameSubmit}
              markdown={markdownAbiName}
              minHeight="5rem"
            />
          ) : (
            <div id="auto-generate-doc" className="flex space-x-3 items-center">
              {markdownAbiName ? (
                <MarkdownPreview
                  source={`${markdownAbiName}`}
                  className="bg-primary"
                  wrapperElement={{
                    'data-color-mode': setting.theme,
                  }}
                />
              ) : (
                <span className="text-gray-300 text-lg">Abi Name</span>
              )}

              <div className="mb-2">
                <Tooltip title="Edit ABI">
                  <Link
                    className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                    onClick={() => {
                      setIsEditAbiName(true);
                    }}>
                    <div className="hidden group-hover:block">
                      <BorderColorIcon
                        className="text-gray-200 hover:text-gray-500"
                        sx={{ fontSize: '15px' }}
                      />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <div className="group">
          {isEditAbiDescription ? (
            <EditMarkdown
              onClose={onAbiDescriptionClose}
              onSubmit={onAbiDescriptionSubmit}
              markdown={markdownAbiDescription}
              minHeight="5rem"
            />
          ) : (
            <div id="auto-generate-doc" className="flex space-x-3 items-center">
              {markdownAbiDescription ? (
                <MarkdownPreview
                  source={`${markdownAbiDescription}`}
                  className="bg-primary"
                  wrapperElement={{
                    'data-color-mode': setting.theme,
                  }}
                />
              ) : (
                <span className="text-gray-300 text-lg">Description</span>
              )}

              <Tooltip title="Edit ABI">
                <Link
                  className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                  onClick={() => {
                    setIsEditAbiDescription(true);
                  }}>
                  <div className="hidden group-hover:block">
                    <BorderColorIcon
                      className="text-gray-200 mb-1 hover:text-gray-500"
                      sx={{ fontSize: '15px' }}
                    />
                  </div>
                </Link>
              </Tooltip>
            </div>
          )}
        </div>

        {isEditAbiDetails ? (
          <EditMarkdown
            onClose={onAbiDetailsClose}
            onSubmit={onAbiDetailsSubmit}
            markdown={markdownAbiDetails}
          />
        ) : (
          <div id="auto-generate-doc" className="mb-10">
            <div className="flex justify-end mb-3">
              <Tooltip title="Edit Method">
                <Link
                  className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                  onClick={() => {
                    setIsEditAbiDetails(true);
                  }}>
                  <BorderColorIcon
                    className="text-gray-200 hover:text-gray-500"
                    sx={{ fontSize: '15px' }}
                  />
                </Link>
              </Tooltip>
            </div>

            {markdownAbiDetails ? (
              <MarkdownPreview
                source={`${markdownAbiDetails}`}
                className="bg-primary"
                wrapperElement={{
                  'data-color-mode': setting.theme,
                }}
              />
            ) : (
              <span className="text-gray-300 text-lg">Abi Details</span>
            )}
          </div>
        )}

        <div className="group">
          {isEditMethodName ? (
            <EditMarkdown
              onClose={onMethodNameClose}
              onSubmit={onMethodNameSubmit}
              markdown={markdownMethodName}
              minHeight="5rem"
            />
          ) : (
            <div
              id="auto-generate-doc"
              className="flex space-x-3 items-center mb-3">
              {markdownMethodName ? (
                <MarkdownPreview
                  source={`${markdownMethodName}`}
                  className="bg-primary"
                  wrapperElement={{
                    'data-color-mode': setting.theme,
                  }}
                />
              ) : (
                <span className="text-gray-300 text-lg">Method Name</span>
              )}

              <div>
                <Tooltip title="Edit ABI">
                  <Link
                    className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                    onClick={() => {
                      setIsEditMethodName(true);
                    }}>
                    <div className="hidden group-hover:block">
                      <BorderColorIcon
                        className="text-gray-200 hover:text-gray-500"
                        sx={{ fontSize: '15px' }}
                      />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <div className="group">
          {isEditMethodDescription ? (
            <EditMarkdown
              onClose={onMethodDescriptionClose}
              onSubmit={onMethodDescriptionSubmit}
              markdown={markdownMethodDescription}
              minHeight="5rem"
            />
          ) : (
            <div
              id="auto-generate-doc"
              className="flex space-x-3 items-center mb-3">
              {markdownMethodDescription ? (
                <MarkdownPreview
                  source={`${markdownMethodDescription}`}
                  className="bg-primary"
                  wrapperElement={{
                    'data-color-mode': setting.theme,
                  }}
                />
              ) : (
                <span className="text-gray-300 text-lg">Description</span>
              )}
              <div>
                <Tooltip title="Edit ABI">
                  <Link
                    className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                    onClick={() => {
                      setIsEditMethodDescription(true);
                    }}>
                    <div className="hidden group-hover:block">
                      <BorderColorIcon
                        className="text-gray-200 hover:text-gray-500"
                        sx={{ fontSize: '15px' }}
                      />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        {isEditMethodDetails ? (
          <EditMarkdown
            onClose={onMethodDetailsClose}
            onSubmit={onMethodDetailsSubmit}
            markdown={markdownMethodDetails}
          />
        ) : (
          <div id="auto-generate-doc">
            <div className="flex justify-end mb-5">
              <Tooltip title="Edit Method">
                <Link
                  className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                  onClick={() => {
                    setIsEditMethodDetails(true);
                  }}>
                  <BorderColorIcon
                    className="text-gray-200 hover:text-gray-500"
                    sx={{ fontSize: '15px' }}
                  />
                </Link>
              </Tooltip>
            </div>

            {markdownMethodDetails ? (
              <MarkdownPreview
                source={`${markdownMethodDetails}`}
                className="bg-primary"
                wrapperElement={{
                  'data-color-mode': setting.theme,
                }}
              />
            ) : (
              <span className="text-gray-300 text-lg">Method Details</span>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Tooltip title="Documentation">
        <Link
          className="cursor-pointer flex items-center pl-1.5"
          onClick={toggleDrawer(true)}>
          <ArticleIcon />
          <Typography className="!ml-2">Documentation</Typography>
        </Link>
      </Tooltip>

      <Drawer
        sx={{
          zIndex: 999999,
          '& .MuiPaper-root': {
            backgroundColor: setting.theme === 'dark' ? '#1A1A2E' : 'white',
          },
        }}
        anchor={'right'}
        open={openDrawer}
        onClose={toggleDrawer(false)}>
        {renderDrawer()}
      </Drawer>
    </div>
  );
};

export default AutoGenerateDocsMethod;
