import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useRecoilState } from 'recoil';
import Add from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import { MobileDrawerOpenState } from '../../../../states/ResponsiveViewState';
import ABIsList from './ABIsList';
import useTabs from '../../../../hooks/useTabs';
import TabService from '../../../../services/TabService';

const ABIs = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );

  // set SelectedTab in workSpace
  const { selectedTab, setSelectedTab, getAllTabs } = useTabs();

  // toggle mobile drawer
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <>
      <div className="flex justify-between m-1.5">
        <div className="text-lg ml-3 flex justify-center items-center font-bold">
          ABIs
        </div>
        <Tooltip title="Add ABI">
          <Button
            size="small"
            className="!mr-5"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              handleDrawerToggle();
              setSelectedTab(TabService.generateCreateAbiTabName());
            }}
            disabled={TabService.isNewTab(selectedTab)}>
            New
          </Button>
        </Tooltip>
      </div>
      <Divider />
      <ABIsList />
    </>
  );
};

export default ABIs;
