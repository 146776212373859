import get from 'lodash/get';
import ObjectStorage from '../modules/ObjectStorage';

const AuthService = {
  getToken() {
    const authDetails = ObjectStorage.getItem('token');
    return get(authDetails, 'access', '');
  },
};

export default AuthService;
