/* eslint-disable no-template-curly-in-string */
const Chains = [
  {
    id: '9833ddbc-27f5-4959-bf5d-78272964b81c',
    name: 'Ethereum Mainnet',
    chain: 'ETH',
    icon: 'ethereum',
    rpc: ['https://eth.llamarpc.com', 'https://cloudflare-eth.com'],
    faucets: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    infoURL: 'https://ethereum.org',
    url: 'https://api.bscscan.com/api',
    shortName: 'eth',
    chainId: 1,
    networkId: 1,
    slip44: 60,
    ens: {
      registry: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    },
    explorers: [
      {
        name: 'etherscan',
        url: 'https://etherscan.io',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'cb11414c-6cb3-436e-bb7e-7666063cee4c',
    name: 'Ethereum Classic Testnet Kotti',
    chain: 'ETC',
    rpc: ['https://www.ethercluster.com/kotti'],
    faucets: [],
    nativeCurrency: {
      name: 'Kotti Ether',
      symbol: 'KOT',
      decimals: 18,
    },
    infoURL: 'https://explorer.jade.builders/?network=kotti',
    shortName: 'kot',
    chainId: 6,
    networkId: 6,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '9dae8c14-1895-4473-9e0a-cf9b3bba0f40',
    name: 'Optimism',
    chain: 'ETH',
    rpc: ['https://mainnet.optimism.io/'],
    faucets: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    infoURL: 'https://optimism.io',
    shortName: 'oeth',
    chainId: 10,
    networkId: 10,
    explorers: [
      {
        name: 'etherscan',
        url: 'https://optimistic.etherscan.io',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '5ee5aa6d-7be8-4b83-a1a8-4d326bd034b4',
    name: 'Binance Smart Chain Mainnet',
    chain: 'BSC',
    rpc: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    url: 'https://api.bscscan.com/api',
    infoURL: 'https://www.binance.org',
    shortName: 'bnb',
    chainId: 56,
    networkId: 56,
    slip44: 714,
    explorers: [
      {
        name: 'bscscan',
        url: 'https://bscscan.com',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '785b181b-906c-4e0e-83ed-9b047405973a',
    name: 'Ethereum Classic Mainnet',
    chain: 'ETC',
    rpc: ['https://www.ethercluster.com/etc'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/?'],
    nativeCurrency: {
      name: 'Ethereum Classic Ether',
      symbol: 'ETC',
      decimals: 18,
    },
    infoURL: 'https://ethereumclassic.org',
    shortName: 'etc',
    chainId: 61,
    networkId: 1,
    slip44: 61,
    explorers: [
      {
        name: 'blockscout',
        url: 'https://blockscout.com/etc/mainnet',
        standard: 'none',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'b1443aa9-2851-4e8d-869a-e48dd37c8e99',
    name: 'Ethereum Classic Testnet Mordor',
    chain: 'ETC',
    rpc: ['https://www.ethercluster.com/mordor'],
    faucets: [],
    nativeCurrency: {
      name: 'Mordor Classic Testnet Ether',
      symbol: 'METC',
      decimals: 18,
    },
    infoURL: 'https://github.com/eth-classic/mordor/',
    shortName: 'metc',
    chainId: 63,
    networkId: 7,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  // {
  //   name: 'Optimism Kovan',
  //   title: 'Optimism Testnet Kovan',
  //   chain: 'ETH',
  //   rpc: ['https://kovan.optimism.io/'],
  //   faucets: ['http://fauceth.komputing.org?chain=69&address=${ADDRESS}'],
  //   nativeCurrency: {
  //     name: 'Kovan Ether',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   explorers: [
  //     {
  //       name: 'etherscan',
  //       url: 'https://kovan-optimistic.etherscan.io',
  //       standard: 'EIP3091',
  //     },
  //   ],
  //   infoURL: 'https://optimism.io',
  //   shortName: 'okov',
  //   chainId: 69,
  //   networkId: 69,
  // },
  {
    id: '4a3bf460-daa3-42ad-9473-0a8c3ea5bcd2',
    name: 'Binance Smart Chain Testnet',
    chain: 'BSC',
    rpc: [
      'https://data-seed-prebsc-1-s1.binance.org:8545',
      'https://data-seed-prebsc-2-s1.binance.org:8545',
      'https://data-seed-prebsc-1-s2.binance.org:8545',
      'https://data-seed-prebsc-2-s2.binance.org:8545',
      'https://data-seed-prebsc-1-s3.binance.org:8545',
      'https://data-seed-prebsc-2-s3.binance.org:8545',
    ],
    faucets: ['https://testnet.binance.org/faucet-smart'],
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'tBNB',
      decimals: 18,
    },
    infoURL: 'https://testnet.binance.org/',
    shortName: 'bnbt',
    chainId: 97,
    networkId: 97,
    explorers: [
      {
        name: 'bscscan-testnet',
        url: 'https://testnet.bscscan.com',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'a55db922-e384-4318-abaa-28e60e14b455',
    name: 'Polygon Mainnet',
    chain: 'Polygon',
    rpc: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com',
    ],
    faucets: [],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    url: 'https://api.polygonscan.com/api',
    infoURL: 'https://polygon.technology/',
    shortName: 'matic',
    chainId: 137,
    networkId: 137,
    slip44: 966,
    explorers: [
      {
        name: 'polygonscan',
        url: 'https://polygonscan.com',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  // {
  //   name: 'Optimism on Gnosis',
  //   chain: 'OGC',
  //   rpc: [
  //     'https://optimism.gnosischain.com',
  //     'wss://optimism.gnosischain.com/wss',
  //   ],
  //   faucets: ['https://faucet.gimlu.com/gnosis'],
  //   nativeCurrency: {
  //     name: 'xDAI',
  //     symbol: 'xDAI',
  //     decimals: 18,
  //   },
  //   infoURL:
  //     'https://www.xdaichain.com/for-developers/optimism-optimistic-rollups-on-gc',
  //   shortName: 'ogc',
  //   chainId: 300,
  //   networkId: 300,
  //   explorers: [
  //     {
  //       name: 'blockscout',
  //       url: 'https://blockscout.com/xdai/optimism',
  //       icon: 'blockscout',
  //       standard: 'EIP3091',
  //     },
  //   ],
  // },
  {
    id: 'b36ff45b-5249-4a30-9421-aaf30396817a',
    name: 'Optimism Goerli Testnet',
    chain: 'ETH',
    rpc: ['https://goerli.optimism.io/'],
    faucets: [],
    nativeCurrency: {
      name: 'Görli Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    infoURL: 'https://optimism.io',
    shortName: 'ogor',
    chainId: 420,
    networkId: 420,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '13736afb-eb4a-441c-904b-721aafd66026',
    name: 'Celo Mainnet',
    chainId: 42220,
    shortName: 'CELO',
    chain: 'CELO',
    networkId: 42220,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
    rpc: ['https://forno.celo.org', 'wss://forno.celo.org/ws'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://docs.celo.org/',
    explorers: [
      {
        name: 'blockscout',
        url: 'https://explorer.celo.org',
        standard: 'none',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '7e4ea4a9-0a70-4d15-afe9-319101bf272b',
    name: 'Avalanche Fuji Testnet',
    chain: 'AVAX',
    rpc: ['https://api.avax-test.network/ext/bc/C/rpc'],
    faucets: ['https://faucet.avax-test.network/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    infoURL: 'https://cchain.explorer.avax-test.network',
    shortName: 'Fuji',
    chainId: 43113,
    networkId: 1,
    explorers: [
      {
        name: 'snowtrace',
        url: 'https://testnet.snowtrace.io',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '1b0c6bb8-5b47-4b1a-9134-46a2c6ff7ea7',
    name: 'Avalanche C-Chain',
    chain: 'AVAX',
    rpc: ['https://api.avax.network/ext/bc/C/rpc'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    url: 'https://api.snowtrace.io/api',
    infoURL: 'https://www.avax.network/',
    shortName: 'avax',
    chainId: 43114,
    networkId: 43114,
    slip44: 9005,
    explorers: [
      {
        name: 'snowtrace',
        url: 'https://snowtrace.io',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '99cdb53c-f51b-4392-9023-ade2eb88b735',
    name: 'Celo Alfajores Testnet',
    chainId: 44787,
    shortName: 'ALFA',
    chain: 'CELO',
    networkId: 44787,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
    rpc: [
      'https://alfajores-forno.celo-testnet.org',
      'wss://alfajores-forno.celo-testnet.org/ws',
    ],
    faucets: [
      'https://celo.org/developers/faucet',
      'https://cauldron.pretoriaresearchlab.io/alfajores-faucet',
    ],
    infoURL: 'https://docs.celo.org/',
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '199559ec-3956-4517-b886-a87049f5e206',
    name: 'Celo Baklava Testnet',
    chainId: 62320,
    shortName: 'BKLV',
    chain: 'CELO',
    networkId: 62320,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
    rpc: ['https://baklava-forno.celo-testnet.org'],
    faucets: [
      'https://docs.google.com/forms/d/e/1FAIpQLSdfr1BwUTYepVmmvfVUDRCwALejZ-TUva2YujNpvrEmPAX2pg/viewform',
      'https://cauldron.pretoriaresearchlab.io/baklava-faucet',
    ],
    infoURL: 'https://docs.celo.org/',
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'e0dec9c4-8f51-4c4c-92c8-4770a35789ab',
    name: 'Mumbai',
    title: 'Polygon Testnet Mumbai',
    chain: 'Polygon',
    rpc: [
      'https://matic-mumbai.chainstacklabs.com',
      'https://rpc-mumbai.maticvigil.com',
      'https://matic-testnet-archive-rpc.bwarelabs.com',
    ],
    faucets: ['https://faucet.polygon.technology/'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    url: 'https://api-testnet.polygonscan.com/api',
    infoURL: 'https://polygon.technology/',
    shortName: 'maticmum',
    chainId: 80001,
    networkId: 80001,
    explorers: [
      {
        name: 'polygonscan',
        url: 'https://mumbai.polygonscan.com',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '8fd169d9-c41f-4c8a-a945-91c8e5a67329',
    name: 'Aurora Mainnet',
    chain: 'NEAR',
    rpc: ['https://mainnet.aurora.dev'],
    faucets: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    infoURL: 'https://aurora.dev',
    shortName: 'aurora',
    chainId: 1313161554,
    networkId: 1313161554,
    explorers: [
      {
        name: 'aurorascan.dev',
        url: 'https://aurorascan.dev',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'ddf02608-ac8d-44c3-8a24-aa8309e5e861',
    name: 'Aurora Testnet',
    chain: 'NEAR',
    rpc: ['https://testnet.aurora.dev/'],
    faucets: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    infoURL: 'https://aurora.dev',
    shortName: 'aurora-testnet',
    chainId: 1313161555,
    networkId: 1313161555,
    explorers: [
      {
        name: 'aurorascan.dev',
        url: 'https://testnet.aurorascan.dev',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '1915481c-e553-498a-a9f1-93193390aaee',
    name: 'Aurora Betanet',
    chain: 'NEAR',
    rpc: ['https://betanet.aurora.dev/'],
    faucets: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    infoURL: 'https://aurora.dev',
    shortName: 'aurora-betanet',
    chainId: 1313161556,
    networkId: 1313161556,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '931bd23b-67aa-42a0-8eaa-ac185082559f',
    name: 'Harmony Mainnet Shard 0',
    chain: 'Harmony',
    rpc: ['https://api.harmony.one', 'https://api.s0.t.hmny.io'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-s0',
    chainId: 1666600000,
    networkId: 1666600000,
    explorers: [
      {
        name: 'Harmony Block Explorer',
        url: 'https://explorer.harmony.one',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'cd0a1527-0493-4b74-ae26-a3b6bdd40f59',
    name: 'Harmony Mainnet Shard 1',
    chain: 'Harmony',
    rpc: ['https://api.s1.t.hmny.io'],
    faucets: [],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-s1',
    chainId: 1666600001,
    networkId: 1666600001,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'b1af3c12-4c09-41d0-a25c-0a2f60ca631e',
    name: 'Harmony Mainnet Shard 2',
    chain: 'Harmony',
    rpc: ['https://api.s2.t.hmny.io'],
    faucets: [],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-s2',
    chainId: 1666600002,
    networkId: 1666600002,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'cbd54e07-0e96-4616-afdb-8d0be8b5169a',
    name: 'Harmony Mainnet Shard 3',
    chain: 'Harmony',
    rpc: ['https://api.s3.t.hmny.io'],
    faucets: [],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-s3',
    chainId: 1666600003,
    networkId: 1666600003,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '2b1aaf6c-3382-443f-9804-4b0049e81476',
    name: 'Harmony Testnet Shard 0',
    chain: 'Harmony',
    rpc: ['https://api.s0.b.hmny.io'],
    faucets: ['https://faucet.pops.one'],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-b-s0',
    chainId: 1666700000,
    networkId: 1666700000,
    explorers: [
      {
        name: 'Harmony Testnet Block Explorer',
        url: 'https://explorer.pops.one',
        standard: 'EIP3091',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '9d883098-9b8a-4924-91fa-f513fda82a5f',
    name: 'Harmony Testnet Shard 1',
    chain: 'Harmony',
    rpc: ['https://api.s1.b.hmny.io'],
    faucets: [],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-b-s1',
    chainId: 1666700001,
    networkId: 1666700001,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: 'efa6639b-8a7b-49d9-b64a-6a6ceb6acb38',
    name: 'Harmony Testnet Shard 2',
    chain: 'Harmony',
    rpc: ['https://api.s2.b.hmny.io'],
    faucets: [],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-b-s2',
    chainId: 1666700002,
    networkId: 1666700002,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '76cae036-2d3d-4fa9-aec7-1423a8a04a07',
    name: 'Harmony Testnet Shard 3',
    chain: 'Harmony',
    rpc: ['https://api.s3.b.hmny.io'],
    faucets: [],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    infoURL: 'https://www.harmony.one/',
    shortName: 'hmy-b-s3',
    chainId: 1666700003,
    networkId: 1666700003,
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  // {
  //   name: 'Harmony Devnet Shard 0',
  //   chain: 'Harmony',
  //   rpc: ['https://api.s1.ps.hmny.io', 'https://api.s1.ps.hmny.io'],
  //   faucets: ['http://dev.faucet.easynode.one/'],
  //   nativeCurrency: {
  //     name: 'ONE',
  //     symbol: 'ONE',
  //     decimals: 18,
  //   },
  //   infoURL: 'https://www.harmony.one/',
  //   shortName: 'hmy-ps-s0',
  //   chainId: 1666900000,
  //   networkId: 1666900000,
  //   explorers: [
  //     {
  //       name: 'Harmony Block Explorer',
  //       url: 'https://explorer.ps.hmny.io',
  //       standard: 'EIP3091',
  //     },
  //   ],
  // },
  {
    id: 'a01071d4-ca0f-4eba-821c-00cfd4a1922f',
    name: 'Palm',
    chain: 'Palm',
    icon: 'palm',
    rpc: ['https://palm-mainnet.public.blastapi.io'],
    faucets: [],
    nativeCurrency: {
      name: 'PALM',
      symbol: 'PALM',
      decimals: 18,
    },
    infoURL: 'https://palm.io',
    shortName: 'palm',
    chainId: 11297108109,
    networkId: 11297108109,
    explorers: [
      {
        name: 'Palm Explorer',
        url: 'https://explorer.palm.io',
        standard: 'EIP3091',
        icon: 'palm',
      },
    ],
    type: {
      label: 'Ethereum',
      id: 'ethereum',
    },
  },
  {
    id: '7da2b95b-1170-42d9-b856-41cc7092a3ae',
    name: 'NEAR Mainnet',
    chain: 'NEAR',
    url: 'https://archival-rpc.mainnet.near.org/',
    rpc: ['https://rpc.mainnet.near.org'],
    type: { label: 'Near', id: 'near' },
    network: { label: 'Mainnet', id: 'mainnet' },
    chainId: 0,
  },
  {
    id: 'dbee3437-103e-4635-8f07-e3c401413f00',
    name: 'NEAR Testnet',
    chain: 'NEAR',
    rpc: ['https://rpc.testnet.near.org'],
    type: { label: 'Near', id: 'near' },
    network: { label: 'Testnet', id: 'testnet' },
    chainId: 0,
  },
];
export default Chains;
