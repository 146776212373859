import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { TelemetryEvents } from '../constants/EventsConstants';
import NewMethod from '../constants/NewMethod.json';
import StorageConstants from '../constants/StorageConstants';
import { uuidv4 } from '../utils/UUIDV4';
import { sendCollectionUpdate } from './EventService';
import HistoryService from './HistoryService';
import ObjectStorage from './ObjectStorageService';
import TabService from './TabService';
import TelemetryService from './TelemetryService';

const CollectionService = {
  /**
   * add ABI in local storage
   * @param {object} ABI
   */
  updateCollectionList(item) {
    const collections = ObjectStorage.getItem(StorageConstants.COLLECTIONS);
    const { data = [] } = collections;
    data.push(item);
    ObjectStorage.setItem(StorageConstants.COLLECTIONS, { data });
    TelemetryService.sendEvent(TelemetryEvents.ABI_CREATION_SUCCESS, {
      defaultChainType: get(item, 'meta.chainList.0.network.type.id'),
    });
    sendCollectionUpdate();
  },

  /**
   * change ABI chain in local storage
   * @param {(number|string)} id
   * @param {(number|string)} chainId
   */
  updateChain(id, chainId) {
    const collections = ObjectStorage.getItem(StorageConstants.COLLECTIONS);
    const { data = [] } = collections;
    const index = findIndex(data, (_d) => _d.id === id);
    if (index !== -1) {
      data[index].chainId = chainId;
      ObjectStorage.setItem(StorageConstants.COLLECTIONS, { data });
      sendCollectionUpdate();
    }
  },

  /**
   * GET all ABI list from local storage
   * @returns {array} All ABI list from local storage
   */
  getCollection() {
    const collections = ObjectStorage.getItem(StorageConstants.COLLECTIONS);
    const { data = [] } = collections;
    return data;
  },

  /**
   * replace local storage collection list
   * @param {array} replace ABI list
   */
  replaceCollections(data) {
    ObjectStorage.setItem(StorageConstants.COLLECTIONS, { data });
    sendCollectionUpdate();
  },

  /**
   * get active contract address from particular ABI
   * @param {object} particular ABI meta data
   * @returns {string}  contract address
   */
  getActiveContractAddress(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain.isActive);
    return activeChain?.address ?? '';
  },

  /**
   * get active chain from particular ABI
   * @param {object} particular ABI meta data
   * @returns {string} chain id
   */
  getActiveChain(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain.isActive);
    return activeChain?.network?.chainId;
  },
  /**
   * get active rpc from particular ABI
   * @param {object} particular ABI meta data
   * @returns {string} rpc url
   */
  getActiveChainRPC(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain.isActive);
    return activeChain?.network?.rpc;
  },

  /**
   * get active contract
   * @param {object} particular ABI meta data
   * @returns {string} rpc url
   */
  getActiveContract(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain.isActive);
    return activeChain;
  },

  /**
   * check whether active chain is near or not
   * @param {object} particular ABI meta data
   * @returns {boolean} true if chain is near
   */
  isActiveContractNear(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain?.isActive);
    return get(activeChain, 'network.type.id') === 'near';
  },
  /**
   * Returns Network Type ID of the active Contract
   * @param {*} meta
   * @returns
   */
  getActiveContractNetworkTypeID(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain?.isActive);
    return get(activeChain, 'network.type.id');
  },

  /**
   * Returns Network ID of the active Contract
   * @param {*} meta
   * @returns
   */
  getActiveContractNetworkID(meta) {
    const activeChain = find(meta?.chainList, (_chain) => _chain?.isActive);
    return get(activeChain, 'network.network.id');
  },

  /**
   * find ABI by tab id
   * @param {string} tab id
   * @returns {object} find ABI
   */
  findCollection(tab) {
    // get all ABI
    const collectionList = this.getCollection();
    // get ABI id
    const collectionId = TabService.getCollectionId(tab);

    // find ABI
    const findCollection = find(
      collectionList,
      (collection) => get(collection, 'id') === collectionId
    );
    return findCollection;
  },

  /**
   * find method by tab id
   * @param {string} tab id
   * @returns {object} find method
   */
  findCollectionMethod(tab) {
    // get all ABI
    const collectionList = this.getCollection();
    // get ABI id by tab id
    const collectionId = TabService.getCollectionId(tab);
    // get method id by tab id
    const collectionMethodId = TabService.getCollectionMethodId(tab);

    // find ABI
    const findCollection = find(
      collectionList,
      (collection) => get(collection, 'id') === collectionId
    );

    // find method
    const findCollectionMethod = find(
      get(findCollection, 'abi'),
      (method) => get(method, 'id') === collectionMethodId
    );
    return findCollectionMethod;
  },

  /**
   * add tab id in unSave List
   * @param {object}
   */
  addUnSavedCollectionMethodTabsId({
    setUnSavedCollectionMethodTabsId,
    unSavedCollectionMethodTabsId,
    tab,
  }) {
    if (!unSavedCollectionMethodTabsId?.includes?.(tab)) {
      setUnSavedCollectionMethodTabsId([
        ...(unSavedCollectionMethodTabsId || []),
        tab,
      ]);
    }
  },

  /**
   * remove tab id from unSave List
   * @param {object}
   */
  removeUnSavedCollectionMethodTabsId({
    setUnSavedCollectionMethodTabsId,
    unSavedCollectionMethodTabsId,
    tab,
  }) {
    if (unSavedCollectionMethodTabsId?.includes?.(tab)) {
      const filterUnSaveTabs = filter(
        unSavedCollectionMethodTabsId,
        (_ust) => _ust !== tab
      );
      setUnSavedCollectionMethodTabsId(filterUnSaveTabs);
    }
  },

  /**
   * change from method to form list
   * @param {object} input - tab id , all history list
   * @returns {object} form list
   */
  convertMethodToForm: ({ tab, allHistory }) => {
    // get selected ABI
    const currentCollection = CollectionService.findCollection(tab);
    // get selected method
    let currentCollectionMethod = CollectionService.findCollectionMethod(tab);

    if (TabService.isHistoryTab(tab)) {
      // get selected history method
      currentCollectionMethod = HistoryService.findHistoryByMethodId({
        methodId: TabService.getCollectionMethodId(tab),
        allHistory,
      });
    }

    const names = map(get(currentCollectionMethod, 'inputs', []), (list) => ({
      [get(list, 'id', '')]: get(list, 'name', ''),
    }));
    // name
    let formatNames = {};
    forEach(names, (list) => {
      formatNames = { ...formatNames, ...list };
    });

    const types = map(get(currentCollectionMethod, 'inputs', []), (list) => ({
      [get(list, 'id', '')]: get(list, 'type', ''),
    }));
    // types
    let formatTypes = {};
    forEach(types, (list) => {
      formatTypes = { ...formatTypes, ...list };
    });

    /**
     *  get Contract Address
     * @returns Contract Address
     */
    const getContractAddress = () => {
      return (
        (TabService.isHistoryTab(tab)
          ? currentCollectionMethod?.address
          : CollectionService.getActiveContractAddress(
              get(currentCollection, 'meta')
            )) || ''
      );
    };

    /**
     *  get Contract Chain
     * @returns Contract Chain
     */
    const getSelectedChain = () => {
      return TabService.isHistoryTab(tab)
        ? currentCollectionMethod?.chain
        : CollectionService.getActiveChain(get(currentCollection, 'meta'));
    };

    return {
      names: formatNames,
      types: formatTypes,
      values: get(currentCollectionMethod, 'values', {}),
      abiResponse: '',
      contractAddress: getContractAddress(),
      selectedChain: getSelectedChain(),
      stateMutability: get(currentCollectionMethod, 'stateMutability', 'view'),
      methodName: get(currentCollectionMethod, 'name') ?? '',
      tempABIs: get(currentCollectionMethod, 'tempABIs') || [],
      deposit:
        get(currentCollectionMethod, 'deposit')?.toString() ||
        '10000000000000000000000',
      gas: get(currentCollectionMethod, 'gas')?.toString() || '30000000000000',
    };
  },

  /**
   * change from form list to method
   * @param {object} input - formList , tab id
   * @returns {object} method
   */
  convertFormToMethod({ formData, selectedTab, allHistory, isSaveFlow }) {
    const isNewMethod = TabService.isUntitledTab(selectedTab);
    const isHistoryTab = TabService.isHistoryTab(selectedTab);

    const isNewMethodOrHistoryTab = isNewMethod || isHistoryTab;

    // get selected method
    let currentCollectionMethod =
      CollectionService.findCollectionMethod(selectedTab);

    // add id
    if (currentCollectionMethod) {
      if (!isSaveFlow) currentCollectionMethod.id = uuidv4();
    } else if (TabService.isHistoryTab(selectedTab)) {
      currentCollectionMethod = HistoryService.findHistoryByMethodId({
        methodId: TabService.getCollectionMethodId(selectedTab),
        allHistory,
      });
      currentCollectionMethod.id = uuidv4();
      omit(currentCollectionMethod, ['address', 'chain']);
      console.log('jjj', currentCollectionMethod);
    } else {
      set(NewMethod, 'id', TabService.getCollectionMethodId(selectedTab));
      currentCollectionMethod = NewMethod;
    }

    const {
      names: formParamNames,
      types: formParamTypes,
      values: formValues,
      stateMutability: formStateMutability,
      methodName: formMethodName,
      description,
    } = formData;

    // value
    const collectionFnValues = !isEmpty(
      compact(Object.values?.(formValues) || [])
    )
      ? {
          values: formValues,
        }
      : {};

    // description
    const collectionFnDescription = isNewMethodOrHistoryTab
      ? {
          description,
        }
      : {};

    // input
    const newInputs = map(formParamTypes, (_, key) => {
      return {
        ...(find(
          get(currentCollectionMethod, 'inputs'),
          (list) => get(list, 'id') === key
        ) || {}),
        name: formParamNames?.[key],
        type: formParamTypes?.[key],
        id: key,
      };
    });

    const newCollectionFnData = {
      ...currentCollectionMethod,
      name: formMethodName,
      stateMutability: formStateMutability,
      inputs: map(newInputs || [], (obj) => {
        const id = get(obj, 'id', '');
        return {
          ...obj,
          name: Object.keys(formParamNames).includes(id)
            ? get(formParamNames, id, '')
            : get(obj, 'name', ''),
          type: Object.keys(formParamTypes).includes(id)
            ? get(formParamTypes, id, '')
            : get(obj, 'type', ''),
        };
      }),
      ...collectionFnValues,
      ...collectionFnDescription,
    };
    return newCollectionFnData;
  },
};
export default CollectionService;
