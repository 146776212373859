import { Tooltip } from '@mui/material';
import trimFileName from '../utils/trimFileName';

function OverflowTruncate({ name, start = 5, end = 5, toolTipTitle }) {
  return (
    <Tooltip title={toolTipTitle || name}>
      <div>
        {trimFileName({ filename: name, startLength: start, endLength: end })}
      </div>
    </Tooltip>
  );
}

export default OverflowTruncate;
