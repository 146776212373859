import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import getClone from '../../../../utils/getClone';
import { uuidv4 } from '../../../../utils/UUIDV4';
import CollectionService from '../../../../services/CollectionService';

/**
 * create new method
 * @param {object}
 */
const createNewMethod = ({
  NewMethod,
  _c,
  collectionsState,
  setSelectedTab,
}) => {
  const newMethodData = getClone(NewMethod);
  newMethodData.id = uuidv4();
  newMethodData.name = 'New Method';
  const currentCollection = getClone(_c);
  set(currentCollection, 'abi', [
    ...get(currentCollection, 'abi'),
    newMethodData,
  ]);
  const replaceCollection = map(collectionsState, (list) => {
    if (get(list, 'id') === get(currentCollection, 'id')) {
      return currentCollection;
    }
    return list;
  });
  setSelectedTab(`${get(currentCollection, 'id')}$${get(newMethodData, 'id')}`);
  CollectionService.replaceCollections(replaceCollection);
};

export default createNewMethod;
