import { Modal } from '../../components/Modal';

const UnSaveModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      submit={onSubmit}
      title="Confirm"
      subTitle="Unsaved changes will be lost. Continue?"
      primaryBtnText="Yes"
      secondaryBtnText="No"
    />
  );
};
export default UnSaveModal;
