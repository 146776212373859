import { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Editor from '@monaco-editor/react';
import forEach from 'lodash/forEach';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {
  FormControl,
  IconButton,
  Link,
  TextField,
  Tooltip,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import '../../../../styles/TagsInput.css';
import { useRecoilValue } from 'recoil';
import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { SplitHeightState } from '../../../../states/SplitState';
import { uuidv4 } from '../../../../utils/UUIDV4';
import isJSON from '../../../../utils/isJSON';
import CollectionService from '../../../../services/CollectionService';
import useTabs from '../../../../hooks/useTabs';
import getClone from '../../../../utils/getClone';
import IconWrapper from '../../../../components/IconWrapper';
import JSONHelper from '../../../../utils/JSONHelper';
import { getActiveChain } from '../../../../services/ChainService';
import HistoryService from '../../../../services/HistoryService';
import TabService from '../../../../services/TabService';
import { HistoryMethodListState } from '../../../../states/HistoryState';

const Parameters = ({
  collectionMethodFormData,
  setCollectionMethodFormData,
}) => {
  const splitHeight = useRecoilValue(SplitHeightState);

  const { selectedTab } = useTabs();

  const { tempABIs } = collectionMethodFormData;

  const getHistoryMethod = () => {
    return HistoryService.findHistoryByMethodId({
      methodId: TabService.getCollectionMethodId(selectedTab),
      allHistory: HistoryMethodListState,
    });
  };

  const tempABIsActiveChain =
    getActiveChain(tempABIs) ||
    getActiveChain(getHistoryMethod()?.tempABIs || []);

  const currentCollection = CollectionService.findCollection(selectedTab);

  const { meta } = currentCollection ?? {};

  const isNear = CollectionService.isActiveContractNear(
    isEmpty(meta) ? { chainList: [tempABIsActiveChain] } : meta
  );

  const [isBulkEdit, setBulkEdit] = useState(false);

  const [bulkParameters, setBulkParameters] = useState('');

  const currentCollectionMethod =
    CollectionService.findCollectionMethod(selectedTab);

  const cloneCollectionMethodFormData = getClone(collectionMethodFormData);

  const {
    names: formParamNames,
    types: formParamTypes,
    values: formValues,
  } = cloneCollectionMethodFormData;

  const currentCollectionFnId = get(currentCollectionMethod, 'id');

  const handleChangeParameterName = (event) => {
    const {
      target: { value, name },
    } = event;

    setCollectionMethodFormData({
      ...collectionMethodFormData,
      names: { ...formParamNames, [name]: value },
    });
  };

  function handleParameterType(e, values, type) {
    const {
      target: { value, name },
    } = e;
    if (value?.includes('[]') && !isJSON(values)) {
      setCollectionMethodFormData({
        ...collectionMethodFormData,
        values: {
          ...formValues,
          [name]: values
            ? JSON.stringify(values?.split(','))
            : JSON.stringify([]),
        },
      });
    }
    if (!value?.includes('[]') && isJSON(values)) {
      const parseValues = JSON.parse(values);

      setCollectionMethodFormData({
        ...collectionMethodFormData,
        values: {
          ...formValues,
          [name]: parseValues?.join(','),
        },
      });
    }
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      types: { ...formParamTypes, [name]: value },
    });
  }

  const handleInputChange = (event, isArray, type) => {
    const {
      target: { value, name },
    } = event;
    if (type === 'json') {
      setCollectionMethodFormData({
        ...collectionMethodFormData,
        values: {
          ...formValues,
          [name]: value || undefined,
        },
      });
    } else if (isArray) {
      setCollectionMethodFormData({
        ...collectionMethodFormData,
        values: {
          ...formValues,
          [name]: value ? JSON.stringify(value?.split(',')) : undefined,
        },
      });
    } else {
      setCollectionMethodFormData({
        ...collectionMethodFormData,
        values: {
          ...formValues,
          [name]: value || undefined,
        },
      });
    }
  };

  /**
   * Add Parameter
   */
  const addParameter = () => {
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      types: {
        ...formParamTypes,
        [uuidv4()]: 'uint',
      },
    });
  };

  /**
   * Remove Parameter
   */
  const removeParameter = (id) => {
    delete formParamTypes?.[id];
    delete formParamNames?.[id];
    delete formValues?.[id];
    setCollectionMethodFormData(cloneCollectionMethodFormData);
  };

  const parameters = map(formParamTypes, (_, key) => {
    return {
      name: formParamNames?.[key],
      type: formParamTypes?.[key] || 'uint',
      id: key,
    };
  });

  const handleBulkEdit = () => {
    if (!isBulkEdit) {
      const bulkParametersData = {};

      forEach(
        get(collectionMethodFormData, 'names'),
        (_nameValue, _nameKey) => {
          const type = get(collectionMethodFormData, 'types')[_nameKey];
          const value = get(collectionMethodFormData, 'values')[_nameKey];

          let changedValue;

          if (type === 'uint' || type === 'uint8' || type === 'uint256') {
            changedValue = Number(value) || 0;
          } else if (type === 'uint256[]') {
            const parseData = JSONHelper.hasJsonStructure(value)
              ? JSON.parse(value)
              : value;
            changedValue = map(parseData, (_value) => {
              return Number(_value) || 0;
            });
          } else {
            changedValue = value ?? (type?.endsWith?.('[]') ? [] : '');
          }
          bulkParametersData[_nameValue] = JSONHelper.hasJsonStructure(
            changedValue
          )
            ? JSON.parse(changedValue)
            : changedValue;
        }
      );
      setBulkParameters(JSON.stringify(bulkParametersData));
      setBulkEdit(true);
      return;
    }
    setBulkEdit(false);
  };

  const changedBulkEditJson = (bulkParameters) => {
    if (JSONHelper.hasJsonStructure(bulkParameters)) {
      const cloneCollectionMethodFormData = getClone(collectionMethodFormData);

      cloneCollectionMethodFormData.names = {};
      cloneCollectionMethodFormData.values = {};
      cloneCollectionMethodFormData.types = {};

      const bulkParametersData = JSON.parse(bulkParameters);

      forEach(bulkParametersData, (value, key) => {
        let type = 'unit';

        if (isObject(value) && isNear) {
          type = 'json';
        } else if (isArray(value)) {
          const arr = value;
          const everyValueAddress = every(arr, (_value) => {
            return _value?.startsWith?.('0x') && _value?.length === 42;
          });
          const everyValueNumber = every(arr, (_value) => {
            return typeof _value === 'number';
          });
          if (everyValueNumber) {
            type = 'uint256[]';
          } else if (everyValueAddress) {
            type = 'address[]';
          } else {
            type = 'string[]';
          }
        } else {
          // eslint-disable-next-line no-undef, no-lonely-if
          if (typeof value === 'number') {
            type = 'uint';
          } else if (value?.startsWith?.('0x') && value?.length === 42) {
            type = 'address';
          } else {
            type = 'string';
          }
        }

        const getValue = () => {
          if (type === 'json') {
            return JSON.stringify(value);
          }
          if (JSONHelper.hasJsonStructure(value)) {
            return JSON.parse(value);
          }
          return value;
        };

        const uuid = uuidv4();
        cloneCollectionMethodFormData.names[uuid] = key;
        cloneCollectionMethodFormData.values[uuid] = getValue();
        cloneCollectionMethodFormData.types[uuid] = type;
      });

      setCollectionMethodFormData(cloneCollectionMethodFormData);
    }
  };

  return (
    <>
      <Link className="cursor-pointer pr-3 flex justify-end">
        <Tooltip title="Bulk Edit">
          <div onClick={handleBulkEdit}>
            {isBulkEdit ? 'Key-Value Edit' : 'Bulk Edit'}
          </div>
        </Tooltip>
      </Link>
      {isBulkEdit ? (
        <div id="bulk-edit-editor" className="flex flex-col my-4 h-full">
          <Editor
            className="!h-[80%]"
            placeholder="Paste ABI JSON here"
            name="abi"
            id="abi-json"
            onChange={(value) => {
              setBulkParameters(value);
              changedBulkEditJson(value);
            }}
            value={bulkParameters}
            autoComplete="off"
            defaultLanguage="json"
            options={{
              cursorStyle: 'line',
              formatOnPaste: true,
              formatOnType: true,
              wordWrap: true,
            }}
            onMount={(editor) => {
              setTimeout(function () {
                editor.getAction('editor.action.formatDocument').run();
              }, 300);
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col p-2 w-full" style={{ height: '92%' }}>
          <TableContainer component={Paper} className="!shadow-none">
            <Table stickyHeader size="small">
              <TableHead className="!border-b-0">
                <TableRow>
                  <TableCell className="!bg-secondary w-1/5">Type</TableCell>
                  <TableCell className="!bg-secondary w-1/5">Name</TableCell>
                  <TableCell className="!bg-secondary w-6/12">Values</TableCell>
                  <TableCell className="!bg-secondary !pr-6 !float-right">
                    <Tooltip title="Add parameter">
                      <IconWrapper>
                        <IconButton color="primary" onClick={addParameter}>
                          <AddIcon />
                        </IconButton>
                      </IconWrapper>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(parameters || [], (input) => {
                  const name = get(input, 'name', '');
                  const type = get(input, 'type');
                  const values = formValues?.[get(input, 'id', '')];
                  let formValuesData =
                    type !== 'json' && isJSON(values)
                      ? JSON.parse(values)
                      : values;
                  if (Array.isArray(formValuesData)) {
                    formValuesData = formValuesData?.join(',');
                  }

                  return (
                    <TableRow>
                      <TableCell
                        className="!px-1 !border-b-0 !bg-primary"
                        sx={{ minWidth: '14.5vw' }}>
                        <FormControl fullWidth size="small">
                          <Select
                            value={formParamTypes?.[get(input, 'id', '')]}
                            onChange={(e) =>
                              handleParameterType(
                                e,
                                formValues?.[get(input, 'id', '')]
                              )
                            }
                            name={get(input, 'id', '')}
                            defaultValue={get(input, 'type')}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="!bg-secondary"
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 250 } },
                            }}>
                            <MenuItem value="json">json</MenuItem>
                            <MenuItem value="uint">uint</MenuItem>
                            <MenuItem value="uint256">uint256</MenuItem>
                            <MenuItem value="uint256[]">uint256[]</MenuItem>
                            <MenuItem value="bool">bool</MenuItem>
                            <MenuItem value="address">address</MenuItem>
                            <MenuItem value="address[]">address[]</MenuItem>
                            <MenuItem value="bytes32">bytes32</MenuItem>
                            <MenuItem value="bytes[]">bytes[]</MenuItem>
                            <MenuItem value="bytes">bytes</MenuItem>
                            <MenuItem value="uint8">uint8</MenuItem>
                            <MenuItem value="string">string</MenuItem>
                            <MenuItem value="string[]">string[]</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className="!px-1 !border-b-0 w-1/5 !bg-primary">
                        <TextField
                          variant="outlined"
                          type="text"
                          name={get(input, 'id', '')}
                          defaultValue={name}
                          value={formParamNames?.[get(input, 'id', '')]}
                          placeholder="Type name"
                          onChange={handleChangeParameterName}
                          sx={{
                            '& .MuiInputBase-input': {
                              boxShadow: 'none',
                            },
                          }}
                          className="w-full !bg-secondary !rounded-lg"
                          size="small"
                        />
                      </TableCell>
                      <TableCell className="!px-1 !border-b-0 w-[60%] !bg-primary">
                        <TextField
                          {...{
                            ...(get(input, 'type').includes('[]')
                              ? {
                                  onChange: (e) =>
                                    handleInputChange(
                                      e,
                                      true,
                                      get(input, 'type')
                                    ),
                                  placeholder:
                                    'Type and comma to add multiple value',
                                }
                              : {
                                  onChange: (e) =>
                                    handleInputChange(
                                      e,
                                      false,
                                      get(input, 'type')
                                    ),
                                  placeholder: 'Type value',
                                }),
                          }}
                          name={get(input, 'id', '')}
                          value={formValuesData ?? ''}
                          id={currentCollectionFnId}
                          className="w-full !bg-secondary !rounded-lg tags-input"
                          variant="outlined"
                          type="text"
                          sx={{
                            '& .MuiInputBase-input': {
                              boxShadow: 'none',
                            },
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell className="!border-none !bg-primary !pr-6 !float-right">
                        <Tooltip title="Remove parameter">
                          <IconWrapper>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                removeParameter(get(input, 'id', ''))
                              }>
                              <HorizontalRuleIcon />
                            </IconButton>
                          </IconWrapper>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default Parameters;
