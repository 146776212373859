import { Modal } from '../../components/Modal';
import { IMPORT_TYPES } from '../../constants/AppConstants';
import NewABI from '../AppHome/WorkSpace/NewTabDetails/NewABI';

const AddNewAbiModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal open={isOpen} isActionRequired={false}>
      <NewABI
        handleBack={onClose}
        onSubmit={onSubmit}
        title={IMPORT_TYPES?.SELECT_ABI}
      />
      <div className="mb-10"></div>
    </Modal>
  );
};
export default AddNewAbiModal;
