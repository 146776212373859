import toLower from 'lodash/toLower';
import { Tooltip } from '@mui/material';

const CryptoIcon = ({ symbol, size, className }) => {
  const iconPath = `${process.env.PUBLIC_URL}/assets/color/`;
  return (
    <Tooltip title={symbol}>
      <img
        src={`${iconPath}${toLower(symbol)}.svg`}
        width={size}
        alt="ic"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `${iconPath}generic.svg`;
        }}
        className={className}
      />
    </Tooltip>
  );
};

export default CryptoIcon;
