import find from 'lodash/find';
import get from 'lodash/get';
import { sendHistoryUpdate } from './EventService';

const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;
const baseName = 'History';
const storeName = 'HistoryList';

function logerr(err) {
  console.log(err);
}

function connectDB(f) {
  const request = indexedDB.open(baseName, 1);
  request.onerror = logerr;
  request.onsuccess = function () {
    f(request.result);
  };
  request.onupgradeneeded = function (e) {
    const Db = e.currentTarget.result;

    if (!Db.objectStoreNames.contains(storeName)) {
      Db.createObjectStore(storeName, {
        keyPath: 'id',
        autoIncrement: true,
      });
    }
    connectDB(f);
  };
}

const HistoryService = {
  /**
   * get all history
   * @param {function} callback
   */
  getAllHistory(f) {
    connectDB(function (db) {
      const rows = [];
      const store = db
        .transaction([storeName], 'readonly')
        .objectStore(storeName);

      if (store.mozGetAll)
        store.mozGetAll().onsuccess = function (e) {
          f(e.target.result);
        };
      else
        store.openCursor().onsuccess = function (e) {
          const cursor = e.target.result;
          if (cursor) {
            rows.push(cursor.value);
            cursor.continue();
          } else {
            f(rows);
          }
        };
    });
  },
  /**
   *  add history
   * @param {object} history
   */
  addHistory(obj) {
    connectDB((db) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const objectStoreRequest = objectStore.add(obj);
      objectStoreRequest.onerror = logerr;
      objectStoreRequest.onsuccess = function () {
        sendHistoryUpdate();
      };
    });
  },
  /**
   * delete particular history by history id
   * @param {(number|string)} id
   */
  deleteHistory(id) {
    connectDB((db) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const objectStoreRequest = objectStore.delete(id);
      objectStoreRequest.onerror = logerr;
      objectStoreRequest.onsuccess = function () {
        sendHistoryUpdate();
      };
    });
  },
  /**
   *  delete all history
   */
  deleteAllHistory() {
    connectDB((db) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onerror = logerr;
      objectStoreRequest.onsuccess = function () {
        sendHistoryUpdate();
      };
    });
  },
  /**
   * get particular history by method id
   * @param {object} input - methodId, all history methods
   * @returns
   */
  findHistoryByMethodId({ methodId, allHistory }) {
    // find history method by particular method id
    const findHistory = find(allHistory, (history) => {
      return get(history, 'method.id') === methodId;
    });
    return {
      tempABIs: get(findHistory, 'tempABIs'),
      address: get(findHistory, 'address'),
      chain: get(findHistory, 'chain'),
      ...(get(findHistory, 'method') || {}),
    };
  },
};
export default HistoryService;
