import { atom } from 'recoil';
import types from '../constants/Types';

const initialSettings = {
  direction: 'ltr',
  language: 'en',
  pinSidebar: true,
  theme: 'light',
};

export const AppSettings = atom({
  key: types.SETTING.APP_SETTINGS,
  default: initialSettings,
});
