import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from 'react';
import find from 'lodash/find';
import toast from 'react-hot-toast';
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Button,
  CircularProgress,
  ListItemButton,
  Tooltip,
} from '@mui/material';
import { QUERY, W3C_ABI_VERSION } from '../constants/AppConstants';
import ABIService from '../services/API/ABIService';
import { uuidv4 } from '../utils/UUIDV4';
import {
  generateABIDetailsMarkDown,
  generateABINameMarkDown,
  generateMethodDetailsMarkDown,
  generateMethodNameMarkDown,
} from '../utils/generateDocReadme';
import { AppSettings } from '../states/SettingsState';
import IconWrapper from '../components/IconWrapper';
import CollectionService from '../services/CollectionService';
import { getAllChains } from '../services/ChainService';
import RouterConstants from '../constants/RouterConstants';
import { CollectionListState } from '../states/CollectionState';
import getClone from '../utils/getClone';
import regex from '../utils/regex';

const Docs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [abiData, setAbiData] = useState(null);

  const [selectedAbiOrMethodName, setSelectedAbiOrMethodName] = useState('');

  const navigate = useNavigate();

  const setting = useRecoilValue(AppSettings);

  // set ABI List in recoil State
  const setCollectionState = useSetRecoilState(CollectionListState);

  const setDocumentationInAbi = (data) => {
    const cloneCurrentCollection = getClone(data);
    if (!get(cloneCurrentCollection, 'documentation.isEdit')) {
      set(
        cloneCurrentCollection,
        'documentation.content.abiName',
        generateABINameMarkDown(cloneCurrentCollection)
      );
      set(
        cloneCurrentCollection,
        'documentation.content.abiDetails',
        generateABIDetailsMarkDown(cloneCurrentCollection)
      );

      forEach(get(cloneCurrentCollection, 'abi'), (_method) => {
        if (_method?.type === 'function') {
          set(
            cloneCurrentCollection,
            `documentation.content.methodName.${get(_method, 'id', '')}`,
            generateMethodNameMarkDown(_method)
          );
          set(
            cloneCurrentCollection,
            `documentation.content.methodDetails.${get(_method, 'id', '')}`,
            generateMethodDetailsMarkDown(_method)
          );
        }
      });
    }
    setAbiData(cloneCurrentCollection);
    setSelectedAbiOrMethodName(
      regex
        .removeMarkdownCharacter(
          get(cloneCurrentCollection, 'documentation.content.abiName')
        )
        ?.toLowerCase()
    );
  };

  const getABICollection = async (d) => {
    setIsLoading(true);
    try {
      const response = await ABIService.getCollection(d);
      const { abi, meta, token } = get(response, 'data');
      const defaultPayload = {
        meta,
        id: uuidv4(),
        parentId: token,
        abi,
        documentation: get(meta, 'documentation'),
      };
      // to be set Collection below
      setDocumentationInAbi(defaultPayload);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error('Invalid Link');
    }
  };

  const getWeb3token = () => {
    if (window.location.href.includes(QUERY.SHARE_QUERY)) {
      const queryParameters = new URLSearchParams(window.location.search);
      const web3token = queryParameters.get(QUERY.SHARE_QUERY);
      return web3token;
    }
  };

  function importFromLink() {
    const web3token = getWeb3token();
    if (web3token) getABICollection(web3token);
  }

  useEffect(() => {
    importFromLink();
  }, []);

  const filterFunctionTypeMethods = filter(
    get(abiData, 'abi', []),
    (_ite) => _ite.type === 'function'
  );

  /**
   * set ABI-list which is from from local storage into recoil state
   */
  function updateCollection() {
    setCollectionState(CollectionService.getCollection());
  }

  function navigateToOrigin() {
    if (window.location.href.includes(QUERY.SHARE_QUERY)) {
      navigate(`${RouterConstants.APP.EVM_HOME}`, { replace: true });
    }
  }

  const handleExecute = async () => {
    const { abi, meta, token, documentation } = abiData || {};
    const data = {
      meta,
      id: uuidv4(),
      parentId: token,
      abi,
      documentation,
    };

    const addressData = CollectionService?.getActiveContractAddress(data?.meta);
    const chainIdData = CollectionService?.getActiveChain(data?.meta);

    const isActiveContractNear = CollectionService?.isActiveContractNear(
      data?.meta
    );

    let shareABI = data;

    if (!addressData || (!chainIdData && !isActiveContractNear)) {
      const allChains = getAllChains();

      const selectedChain = find(allChains, (chain) => {
        return get(chain, 'chainId') === data?.meta?.chainId;
      });

      shareABI = {
        meta: {
          version: W3C_ABI_VERSION,
          name: data?.meta?.name,
          source: data?.meta?.source,
          chainList: [
            {
              address: data?.meta?.address,
              id: uuidv4(),
              isActive: true,
              name: get(selectedChain, 'name'),
              network: {
                chainId: data?.meta?.chainId,
                rpc: Array.isArray(selectedChain?.rpc)
                  ? selectedChain?.rpc?.[0]
                  : selectedChain?.rpc,
                chainName: get(selectedChain, 'name'),
                type: get(selectedChain, 'type'),
                network: get(selectedChain, 'network') || null,
              },
            },
          ],
        },
        abi: data?.abi,
        parentId: data?.parentId,
        id: uuidv4(),
        documentation,
      };
    }

    const changedMethodIdInAbiLevelDocs = ({
      newMethodId,
      copyCollection,
      list,
    }) => {
      set(
        copyCollection,
        `documentation.content.methodName.${newMethodId}`,
        get(
          copyCollection,
          `documentation.content.methodName.${get(list, 'id', '')}`
        )
      );
      delete copyCollection?.documentation?.content?.methodName?.[
        get(list, 'id', '')
      ];

      set(
        copyCollection,
        `documentation.content.methodDescription.${newMethodId}`,
        get(
          copyCollection,
          `documentation.content.methodDescription.${get(list, 'id', '')}`
        )
      );
      delete copyCollection?.documentation?.content?.methodDescription?.[
        get(list, 'id', '')
      ];

      set(
        copyCollection,
        `documentation.content.methodDetails.${newMethodId}`,
        get(
          copyCollection,
          `documentation.content.methodDetails.${get(list, 'id', '')}`
        )
      );
      delete copyCollection?.documentation?.content?.methodDetails?.[
        get(list, 'id', '')
      ];
    };

    const copyCollection = JSON.parse(JSON.stringify(shareABI));
    set(copyCollection, 'id', uuidv4());
    forEach(get(copyCollection, 'abi'), (list) => {
      const newMethodId = uuidv4();
      changedMethodIdInAbiLevelDocs({ newMethodId, copyCollection, list });
      set(list, 'id', newMethodId);
    });

    CollectionService.updateCollectionList(copyCollection);
    updateCollection();
    navigateToOrigin();
    setIsLoading(false);
    toast.success('Added ABI');
  };

  const handleMethodClick = (abiOrMethodName) => {
    const changedKebabCase = abiOrMethodName?.replace(' ', '-');
    setSelectedAbiOrMethodName(changedKebabCase);
    const getMethodElement = document.getElementById(changedKebabCase);
    getMethodElement?.scrollIntoView?.({ behavior: 'smooth' });
  };

  let fullMarkdownData = `${get(
    abiData,
    'documentation.content.abiName'
  )} ${get(abiData, 'documentation.content.abiDescription')} ${get(
    abiData,
    'documentation.content.abiDetails'
  )}`;

  forEach(
    get(abiData, 'documentation.content.methodName'),
    (_methodName, _id1) => {
      forEach(
        get(abiData, 'documentation.content.methodDescription'),
        (_methodDescription, _id2) => {
          forEach(
            get(abiData, 'documentation.content.methodDetails'),
            (_methodDetails, _id3) => {
              if (_id1 === _id2 && _id1 === _id3 && _id2 === _id3) {
                fullMarkdownData += _methodName;
                fullMarkdownData += _methodDescription;
                fullMarkdownData += _methodDetails;
              }
            }
          );
        }
      );
    }
  );

  return (
    <div>
      <div className="absolute -top-12 right-4">
        <Tooltip title="Back to Home">
          <Button
            variant="contained"
            size="medium"
            className="inline-flex items-center text-sm ml-2 h-10"
            onClick={handleExecute}
            endIcon={
              <IconWrapper>
                <SendIcon className="!w-full !h-full mb-0.5" />
              </IconWrapper>
            }>
            Execute
          </Button>
        </Tooltip>
      </div>
      {abiData && (
        <div className="flex space-x-5">
          <div className="w-[30rem] h-[calc(100vh-74px)] overflow-auto !border-r-2 !border-solid bg-gray-50">
            <ListItemButton
              selected={
                selectedAbiOrMethodName ===
                regex
                  .removeMarkdownCharacter(
                    get(abiData, 'documentation.content.abiName')
                  )
                  ?.toLowerCase()
              }
              onClick={() => {
                handleMethodClick(
                  regex
                    .removeMarkdownCharacter(
                      get(abiData, 'documentation.content.abiName')
                    )
                    ?.toLowerCase()
                );
              }}
              className="bg-secondary font-semibold h-14 !border-b-2 !border-solid !border-[#d7d9d7] !p-2 !pl-5 !border-opacity-30">
              Introduction
            </ListItemButton>
            {map(get(abiData, 'documentation.content.methodName'), (name) => {
              const methodName = regex.removeMarkdownCharacter(name);
              return (
                <ListItemButton
                  selected={
                    selectedAbiOrMethodName === methodName?.toLowerCase()
                  }
                  onClick={() => {
                    handleMethodClick(methodName?.toLowerCase());
                  }}
                  className="bg-secondary font-semibold h-14 !border-b-2 !border-solid !border-[#d7d9d7] !p-2 !pl-5 !border-opacity-30">
                  {methodName}
                </ListItemButton>
              );
            })}
          </div>
          <div className="pt-3 !pr-5 w-full">
            <div
              id="auto-generate-doc"
              className="h-[calc(100vh-90px)] overflow-auto w-full">
              <MarkdownPreview
                source={fullMarkdownData}
                className="mb-6 bg-primary"
                wrapperElement={{
                  'data-color-mode': setting.theme,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="absolute w-full h-[91.9vh] flex justify-center items-center font-bold text-lg">
          <CircularProgress size={60} />
        </div>
      )}
    </div>
  );
};

export default Docs;
