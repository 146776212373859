import { Modal } from '../../components/Modal';

const DeleteConfirmationModal = ({ isOpen, onClose, onSubmit, title }) => {
  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onSubmit();
        }
      }}>
      <Modal
        open={isOpen}
        handleClose={onClose}
        submit={onSubmit}
        title="Confirm"
        subTitle={`Do you want to delete ${title} ?`}
        primaryBtnText="Delete"
        secondaryBtnText="Cancel"
        primaryBtnColor="error"
      />
    </div>
  );
};
export default DeleteConfirmationModal;
