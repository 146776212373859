export const MODAL_ID = {
  COLLECTION: 'collection',
  CHAIN_EXPLORER: 'chainExplorer',
  JSON_IMPORT: 'json_import',
  URL_EXPLORER: 'url_explorer',
};

export const IMPORT_SOURCE = {
  JSON: 'json',
  FILE: 'file',
  CHAIN_EXPLORER: 'chain',
  IMPORT: 'url-import',
  TEMPLATE: 'template',
  URL: 'link',
  NEW_ABI: 'new-abi',
};

export const TEMPLATE_ID = {
  ERC20: 'erc20',
  ERC721: 'erc721',
  ERC1155: 'erc1155',
};

export const TEMPLATE_META = [
  {
    label: 'ERC20',
    id: TEMPLATE_ID.ERC20,
  },
  { label: 'ERC721', id: TEMPLATE_ID.ERC721 },
  { label: 'ERC1155', id: TEMPLATE_ID.ERC1155 },
];

export const META_MASK_STATUS = { CONNECTED: 'connected' };
export const QUERY = { SHARE_QUERY: 'web3ClientToken' };

export const IMPORT_TYPES = {
  IMPORT_BY_CONTRACT_ID: 'Import by Contract ID',
  CREATE_FROM_STANDARDS: 'Create from standards',
  IMPORT_FROM_JSON: 'Import from JSON',
  CREATE_NEW_ABI: 'Create New ABI',
  IMPORT_ABI: 'Web3client ABI',
  SELECT_ABI: 'Select ABI',
  CREATE_OR_SELECT_ABI: 'Create Or Select ABI',
  CLONE: 'clone',
  SAVE_AS: 'saveAs',
};
export const STATE_MUTUABLITIY_LIST = {
  VIEW: 'view',
  NON_PAYABLE: 'nonpayable',
  PAYABLE: 'payable',
  PURE: 'pure',
  DEFAULT: 'default',
};
export const STATE_MUTUABLITIY = {
  [STATE_MUTUABLITIY_LIST.VIEW]: 'READ',
  [STATE_MUTUABLITIY_LIST.NON_PAYABLE]: 'WRITE',
  [STATE_MUTUABLITIY_LIST.PAYABLE]: 'WRITE',
  [STATE_MUTUABLITIY_LIST.PURE]: 'READ',
  [STATE_MUTUABLITIY_LIST.DEFAULT]: '',
};

export const STATE_MUTUABLITIY_PARAMETER = {
  [STATE_MUTUABLITIY_LIST.VIEW]: 'VIEW',
  [STATE_MUTUABLITIY_LIST.NON_PAYABLE]: 'NON-PAYABLE',
  [STATE_MUTUABLITIY_LIST.PAYABLE]: 'PAYABLE',
  [STATE_MUTUABLITIY_LIST.PURE]: 'PURE',
  [STATE_MUTUABLITIY_LIST.DEFAULT]: '',
};

export const STATE_MUTUABLITIY_COLORS = {
  [STATE_MUTUABLITIY_LIST.VIEW]: 'success',
  [STATE_MUTUABLITIY_LIST.NON_PAYABLE]: 'error',
  [STATE_MUTUABLITIY_LIST.PAYABLE]: 'error',
  [STATE_MUTUABLITIY_LIST.PURE]: 'success',
  [STATE_MUTUABLITIY_LIST.DEFAULT]: 'warning',
};

export const META_MASK = { DOWNLOAD: 'https://metamask.io/download/' };
export const W3C_ABI_VERSION = '4.0';

export const TABS = {
  UNTITLED_TAB: 'untitled-tab',
  CREATE_ABI_TAB: 'create-abi-tab',
  HISTORY_METHOD: 'history-method',
};

export const NETWORKS = {
  ETHERS: 'ethereum',
  NEAR: 'near',
};

export const DOCS_LEVEL = {
  ABI_LEVEL: 'abi level',
  METHOD_LEVEL: 'method level',
};

export const DOCS = {
  ABI_NAME: 'abi name',
  ABI_DESCRIPTION: 'abi description',
  ABI_DETAILS: 'abi details',
  METHOD_NAME: 'method name',
  METHOD_DESCRIPTION: 'method description',
  METHOD_DETAILS: 'method details',
  REGENERATE_ABI_AND_METHOD: 'regenerate abi and method',
};
