import SendIcon from '@mui/icons-material/Send';
import { Alert, Button, Tooltip } from '@mui/material';
import find from 'lodash/find';
import get from 'lodash/get';
import { useMetaMask } from 'metamask-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useRecoilState, useRecoilValue } from 'recoil';
import { object, string } from 'yup';
import DividerWithText from '../../../../components/DivderWithText';
import IconWrapper from '../../../../components/IconWrapper';
import Input from '../../../../components/Input';
import MetaMaskConnect from '../../../../components/MetaMaskConnect';
import { Modal } from '../../../../components/Modal';
import {
  META_MASK_STATUS,
  STATE_MUTUABLITIY_LIST,
} from '../../../../constants/AppConstants';
import { TelemetryEvents } from '../../../../constants/EventsConstants';
import Messages from '../../../../constants/Messages';
import useTabs from '../../../../hooks/useTabs';
import { getChainDetailsByChainId } from '../../../../services/ChainService';
import CollectionService from '../../../../services/CollectionService';
import HistoryService from '../../../../services/HistoryService';
import TabService from '../../../../services/TabService';
import TelemetryService from '../../../../services/TelemetryService';
import { HistoryMethodListState } from '../../../../states/HistoryState';
import { IsResponseLoadingState } from '../../../../states/LoadingState';
import { uuidv4 } from '../../../../utils/UUIDV4';
import sendCollectionFn from './sendCollectionFn';

const SignConfigModal = ({
  collectionMethodFormData,
  setCollectionMethodFormData,
}) => {
  const { selectedTab } = useTabs();

  const currentCollection = CollectionService.findCollection(selectedTab);

  const HistoryMethodList = useRecoilValue(HistoryMethodListState);

  const getHistoryMethod = () => {
    return HistoryService.findHistoryByMethodId({
      methodId: TabService.getCollectionMethodId(selectedTab),
      allHistory: HistoryMethodList,
    });
  };

  const tempABIs =
    get(collectionMethodFormData, 'tempABIs') || getHistoryMethod()?.tempABIs;

  const { stateMutability: formStateMutability } = collectionMethodFormData;

  const activeContract = CollectionService.getActiveContract(
    get(currentCollection, 'meta')
  );

  const currentAddress = CollectionService.getActiveContractAddress(
    get(currentCollection, 'meta')
  );

  const [isResponseLoading, setIsResponseLoading] = useRecoilState(
    IsResponseLoadingState
  );

  const { status, account } = useMetaMask();

  const [rpcURL, setRPCURL] = useState('');
  const [pKey, setPKey] = useState('');
  const [signerConfigOpen, setSignerConfigOpen] = useState(false);

  function onChangeRPCURL(e) {
    setRPCURL(e.target.value);
  }
  function onChangePrivateKey(e) {
    setPKey(e.target.value);
  }

  const openSignerConfig = () => {
    setSignerConfigOpen(true);
  };

  const closeSignerConfig = () => {
    setSignerConfigOpen(false);
  };

  const reset = () => {
    setRPCURL('');
    setPKey('');
  };

  const activeNewChain = find(tempABIs || [], (ac) => ac.isActive);

  const address = currentAddress || activeNewChain?.address;

  const network = get(activeContract, 'network') || activeNewChain?.network;

  const execute = (privateKey) => {
    const convertFormToMethod = CollectionService.convertFormToMethod({
      formData: collectionMethodFormData,
      selectedTab,
      allHistory: HistoryMethodList,
    });
    sendCollectionFn({
      formAddress: address,
      currentCollectionMethod: convertFormToMethod,
      account,
      collectionMethodFormData,
      setCollectionMethodFormData,
      network,
      setIsResponseLoading,
      privateKey,
    });
  };

  function handleSignerConfigSubmit() {
    // execute when connected metamask
    if (status === META_MASK_STATUS.CONNECTED) {
      execute();
      closeSignerConfig();
      reset();
      return;
    }

    const schema = object().shape({
      privateKey: string().required('Private key is Required'),
    });
    schema
      .validate({ privateKey: pKey }) // todo:  Validate ABI json, Files
      .then(() => {
        execute(pKey);
        closeSignerConfig();
        reset();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  }

  /**
   * execute method
   */
  function handleSaveSubmit() {
    // execute there are no method name
    if (!get(collectionMethodFormData, 'methodName')) {
      toast.error(Messages.INFO.METHOD_NAME_IS_REQUIRED);
      return;
    }

    if (!get(collectionMethodFormData, 'stateMutability')) {
      toast.error(Messages.INFO.STATE_MUTABILITY);
      return;
    }
    const newCollectionMethod = CollectionService.convertFormToMethod({
      formData: collectionMethodFormData,
      selectedTab,
    });
    newCollectionMethod.id = uuidv4();
    HistoryService.addHistory({
      tempABIs: get(currentCollection, 'meta.chainList') || tempABIs,
      method: newCollectionMethod,
      address: get(collectionMethodFormData, 'contractAddress'),
      chain: getChainDetailsByChainId(
        get(collectionMethodFormData, 'selectedChain')
      ),
    });
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_SEND, { network });
    const isPayable = formStateMutability === STATE_MUTUABLITIY_LIST.PAYABLE;
    if (network && network.type && network.type.id === 'near') {
      execute();
      return;
    }
    if (!isPayable || status === 'connected') {
      execute();
    } else {
      openSignerConfig();
    }
  }

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const rpcUrl = get(currentCollection, 'meta.rpcURL');
    setRPCURL(rpcUrl || '');
  }, [selectedTab]);

  // useEffect(() => {
  //   const listener = (event) => {
  //     if (event.code === 'Enter' || event.code === 'NumpadEnter') {
  //       event.preventDefault();
  //       handleSaveSubmit();
  //     }
  //   };
  //   document.addEventListener('keydown', listener);
  //   return () => {
  //     document.removeEventListener('keydown', listener);
  //   };
  // }, [collectionMethodFormData, selectedTab]);

  return (
    <>
      <Tooltip title="Execute method">
        <Button
          variant="contained"
          size="medium"
          className="inline-flex w-full items-center text-sm ml-2 h-10"
          onClick={handleSaveSubmit}
          endIcon={
            <IconWrapper>
              <SendIcon className="!w-full !h-full mb-1" />
            </IconWrapper>
          }
          disabled={isResponseLoading}>
          Send
        </Button>
      </Tooltip>
      <div>
        <Modal
          open={signerConfigOpen}
          handleClose={(e) => {
            e?.stopPropagation?.();
            closeSignerConfig(false);
            reset();
          }}
          submit={handleSignerConfigSubmit}
          primaryBtnText="Send"
          secondaryBtnText="Cancel">
          <div className="flex w-full p-4 justify-center flex-col items-center">
            <div className="flex items-center flex-col mb-2">
              <MetaMaskConnect width={200} chainId={get(network, 'chainId')} />
            </div>
            <DividerWithText>OR</DividerWithText>

            <div className="flex flex-col mt-5 w-11/12 mx-auto">
              {/* {!window.ethereum && (
                <Input
                  label="RPC Provider URL"
                  onChange={onChangeRPCURL}
                  value={rpcURL}
                  name="rpc-url"
                />
              )} */}
              <Input
                label="Private Key"
                onChange={onChangePrivateKey}
                value={pKey}
                name="private-key"
              />
              <Alert className={`mt-3`} severity="error">
                Entering your private key is a security risk. We do not store
                your private key and it will only be used to execute the
                function. As an alternative, you can connect your MetaMask
                wallet to execute this function securely. To do so, click on the
                &apos;Connect&apos; button and follow the instructions.
              </Alert>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SignConfigModal;
