import { Button, Link } from '@mui/material';
import get from 'lodash/get';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { number, object, string } from 'yup';
import Editor from '@monaco-editor/react';
import { uuidv4 } from '../../../../utils/UUIDV4';
import {
  IMPORT_SOURCE,
  W3C_ABI_VERSION,
} from '../../../../constants/AppConstants';
import JSONHelper from '../../../../utils/JSONHelper';
import Back from './Back';
import Input from '../../../../components/Input';
import NetworkSelector from '../../../NetworkSelector';

const ImportJsonFile = ({ handleBack, onSubmit, title }) => {
  const [networkSelectorData, setNetworkSelectorData] = useState({
    selectedChain: null,
    chainName: '',
    chainId: '',
    rpc: '',
    network: null,
    type: null,
  });
  const {
    selectedChain,
    chainName,
    chainId,
    rpc,
    warningMsg,
    isCustomAdd,
    network,
    type,
  } = networkSelectorData;

  function resetNetworkState() {
    setNetworkSelectorData({
      selectedChain: null,
      chainName: '',
      chainId: '',
      rpc: '',
      network: null,
      type: null,
    });
  }

  const [abiName, setAbiName] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [abiJSON, setABIJSON] = useState('');

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setABIJSON(JSON.stringify(JSON.parse(e.target.result), null, 2));
    };
  };

  function onChangeABIName(e) {
    setAbiName(e.target.value);
  }
  function onChangeContractAddress(e) {
    setContractAddress(e.target.value);
  }

  const generateInputIds = (inputsArray) => {
    return map(inputsArray, (obj) => {
      return {
        ...obj,
        id: uuidv4(),
      };
    });
  };

  function generateId(abiJSON) {
    return map(abiJSON, (_a) => ({
      ..._a,
      id: uuidv4(),
      inputs: generateInputIds(_a.inputs),
    }));
  }

  function handleSubmit() {
    const selectedChainId =
      get(selectedChain, 'chainId') ?? parseInt(chainId, 10);
    const selectedRPC = get(selectedChain, 'rpc.0') || rpc;
    const selectedChainName = get(selectedChain, 'name') || chainName;
    const selectedType = get(selectedChain, 'type') || type;
    const selectedNetworkStatus = get(selectedChain, 'network') || network;

    const getTypeBySchema = () => {
      if (selectedType?.id === 'near') {
        return {
          network: string().required("Network should'nt be empty"),
        };
      }
      return {
        chainId: number().nullable().required("Chain should'nt be empty"),
      };
    };

    const schema = object().shape({
      name: string().required("Collection Name Should'nt be empty"),
      address: string().required("Contract ID Should'nt be empty"),
      rpc: string().required("RPC URL should'nt be empty"),
      ...getTypeBySchema(),
      chainName: string().required("Chain name should'nt be empty"),
    });

    if (!abiJSON) {
      toast.error("ABI JSON Should'nt be empty");
      return;
    }
    if (!JSONHelper.hasJsonStructure(abiJSON)) {
      toast.error('Not a valid JSON');
      return;
    }

    if (abiJSON) {
      const getTypeByDefaultPayload = () => {
        if (selectedType?.id === 'near') {
          return {
            network: selectedNetworkStatus?.id,
          };
        }
        return {
          chainId: selectedChainId || null,
        };
      };
      const defaultPayload = {
        name: abiName,
        address: contractAddress,
        rpc: selectedRPC,
        ...getTypeByDefaultPayload(),
        chainName: selectedChainName,
      };
      const toastId = toast.loading('Loading...');
      schema
        .validate(defaultPayload) // todo:  Validate ABI json, Files
        .then(() => {
          onSubmit(
            {
              abi: generateId(JSON.parse(abiJSON)),
              id: uuidv4(),
              parentId: '',
              meta: {
                name: abiName,
                version: W3C_ABI_VERSION,
                chainList: [
                  {
                    id: uuidv4(),
                    name: selectedChainName,
                    address: contractAddress,
                    isActive: true,
                    network: {
                      chainId: selectedChainId,
                      rpc: selectedRPC,
                      chainName: selectedChainName,
                      type: selectedType,
                      network: selectedNetworkStatus,
                    },
                  },
                ],
                source: IMPORT_SOURCE.JSON,
              },
            },
            !warningMsg && isCustomAdd
          );
          toast.success('Created ABI', {
            id: toastId,
          });
          resetNetworkState();
        })
        .catch((err) => {
          toast.error(err?.message, {
            id: toastId,
          });
        });
    }
  }

  useEffect(() => {
    setAbiName('');
    setContractAddress('');
    setABIJSON('');
  }, []);

  function onChangeABIJSON(e) {
    setABIJSON(e);
  }

  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit(event);
        }
      }}>
      <Back handleBack={handleBack} title={title} />

      <div className="flex flex-col mt-5 w-11/12 mx-auto">
        <div className="overflow-auto pr-3 h-full">
          <Input
            label="ABI Name"
            onChange={onChangeABIName}
            value={abiName}
            name="abi-name"
          />

          <Input
            label="Contract ID"
            onChange={onChangeContractAddress}
            value={contractAddress}
            name="contract-address"
          />

          <NetworkSelector
            networkSelectorData={networkSelectorData}
            setNetworkSelectorData={setNetworkSelectorData}
          />

          <div className="w-full flex flex-row my-4 justify-between">
            <div className="flex text-sm mb-1 font-medium">ABI JSON</div>
            <div className="flex">
              <div className="flex items-center justify-center  bg-primary">
                <label className="flex flex-row items-center p-2 cursor-pointer">
                  <svg
                    className="w-6 h-6"
                    fill="#1682fc"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  <Link className="px-2">Upload file</Link>
                  <input
                    type="file"
                    title=""
                    accept=".json"
                    multiple
                    onChange={handleChange}
                    className="hidden"
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-col mb-4">
            <Editor
              className="!h-[calc(100vh-680px)]"
              placeholder="Paste ABI JSON here"
              name="abi"
              id="abi-json"
              onChange={onChangeABIJSON}
              value={abiJSON}
              autoComplete="off"
              defaultLanguage="json"
              options={{
                cursorStyle: 'line',
                formatOnPaste: true,
                formatOnType: true,
                wordWrap: true,
              }}
              onMount={(editor) => {
                setTimeout(function () {
                  editor.getAction('editor.action.formatDocument').run();
                }, 300);
              }}
            />
          </div>
          <Button
            variant="contained"
            className="w-20 mt-6"
            onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImportJsonFile;
