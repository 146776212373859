import { Badge, Tooltip } from '@mui/material';
import get from 'lodash/get';
import map from 'lodash/map';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { TabPanel } from 'react-headless-tabs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Messages from '../../../../constants/Messages';
import useTabs from '../../../../hooks/useTabs';
import CollectionService from '../../../../services/CollectionService';
import HistoryService from '../../../../services/HistoryService';
import TabService, { getTabsDetails } from '../../../../services/TabService';
import { HistoryMethodListState } from '../../../../states/HistoryState';
import { ModalOpenState } from '../../../../states/ModalState';
import {
  TabsState,
  UnSavedCollectionMethodTabsIdState,
} from '../../../../states/TabStates';
import NewTabDetails from '../NewTabDetails';
import Playground from '../Playground';
import MoreOptions from './MoreOptions';
import TabSelector from './TabSelector';

function DynamicTabs() {
  const { selectedTab, setSelectedTab, removeTab, getAllTabs } = useTabs();
  const setModalOpen = useSetRecoilState(ModalOpenState);
  const [closeTabId, setCloseTabId] = useState(null);
  const [tabList, setTabList] = useRecoilState(TabsState);

  // store unSave tab ids
  const unSavedCollectionMethodTabsId = useRecoilValue(
    UnSavedCollectionMethodTabsIdState
  );

  // all history list
  const allHistory = useRecoilValue(HistoryMethodListState);

  /**
   * close tab
   * @param {string} tab id
   */
  const closeTab = (tab) => {
    const isUnSaveMethod = unSavedCollectionMethodTabsId?.includes(selectedTab);
    if (isUnSaveMethod) {
      setCloseTabId(tab);
      setModalOpen({ open: true, key: 'close_tab' });
    } else {
      removeTab(tab);
    }
  };

  /**
   *  add tab
   * @param {string} tab id
   */
  const tabClick = (tab) => {
    setSelectedTab(tab);
  };

  const conditionTabClose = () => {
    removeTab(closeTabId);
    setModalOpen({ open: false });
  };

  window.onbeforeunload = () => {
    const isAnyUnSaveTab = some(getAllTabs(), (_tab) => {
      return unSavedCollectionMethodTabsId?.includes(_tab);
    });
    if (isAnyUnSaveTab) {
      return Messages.INFO.UNSAVED_CHANGES_WILL_BE_LOST_CONTINUE;
    }
  };

  /**
   * add untitled tab
   */
  const triggerNewMethod = () => {
    setSelectedTab(TabService.generateUntitledTabName());
  };

  const TabsDetailsPayload = {
    tabs: tabList,
    currentActiveTab: selectedTab,
  };

  /*  get tabs details from local Storage  */

  useEffect(() => {
    const TabsDetails = getTabsDetails();
    setTimeout(() => {
      setSelectedTab(TabsDetails.currentActiveTab);
      setTabList(TabsDetails.tabs || []);
    }, 100);
  }, []);

  return (
    <>
      <ul id="tabs-ui" className="border-b h-12 border-secondary">
        {map(getAllTabs(), (_tab, _tabIndex, arr) => {
          const badge = unSavedCollectionMethodTabsId?.includes(_tab) ? (
            <Badge
              color="secondary"
              variant="dot"
              sx={{
                '&>.css-15dsewq-MuiBadge-badge': {
                  minWidth: '6px !important',
                  height: '6px !important',
                },
              }}></Badge>
          ) : (
            <span />
          );
          /**
           * get tab name
           * @param {string} tab id
           * @returns {string} tab name
           */
          const getTabName = (tab) => {
            if (TabService.isUntitledTab(tab)) {
              return 'New Method';
            }
            if (TabService.isNewTab(tab)) {
              return 'New';
            }
            if (TabService.isHistoryTab(tab)) {
              const methodId = TabService.getCollectionMethodId(tab);
              const findHistory = HistoryService.findHistoryByMethodId({
                methodId,
                allHistory,
              });
              return get(findHistory, 'name');
            }
            const name = CollectionService.findCollectionMethod(tab)?.name;
            return name;
          };

          return (
            <TabSelector
              key={_tab}
              isActive={selectedTab === _tab}
              onClick={() => tabClick(_tab)}
              onClose={() => closeTab(_tab)}
              index={_tabIndex}
              totalCount={arr?.length}
              isNewTab={TabService.isNewTab(_tab)}
              badge={badge}>
              {getTabName(_tab)}
            </TabSelector>
          );
        })}
        <li className="py-4 inline-flex items-center">
          <Tooltip title="Add new method">
            <button
              className="ml-2 rounded-full text-sm text-primary focus:outline-none focus:shadow-outline hover:bg-secondary hover:text-secondary transition duration-300 ease-in-out"
              onClick={triggerNewMethod}>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
              </svg>
            </button>
          </Tooltip>
        </li>
        <li className="py-4 inline-flex items-center">
          <MoreOptions conditionTabClose={conditionTabClose} />
        </li>
      </ul>

      <div className="p-4 overflow-auto">
        {map(getAllTabs(), (_tab) => {
          return (
            <TabPanel
              key={_tab}
              hidden={selectedTab !== _tab}
              style={{ height: 'calc(100vh - 150px)' }}>
              {TabService.isNewTab(_tab) && <NewTabDetails tab={_tab} />}
              {!TabService.isNewTab(_tab) && <Playground _tab={_tab} />}
            </TabPanel>
          );
        })}
      </div>
    </>
  );
}

export default DynamicTabs;
