import {
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export function Modal({
  open,
  handleClose,
  title,
  subTitle,
  submit,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnColor,
  children,
  isActionRequired = true,
  maxWidth,
}) {
  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          submit(event);
        }
      }}>
      <Dialog
        fullWidth
        maxWidth={maxWidth || 'sm'}
        open={open}
        onClose={handleClose}>
        {title ? (
          <DialogTitle
            sx={{
              '&.MuiTypography-root': {
                paddingBottom: '8px',
              },
            }}>
            {title}
          </DialogTitle>
        ) : (
          <div className="mt-9" />
        )}
        <DialogContent>
          <DialogContentText>{children || subTitle}</DialogContentText>
        </DialogContent>
        {isActionRequired && (
          <DialogActions>
            <Button onClick={handleClose}>{secondaryBtnText}</Button>
            <Button
              variant="contained"
              color={primaryBtnColor}
              onClick={submit}>
              {primaryBtnText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
