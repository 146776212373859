import { Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import MetaMaskConnect from '../../../../components/MetaMaskConnect';
import { NETWORKS } from '../../../../constants/AppConstants';
import NearWalletSelector from '../../../../modules/NearWalletSelector';
import { subscribeToNearAuthState } from '../../../../services/EventService';

const WalletOptions = ({ networkType, network }) => {
  const [showSignOut, setShowSignOut] = useState(false);
  async function setupNear(networkID) {
    await NearWalletSelector.setupSelectorForNetWorkIfNotAvailable(networkID);
  }
  useEffect(() => {
    if (networkType === NETWORKS.NEAR) {
      setupNear(network);
    }
    setShowSignOut(NearWalletSelector.isLoggedIntoNetwork(network));
    const unsub = subscribeToNearAuthState(() => {
      setShowSignOut(NearWalletSelector.isLoggedIntoNetwork(network));
    });
    return () => {
      unsub && unsub();
    };
  }, [network, networkType]);
  if (networkType === NETWORKS.ETHERS) return <MetaMaskConnect />;
  return (
    <>
      {showSignOut && (
        <div>
          <Tooltip title="Sign Out">
            <Button
              variant="outlined"
              className="cursor-pointer flex items-center space-x-2 pr-2"
              onClick={() => NearWalletSelector.logOutFromNetwork(network)}>
              <div>Sign Out</div>
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default WalletOptions;
