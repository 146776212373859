import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
/**
 * create ABI payload
 * @param {object} options
 * @returns {object} ABI payload
 */
export function getABIPayload(options) {
  const {
    formAddress: contractAddress,
    currentCollectionMethod,
    formMethodName: methodName,
    formStateMutability: stateMutability,
    formParamNames: ABIFormParameterNameState,
    formParamTypes: ABIFormParameterTypeState,
    formValues: ABIFormValuesState,
    account,
    network,
    privateKey,
    options: executorOptions,
  } = options;

  return {
    contractAddress,
    ABI: [
      {
        ...currentCollectionMethod,
        name: methodName,
        stateMutability,
        inputs: get(currentCollectionMethod, 'inputs', []).map((obj) => {
          const id = get(obj, 'id', '');
          return {
            ...obj,
            name: Object.keys(ABIFormParameterNameState).includes(id)
              ? get(ABIFormParameterNameState, id, '')
              : get(obj, 'name', ''),
            type: Object.keys(ABIFormParameterTypeState).includes(id)
              ? get(ABIFormParameterTypeState, id, '')
              : get(obj, 'type', ''),
          };
        }),
      },
    ],
    method: methodName,
    values: mapKeys(ABIFormValuesState, function (value, key) {
      const findKeyInABIState = get(ABIFormParameterNameState, key, '');
      const findingOldParameterName = find(
        get(currentCollectionMethod, 'inputs', []),
        (paraObj) => get(paraObj, 'id', '') === key
      );
      if (!isEmpty(findKeyInABIState)) {
        return findKeyInABIState;
      }
      return get(findingOldParameterName, 'name', key);
    }),
    account,
    stateMutability,
    network,
    privateKey,
    executorOptions,
  };
}
