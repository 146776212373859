import Editor from '@monaco-editor/react';

import { useEffect, useRef } from 'react';

const CodeEditor = (props) => {
  const monacoRef = useRef();
  function handleEditorDidMount(editor, monaco) {
    monaco.editor.setTheme(
      props.mode === 'dark' ? 'w3c-theme' : 'w3c-theme-light'
    );
    monacoRef.current = monaco;
  }
  const handleEditorWillMount = (monaco) => {
    monaco.editor.defineTheme('w3c-theme-light', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#00000000', // transparent
      },
    });
    monaco.editor.defineTheme('w3c-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#00000000', // transparent
      },
    });
  };

  useEffect(() => {
    monacoRef.current &&
      monacoRef.current.editor.setTheme(
        props.mode === 'dark' ? 'w3c-theme' : 'w3c-theme-light'
      );
  }, [props.mode, monacoRef]);

  return (
    <Editor
      {...props}
      loading=""
      // className="overflow-scroll h-20 lg:h-[100vh-30rem]"
      beforeMount={handleEditorWillMount}
      onMount={handleEditorDidMount}
    />
  );
};

export default CodeEditor;
