import {
  Drawer,
  Tooltip,
  IconButton,
  //   MenuItem,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import MarkdownPreview from '@uiw/react-markdown-preview';
import IconWrapper from '../../components/IconWrapper';
import { AppSettings } from '../../states/SettingsState';
import Messages from '../../constants/Messages';
import EditMarkdown from '../../components/EditMarkdown';
import { CollectionListState } from '../../states/CollectionState';
import { DOCS } from '../../constants/AppConstants';
import DocsServices from '../../services/DocsServices';

const AutoGenerateDocsABI = ({
  openDrawer,
  setOpenDrawer,
  activeCollection,
}) => {
  const setting = useRecoilValue(AppSettings);

  const [isEditAbiName, setIsEditAbiName] = useState(false);
  const [isEditAbiDescription, setIsEditAbiDescription] = useState(false);
  const [isEditAbiDetails, setIsEditAbiDetails] = useState(false);

  const [isEditMethodName, setIsEditMethodName] = useState({});
  const [isEditMethodDescription, setIsEditMethodDescription] = useState({});
  const [isEditMethodDetails, setIsEditMethodDetails] = useState({});

  // all ABI list
  const collectionList = useRecoilValue(CollectionListState);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(isOpen);
  };

  //  --------   handle Abi Name  start--------------
  const onAbiNameClose = () => {
    setIsEditAbiName(false);
  };
  const onAbiNameSubmit = (data) => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.ABI_NAME,
      value: data,
      collectionList,
      activeCollection,
    });
    setIsEditAbiName(false);
  };
  //  --------   handle Abi Name  end--------------

  //  --------   handle Abi description  start--------------
  const onAbiDescriptionClose = () => {
    setIsEditAbiDescription(false);
  };
  const onAbiDescriptionSubmit = (data) => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.ABI_DESCRIPTION,
      value: data,
      collectionList,
      activeCollection,
    });
    setIsEditAbiDescription(false);
  };
  //  --------   handle Abi description  end--------------

  //  --------   handle Abi details  start--------------
  const onAbiDetailsClose = () => {
    setIsEditAbiDetails(false);
  };
  const onAbiDetailsSubmit = (data) => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.ABI_DETAILS,
      value: data,
      collectionList,
      activeCollection,
    });

    setIsEditAbiDetails(false);
  };
  //  --------   handle Abi details  end--------------

  //  --------   handle Method name  start--------------
  const onMethodNameClose = () => {
    setIsEditMethodName(false);
  };
  const onMethodNameSubmit = (data, methodId) => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.METHOD_NAME,
      value: data,
      methodId,
      collectionList,
      activeCollection,
    });
    setIsEditMethodName(false);
  };
  //  --------   handle Method name  end--------------

  //  --------   handle Method description  start--------------
  const onMethodDescriptionClose = () => {
    setIsEditMethodDescription(false);
  };
  const onMethodDescriptionSubmit = (data, methodId) => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.METHOD_DESCRIPTION,
      value: data,
      methodId,
      collectionList,
      activeCollection,
    });
    setIsEditMethodDescription(false);
  };
  //  --------   handle Method description  end--------------

  //  --------   handle Method details  start--------------
  const onMethodDetailsClose = () => {
    setIsEditMethodDetails(false);
  };
  const onMethodDetailsSubmit = (data, methodId) => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.METHOD_DETAILS,
      value: data,
      methodId,
      collectionList,
      activeCollection,
    });
    setIsEditMethodDetails(false);
  };
  //  --------   handle Method details  end--------------

  const handleRegenerateDocs = () => {
    DocsServices.setDocumentationInAbi({
      updateStatus: DOCS.REGENERATE_ABI_AND_METHOD,
      collectionList,
      activeCollection,
    });
  };

  useEffect(() => {
    if (!openDrawer) return;
    DocsServices.setDocumentationInAbi({
      collectionList,
      activeCollection,
    });
  }, [openDrawer]);

  const markdownAbiName = get(
    activeCollection,
    'documentation.content.abiName'
  );

  const markdownAbiDescription = get(
    activeCollection,
    'documentation.content.abiDescription'
  );

  const markdownAbiDetails = get(
    activeCollection,
    'documentation.content.abiDetails'
  );

  let fullMarkdownData = `${markdownAbiName} ${markdownAbiDescription} ${markdownAbiDetails}`;

  forEach(
    get(activeCollection, 'documentation.content.methodName'),
    (_methodName, _id1) => {
      forEach(
        get(activeCollection, 'documentation.content.methodDescription'),
        (_methodDescription, _id2) => {
          forEach(
            get(activeCollection, 'documentation.content.methodDetails'),
            (_methodDetails, _id3) => {
              if (_id1 === _id2 && _id1 === _id3 && _id2 === _id3) {
                fullMarkdownData += _methodName;
                fullMarkdownData += _methodDescription;
                fullMarkdownData += _methodDetails;
              }
            }
          );
        }
      );
    }
  );

  function renderDrawer() {
    return (
      <div className="lg:w-[42vw] w-[85vw] flex flex-col h-full p-6 bg-primary">
        <div className="flex items-center mb-5">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <div className="font-bold text-xl">Documentation</div>
              <CopyToClipboard
                text={fullMarkdownData}
                className="cursor-pointer flex items-center ml-2"
                onCopy={() => {
                  toast.success(Messages.INFO.COPY_CLIPBOARD);
                }}>
                <div className="text-xs text-slate-400 ml-2">
                  <Tooltip title="Copy">
                    <FileCopyIcon fontSize="14px" className="mt-0.5" />
                  </Tooltip>
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex ml-auto space-x-3 p-2">
            <Tooltip title="Regenerate Documentation">
              <Link
                className="cursor-pointer flex items-center"
                onClick={handleRegenerateDocs}>
                <ArticleIcon />
                <Typography className="!ml-2">
                  Regenerate Documentation
                </Typography>
              </Link>
            </Tooltip>
            <Tooltip title="close">
              <IconWrapper className="flex">
                <IconButton aria-label="close" onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </IconWrapper>
            </Tooltip>
          </div>
        </div>

        <div className="group">
          {isEditAbiName ? (
            <EditMarkdown
              onClose={onAbiNameClose}
              onSubmit={onAbiNameSubmit}
              markdown={markdownAbiName}
              minHeight="5rem"
            />
          ) : (
            <div id="auto-generate-doc" className="flex space-x-3 items-center">
              {markdownAbiName ? (
                <MarkdownPreview
                  source={`${markdownAbiName}`}
                  className="bg-primary"
                  wrapperElement={{
                    'data-color-mode': setting.theme,
                  }}
                />
              ) : (
                <span className="text-gray-300 text-lg">Abi Name</span>
              )}

              <div className="mb-2">
                <Tooltip title="Edit ABI">
                  <Link
                    className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                    onClick={() => {
                      setIsEditAbiName(true);
                    }}>
                    <div className="hidden group-hover:block">
                      <BorderColorIcon
                        className="text-gray-200 hover:text-gray-500"
                        sx={{ fontSize: '15px' }}
                      />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <div className="group">
          {isEditAbiDescription ? (
            <EditMarkdown
              onClose={onAbiDescriptionClose}
              onSubmit={onAbiDescriptionSubmit}
              markdown={markdownAbiDescription}
              minHeight="5rem"
            />
          ) : (
            <div id="auto-generate-doc" className="flex space-x-3 items-center">
              {markdownAbiDescription ? (
                <MarkdownPreview
                  source={`${markdownAbiDescription}`}
                  className="bg-primary"
                  wrapperElement={{
                    'data-color-mode': setting.theme,
                  }}
                />
              ) : (
                <span className="text-gray-300 text-lg">Description</span>
              )}

              <Tooltip title="Edit ABI">
                <Link
                  className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                  onClick={() => {
                    setIsEditAbiDescription(true);
                  }}>
                  <div className="hidden group-hover:block">
                    <BorderColorIcon
                      className="text-gray-200 mb-1 hover:text-gray-500"
                      sx={{ fontSize: '15px' }}
                    />
                  </div>
                </Link>
              </Tooltip>
            </div>
          )}
        </div>

        {isEditAbiDetails ? (
          <EditMarkdown
            onClose={onAbiDetailsClose}
            onSubmit={onAbiDetailsSubmit}
            markdown={markdownAbiDetails}
          />
        ) : (
          <div id="auto-generate-doc" className="mb-10">
            <div className="flex justify-end mb-3">
              <Tooltip title="Edit Method">
                <Link
                  className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                  onClick={() => {
                    setIsEditAbiDetails(true);
                  }}>
                  <BorderColorIcon
                    className="text-gray-200 hover:text-gray-500"
                    sx={{ fontSize: '15px' }}
                  />
                </Link>
              </Tooltip>
            </div>

            {markdownAbiDetails ? (
              <MarkdownPreview
                source={`${markdownAbiDetails}`}
                className="bg-primary"
                wrapperElement={{
                  'data-color-mode': setting.theme,
                }}
              />
            ) : (
              <span className="text-gray-300 text-lg">Abi Details</span>
            )}
          </div>
        )}

        {map(get(activeCollection, 'abi'), (_method) => {
          if (_method.type !== 'function') return null;
          const markdownMethodDescription = get(
            activeCollection,
            `documentation.content.methodDescription.${get(_method, 'id')}`
          );
          const markdownMethodName = get(
            activeCollection,
            `documentation.content.methodName.${get(_method, 'id')}`
          );
          const markdownMethodDetails = get(
            activeCollection,
            `documentation.content.methodDetails.${get(_method, 'id')}`
          );

          return (
            <>
              <div className="group">
                {get(isEditMethodName, `${get(_method, 'id')}`) ? (
                  <EditMarkdown
                    onClose={onMethodNameClose}
                    onSubmit={(data) =>
                      onMethodNameSubmit(data, get(_method, 'id'))
                    }
                    markdown={markdownMethodName}
                    minHeight="5rem"
                  />
                ) : (
                  <div
                    id="auto-generate-doc"
                    className="flex space-x-3 items-center mb-3">
                    {markdownMethodName ? (
                      <MarkdownPreview
                        source={`${markdownMethodName}`}
                        className="bg-primary"
                        wrapperElement={{
                          'data-color-mode': setting.theme,
                        }}
                      />
                    ) : (
                      <span className="text-gray-300 text-lg">Method Name</span>
                    )}

                    <div>
                      <Tooltip title="Edit ABI">
                        <Link
                          className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                          onClick={() => {
                            setIsEditMethodName((pre) => ({
                              ...pre,
                              [get(_method, 'id')]: true,
                            }));
                          }}>
                          <div className="hidden group-hover:block">
                            <BorderColorIcon
                              className="text-gray-200 hover:text-gray-500"
                              sx={{ fontSize: '15px' }}
                            />
                          </div>
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>

              <div className="group">
                {get(isEditMethodDescription, `${get(_method, 'id')}`) ? (
                  <EditMarkdown
                    onClose={onMethodDescriptionClose}
                    onSubmit={(data) =>
                      onMethodDescriptionSubmit(data, get(_method, 'id'))
                    }
                    markdown={markdownMethodDescription}
                    minHeight="5rem"
                  />
                ) : (
                  <div
                    id="auto-generate-doc"
                    className="flex space-x-3 items-center mb-3">
                    {markdownMethodDescription ? (
                      <MarkdownPreview
                        source={`${markdownMethodDescription}`}
                        className="bg-primary"
                        wrapperElement={{
                          'data-color-mode': setting.theme,
                        }}
                      />
                    ) : (
                      <span className="text-gray-300 text-lg">Description</span>
                    )}
                    <div>
                      <Tooltip title="Edit ABI">
                        <Link
                          className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                          onClick={() => {
                            setIsEditMethodDescription((pre) => ({
                              ...pre,
                              [get(_method, 'id')]: true,
                            }));
                          }}>
                          <div className="hidden group-hover:block">
                            <BorderColorIcon
                              className="text-gray-200 hover:text-gray-500"
                              sx={{ fontSize: '15px' }}
                            />
                          </div>
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>

              {get(isEditMethodDetails, `${get(_method, 'id')}`) ? (
                <EditMarkdown
                  onClose={onMethodDetailsClose}
                  onSubmit={(data) =>
                    onMethodDetailsSubmit(data, get(_method, 'id'))
                  }
                  markdown={markdownMethodDetails}
                />
              ) : (
                <div id="auto-generate-doc" className="mb-10">
                  <div className="flex justify-end">
                    <Tooltip title="Edit Method">
                      <Link
                        className="cursor-pointer flex items-center space-x-2 pr-2 text-lg"
                        onClick={() => {
                          setIsEditMethodDetails((pre) => ({
                            ...pre,
                            [get(_method, 'id')]: true,
                          }));
                        }}>
                        <BorderColorIcon
                          className="text-gray-200 hover:text-gray-500"
                          sx={{ fontSize: '15px' }}
                        />
                      </Link>
                    </Tooltip>
                  </div>

                  {markdownMethodDetails ? (
                    <MarkdownPreview
                      source={`${markdownMethodDetails}`}
                      className="bg-primary"
                      wrapperElement={{
                        'data-color-mode': setting.theme,
                      }}
                    />
                  ) : (
                    <span className="text-gray-300 text-lg">
                      Method Details
                    </span>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Drawer
        sx={{
          zIndex: 999999,
          '& .MuiPaper-root': {
            backgroundColor: setting.theme === 'dark' ? '#1A1A2E' : 'white',
          },
        }}
        anchor={'right'}
        open={openDrawer}
        onClose={toggleDrawer(false)}>
        {renderDrawer()}
      </Drawer>
    </div>
  );
};

export default AutoGenerateDocsABI;
