import { initializeApp } from 'firebase/app';

import { getAnalytics, logEvent } from 'firebase/analytics';
import isArray from 'lodash/isArray';
import Configuration from '../../../../config';
import baseController from './Base';

const { FirebaseConfiguration } = Configuration;

const firebaseController = {
  boot(cb) {
    console.log(
      `FirebaseController~Initiate in ${this.type} telemetry service`
    );
    const firebaseConfig = FirebaseConfiguration[process.env.REACT_APP_CHANNEL];

    // Initialize Firebase

    const app = initializeApp(firebaseConfig);

    /**
     * Initializing Analytics
     */
    this.analytics = getAnalytics(app);
    /**
     * Initializing Performance
     */
    // this.performance = firebase.performance();
    cb && cb(null, this);
  },

  sendEvent(key = '', value = {}) {
    if (typeof value !== 'object' || isArray(value)) {
      console.error(
        'FirebaseController~sendEvent: failure value must be of type object'
      );
    }
    const source = 'web3client-web-app';
    const eventValue = {
      ...value,
      source,
      channel: process.env.REACT_APP_CHANNEL,
    };
    const lowerCaseKey = key.toLowerCase();
    logEvent(this.analytics, lowerCaseKey, eventValue);
    if (process.env.REACT_APP_CHANNEL === 'dev') {
      console.log(
        'FirebaseController~sendEvent: success',
        lowerCaseKey,
        eventValue
      );
    }
  },
};
export default () =>
  Object.assign(Object.create(baseController), firebaseController, {
    type: 'firebase',
  });
