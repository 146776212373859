import API from '../APIService';

const APIURL = process.env.REACT_APP_CLIENT_API_URL;
const InternalEventService = {
  sendEvent(options) {
    return new Promise((resolve, reject) => {
      const url = `${APIURL}/api/v1/analytics/event-data`;
      API.fetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};
export default InternalEventService;
