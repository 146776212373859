import { NETWORKS } from '../../constants/AppConstants';
import ether from './ether';
import near from './near';

const get = (type = NETWORKS.ETHERS) => {
  if (type === NETWORKS.ETHERS) {
    return ether;
  }
  return near;
};

const executor = {
  get,
};

export default executor;
