import List from '@mui/material/List';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getAllEVMChains } from '../../../../services/ChainService';

import ABIListItem from './ABIListItem';
import ChainColor, { defaultColor } from '../../../../constants/ChainColor';

import {
  CollectionListState,
  CurrentExpandedCollectionId,
} from '../../../../states/CollectionState';
import ABIListItemMethod from './ABIListItemMethod';
import CollectionService from '../../../../services/CollectionService';

const ABIsList = () => {
  // store ABI list in recoil state
  const collectionList = useRecoilValue(CollectionListState);

  const [chains, setChains] = useState([]);
  const [expandedId, setExpandedId] = useRecoilState(
    CurrentExpandedCollectionId
  );
  const handleClick = (i) => {
    if (i === expandedId) {
      setExpandedId('');
    } else {
      setExpandedId(i);
    }
  };

  const selectChainIcon = (d) => {
    const getSelectedchain = find(
      chains,
      (_chain) =>
        Number(_chain?.chainId) ===
        Number(CollectionService.getActiveChain(d?.meta))
    );
    const getSymbolName = get(getSelectedchain, 'nativeCurrency.symbol');
    const getSelectedcolor = find(
      ChainColor,
      (_ChainColor) => _ChainColor?.symbolName === getSymbolName
    );

    const selectedcolor = {
      symbolName: get(getSelectedchain, 'name'),
      color: defaultColor,
    };

    if (getSelectedcolor) {
      return getSelectedcolor;
    }
    return selectedcolor;
  };

  const getChainsData = () => {
    const explorersChains = getAllEVMChains();
    const chainList = map(explorersChains, (_d) => ({
      ..._d,
      label: _d?.name,
      value: _d?.chainId,
    }));
    setChains(chainList);
  };

  useEffect(() => {
    setTimeout(() => {
      getChainsData();
    }, 300);
  }, []);

  if (isEmpty(chains)) return null;

  return (
    <List
      className="!p-0"
      sx={{
        position: 'relative',
        overflow: 'auto',
        height: 'calc(100vh - 165px)',
      }}>
      <li>
        {map(collectionList, (_c) => {
          return (
            <>
              <ABIListItem
                selectChainIcon={selectChainIcon}
                handleClick={handleClick}
                _c={_c}
              />
              <ABIListItemMethod _c={_c} />
            </>
          );
        })}
      </li>
    </List>
  );
};
export default ABIsList;
