import Box from '@mui/material/Box';
import get from 'lodash/get';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IMPORT_TYPES } from '../../../../constants/AppConstants';
import CollectionService from '../../../../services/CollectionService';
import { AppSettings } from '../../../../states/SettingsState';
import ImportChainExplorer from './ImportChainExplorer';
import ImportJsonFile from './ImportJsonFile';
import NewABI from './NewABI';
import ImportABI from './ImportAbi';
import SelectFromTemplate from './SelectFromTemplate';
import TelemetryService from '../../../../services/TelemetryService';
import { TelemetryEvents } from '../../../../constants/EventsConstants';
import { saveChain } from '../../../../services/ChainService';
import { uuidv4 } from '../../../../utils/UUIDV4';

/**
 * create new ABI form wrapper
 */
const TransitionDetails = ({ children, isTransitionDetailsPage }) => {
  return (
    <div
      className={`transition ease-in-out duration-500 ${
        isTransitionDetailsPage
          ? 'opacity-1 translate-x-0'
          : 'opacity-0 translate-x-32'
      }`}>
      {children}
    </div>
  );
};

export default function NewTabDetails({ tab }) {
  const matches = useMediaQuery('(min-width:880px)');

  const [selected, setSelected] = useState('');
  const [isTransitionMainPage, setIsTransitionMainPage] = useState(false);
  const [isTransitionDetailsPage, setIsTransitionDetailsPage] = useState(false);

  const setting = useRecoilValue(AppSettings);

  /**
   * back workspace main page
   */
  const handleBack = (type) => {
    setIsTransitionDetailsPage(false);
    setTimeout(() => {
      setIsTransitionMainPage(true);
      setSelected(null);
      setTimeout(() => {
        setIsTransitionMainPage(false);
      }, 300);
    }, 300);
    type &&
      TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_IMPORT_BACK, {
        import_type: type,
      });
  };

  function handleCollectionCreation(payload, isStore) {
    if (isStore) {
      const { chainId, rpc, chainName, type, network } = get(
        payload,
        'meta.chainList.0.network',
        {}
      );
      saveChain({
        chainId,
        rpc,
        name: chainName,
        type,
        network,
        id: uuidv4(),
      });
    }
    CollectionService.updateCollectionList(payload);
    handleBack();
  }

  if (selected === IMPORT_TYPES?.IMPORT_BY_CONTRACT_ID) {
    return (
      <TransitionDetails isTransitionDetailsPage={isTransitionDetailsPage}>
        <ImportChainExplorer
          handleBack={handleBack}
          onSubmit={handleCollectionCreation}
          title={IMPORT_TYPES?.IMPORT_BY_CONTRACT_ID}
        />
      </TransitionDetails>
    );
  }
  if (selected === IMPORT_TYPES?.CREATE_FROM_STANDARDS) {
    return (
      <TransitionDetails isTransitionDetailsPage={isTransitionDetailsPage}>
        <SelectFromTemplate
          handleBack={handleBack}
          onSubmit={handleCollectionCreation}
          title={IMPORT_TYPES?.CREATE_FROM_STANDARDS}
        />
      </TransitionDetails>
    );
  }
  if (selected === IMPORT_TYPES?.IMPORT_FROM_JSON) {
    return (
      <TransitionDetails isTransitionDetailsPage={isTransitionDetailsPage}>
        <ImportJsonFile
          handleBack={handleBack}
          onSubmit={handleCollectionCreation}
          title={IMPORT_TYPES?.IMPORT_FROM_JSON}
        />
      </TransitionDetails>
    );
  }
  if (selected === IMPORT_TYPES?.CREATE_NEW_ABI) {
    return (
      <TransitionDetails isTransitionDetailsPage={isTransitionDetailsPage}>
        <NewABI
          handleBack={handleBack}
          onSubmit={handleCollectionCreation}
          title={IMPORT_TYPES?.CREATE_NEW_ABI}
        />
      </TransitionDetails>
    );
  }
  if (selected === IMPORT_TYPES?.IMPORT_ABI) {
    return (
      <TransitionDetails isTransitionDetailsPage={isTransitionDetailsPage}>
        <ImportABI
          handleBack={handleBack}
          onSubmit={handleCollectionCreation}
          title={IMPORT_TYPES?.IMPORT_ABI}
        />
      </TransitionDetails>
    );
  }

  /**
   * go tonew ABI form page
   */
  const gotoDetailsPage = (page) => {
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_NEW_ABI, {
      import_type: page,
    });
    setIsTransitionMainPage(true);
    setTimeout(() => {
      setIsTransitionDetailsPage(false);
      setSelected(page);
      setTimeout(() => {
        setIsTransitionDetailsPage(true);
      }, 300);
    }, 300);
  };

  function isDark() {
    if (setting.theme === 'dark') {
      return true;
    }
    return false;
  }

  return (
    <Box
      className={`mt-3 lg:mt-5 mx-2 lg:mx-0 transition ease-in-out duration-500 ${
        isTransitionMainPage
          ? 'opacity-0 -translate-x-32'
          : 'opacity-1 translate-x-0'
      }`}>
      <div className="w-full flex flex-col md:flex-row items-center mt-4 place-content-evenly">
        <div className="flex flex-col pt-13 lg:pt-16 lg:text-left text-center mt-4 order-2 md:order-1">
          <h1 className="font-bold text-3xl p-0 lg:p-3">
            Welcome to Web3client
          </h1>
          <p className="font-sm text-base pl-3">
            The easiest and most efficient way to create, test and share ABI’s
          </p>
        </div>
        <div className=" max-w-md order-1 md:order-2">
          <Box
            component="img"
            wrapperClassName="wrapper"
            sx={{ objectFit: 'cover' }}
            src={
              isDark()
                ? '/image-web3client-dark.png'
                : '/image-web3client-dark.png'
            }
            alt="home-page"
            className="w-64 h-64"
          />
        </div>
      </div>

      <div
        className={`w-full flex flex-wrap lg:flex-nowrap items-center justify-center ${
          matches
            ? 'space-x-8 space-y-0 flex-row gap-4 px-8 my-32'
            : 'space-x-0 space-y-28 flex-col gap-5 px-0  my-14'
        }  `}>
        <div className="flex space-x-10">
          <div
            className="lg:w-32 w-24 lg:h-32 h-24 hover:border-[#1682fc] shadow-md cursor-pointer items-center justify-center bg-primary font-medium rounded border-t border-r border-l border-b -mb-px border-secondary"
            onClick={() => gotoDetailsPage(IMPORT_TYPES?.CREATE_NEW_ABI)}>
            <img
              src={
                isDark()
                  ? '/icons/icon-new-abi-dark.svg'
                  : '/icons/icon-new-abi.svg'
              }
              alt="icon-new"
              width="70px"
              height="70px"
              className="lg:p-5 p-7 ml-2 lg:ml-6 mt-2 lg:mt-5"></img>
            <p className="text-center pt-3 lg:pt-10">
              {IMPORT_TYPES?.CREATE_NEW_ABI}
            </p>
          </div>
          <div
            className="lg:w-32 w-24 lg:h-32 h-24 hover:border-[#1682fc] active:border-[#1682fc] shadow-md cursor-pointer items-center justify-center bg-primary font-medium rounded border-t border-r border-l border-b -mb-px border-secondary"
            onClick={() =>
              gotoDetailsPage(IMPORT_TYPES?.IMPORT_BY_CONTRACT_ID)
            }>
            <img
              src={
                isDark()
                  ? '/icons/icon-chain-explorer-dark.svg'
                  : '/icons/icon-chain-explorer.svg'
              }
              alt="icon-chain"
              width="70px"
              height="70px"
              className="lg:p-5 p-7 ml-2 lg:ml-6 mt-2 lg:mt-5"></img>
            <p className="text-center pt-3 lg:pt-10">
              {IMPORT_TYPES?.IMPORT_BY_CONTRACT_ID}
            </p>
          </div>
          <div
            className="lg:w-32 w-24 lg:h-32 h-24 hover:border-[#1682fc] shadow-md cursor-pointer items-center justify-center bg-primary font-medium rounded border-t border-r border-l border-b -mb-px border-secondary"
            onClick={() =>
              gotoDetailsPage(IMPORT_TYPES?.CREATE_FROM_STANDARDS)
            }>
            <img
              src={
                isDark()
                  ? '/icons/icon-select-from-template-dark.svg'
                  : '/icons/icon-select-from-template.svg'
              }
              alt="icon-temp"
              width="70px"
              height="70px"
              className="lg:p-5 p-7 ml-2 lg:ml-6 mt-2 lg:mt-5"></img>
            <p className="text-center pt-3 lg:pt-10">
              {IMPORT_TYPES?.CREATE_FROM_STANDARDS}
            </p>
          </div>
        </div>
        <div className="flex space-x-10">
          <div
            className="lg:w-32 w-24 lg:h-32 h-24 hover:border-[#1682fc] shadow-md cursor-pointer items-center justify-center bg-primary font-medium rounded border-t border-r border-l border-b -mb-px border-secondary"
            onClick={() => gotoDetailsPage(IMPORT_TYPES?.IMPORT_FROM_JSON)}>
            <img
              src={
                isDark()
                  ? '/icons/icon-json-file-dark.svg'
                  : '/icons/icon-json-file.svg'
              }
              alt="icon-file"
              width="70px"
              height="70px"
              className="lg:p-5 p-7 ml-2 lg:ml-6 mt-2 lg:mt-5"></img>
            <p className="text-center pt-3 lg:pt-10">
              {IMPORT_TYPES?.IMPORT_FROM_JSON}
            </p>
          </div>
          <div
            className="lg:w-32 w-24 lg:h-32 h-24 hover:border-[#1682fc] shadow-md cursor-pointer items-center justify-center bg-primary font-medium rounded border-t border-r border-l border-b -mb-px border-secondary"
            onClick={() => gotoDetailsPage(IMPORT_TYPES?.IMPORT_ABI)}>
            <img
              src={
                isDark()
                  ? '/icons/icon-import-abi-dark.svg'
                  : '/icons/icon-import-abi.svg'
              }
              alt="icon-new"
              width="70px"
              height="70px"
              className="lg:p-5 p-7 ml-2 lg:ml-6 mt-2 lg:mt-5"></img>
            <p className="text-center pt-3 lg:pt-10">
              {IMPORT_TYPES?.IMPORT_ABI}
            </p>
          </div>
        </div>
      </div>
    </Box>
  );
}
