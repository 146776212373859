/* eslint-disable new-cap */
import { ethers } from 'ethers';
import get from 'lodash/get';
import { STATE_MUTUABLITIY_LIST } from '../../constants/AppConstants';

const _getEthereumProvider = (network) => {
  return new ethers.providers.Web3Provider(window.ethereum, {
    chainId: Number(network?.chainId),
  });
};

const _getDefaultProvider = (network) => {
  return new ethers.providers.getDefaultProvider(network);
};

const _getJsonRpcProvider = (rpcURL) => {
  return new ethers.providers.JsonRpcProvider(rpcURL);
};
/**
 * Returns ABI Interface Object
 * @param {*} abi
 * @param {*} method
 * @returns
 */
const _getABInterface = (abi, method) => {
  const currentMethod = abi[0];
  const newABI = [];

  newABI.push({
    id: currentMethod.id,
    inputs: currentMethod.inputs,
    outputs: currentMethod.outputs,
    stateMutability: currentMethod.stateMutability,
    type: currentMethod.type,
    name: currentMethod.name,
  });

  const ethInterface = new ethers.utils.Interface(newABI);
  return (
    ethInterface.fragments.filter((fragment) => {
      return fragment.name === method;
    })[0] || {}
  );
};

const _getSigner = ({ provider, account, privateKey }) => {
  if (privateKey) return new ethers.Wallet(privateKey, provider);
  return provider.getSigner(account);
};

const _isMethodInterface = (methodInterface) => {
  const type = get(methodInterface, 'type');
  return type === 'function';
};

const _parseUInt256 = (data) => {
  return data;
};

const _parseAddress = (data) => {
  return ethers.utils.getAddress(data);
};

const _parseBytes = (data = ethers.constants.HashZero) => {
  return ethers.utils.parseBytes32String(data);
};

const parseArguments = (type, value) => {
  if (type === 'uint256') {
    return _parseUInt256(value);
  }
  if (type === 'address') {
    return _parseAddress(value);
  }

  if (type === 'bytes') {
    if (!value) {
      return ethers.constants.HashZero;
    }
    return _parseBytes(value);
  }
  if (type === 'uint256[]') {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return value.map((v) => {
      return parseArguments('uint256', v);
    });
  }
  if (type === 'address[]') {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return value.map((v) => {
      return parseArguments('address', v);
    });
  }

  if (type === 'bytes[]') {
    if (!value) {
      return [];
    }
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return value.map((v) => {
      return parseArguments('bytes', v);
    });
  }
  return value;
};

const _getArguments = ({ methodInterface, values }) => {
  const inputs = get(methodInterface, 'inputs', []);
  return inputs.map((inp) => {
    const { name, type } = inp;
    return parseArguments(type, values[name]);
  });
};

const _getProvider = (account, network) => {
  if (account && window.ethereum) {
    return _getEthereumProvider(network);
  }
  if (network?.rpc) return _getJsonRpcProvider(network?.rpc);
  return _getDefaultProvider(network?.chainId);
};

const _isView = (stateMutability) => {
  return (
    stateMutability === STATE_MUTUABLITIY_LIST.VIEW ||
    stateMutability === STATE_MUTUABLITIY_LIST.PURE
  );
};

const ether = {
  /**
   *
   * @param { }
   * contractAddress, ABI: Array[], method: String, values, account
   * @returns
   */
  executeMethod({
    contractAddress,
    ABI,
    method,
    values,
    account,
    stateMutability,
    network,
    privateKey,
  }) {
    async function executeMethod(options, cb) {
      const { isView, provider, signer, methodArguments, gasLimit, nounce } =
        options;
      const currentMethod = ABI[0];

      const newABI = [];

      newABI.push({
        id: currentMethod.id,
        inputs: currentMethod.inputs,
        outputs: currentMethod.outputs,
        stateMutability: currentMethod.stateMutability,
        type: currentMethod.type,
        name: currentMethod.name,
      });
      try {
        const Contract = new ethers.Contract(
          contractAddress,
          newABI,
          isView ? provider : signer
        );
        const tx = await Contract[method](...methodArguments, {
          gasLimit: gasLimit || undefined,
          nounce: nounce || undefined,
        });
        cb(tx);
      } catch (e) {
        console.log(e);
        cb(e);
      }
    }
    return new Promise((res, rej) => {
      try {
        const provider = _getProvider(account, network);
        const isView = _isView(stateMutability);
        const signer = isView
          ? null
          : _getSigner({ provider, account, privateKey });
        const interfaceToExecute = _getABInterface(ABI, method);
        const isMethod = _isMethodInterface(interfaceToExecute);
        if (isMethod) {
          const methodArguments = _getArguments({
            methodInterface: interfaceToExecute,
            values,
          });
          executeMethod(
            { isView, provider, signer, methodArguments },
            (err, tx) => {
              if (err) res(err);
              res(tx);
            }
          );
        } else {
          rej({
            code: 'ERR_1',
            message: `The ${method} is not of type function in the ABI provided`,
          });
        }
      } catch (e) {
        console.log(e);
        res(e);
      }
    });
  },
};

export default ether;
