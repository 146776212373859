import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SelectField = ({
  value,
  onChange,
  options,
  label,
  isDisabledLabel,
  inputValue,
  onInputChange,
  disabled,
  isNoClosable,
  placeholder,
}) => {
  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        sx={{
          '& .MuiInputBase-input': {
            boxShadow: 'none',
          },
        }}
        size="small"
        placeholder={placeholder}
      />
    );
  };

  const setNoClosable = () => {
    return isNoClosable ? { clearIcon: null } : {};
  };

  return (
    <Box>
      {!isDisabledLabel && (
        <div className="text-sm mb-1 font-medium">{label}</div>
      )}
      <Autocomplete
        value={value}
        onChange={onChange}
        options={options}
        renderInput={renderInput}
        inputValue={inputValue}
        onInputChange={onInputChange}
        disabled={disabled}
        {...setNoClosable()}
      />
    </Box>
  );
};

export default SelectField;
