import { MetaMaskProvider } from 'metamask-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import './index.css';
import Migration from './services/boot/Migration';
import Telemetry from './services/boot/Telemetry';
import User from './services/boot/User';

if (process.env.NODE_ENV !== 'development') console.log = () => {};

const root = ReactDOM.createRoot(document.getElementById('root'));
User.boot(() => {
  Telemetry.boot(() => {
    Migration.boot(() => {
      root.render(
        <React.StrictMode>
          <MetaMaskProvider>
            <RecoilRoot>
              <BrowserRouter>
                <Toaster
                  toastOptions={{
                    style: {
                      wordBreak: 'break-all',
                    },
                  }}
                  position="top-center"
                  reverseOrder={true}
                />
                <App />
              </BrowserRouter>
            </RecoilRoot>
          </MetaMaskProvider>
        </React.StrictMode>
      );
    });
  });
});
