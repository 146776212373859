import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import RouterConstants from './constants/RouterConstants';
// layouts
import MainLayout from './layouts/MainLayout';

// Pages
import AppHome from './pages/AppHome';
import Docs from './pages/Docs';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  return useRoutes([
    {
      path: RouterConstants.APP.BASE,
      element: <MainLayout />,
      children: [
        {
          path: RouterConstants.APP.BASE,
          element: <AppHome />,
        },
        {
          path: `${RouterConstants.APP.BASE}/${RouterConstants.APP.DOCS}`,
          element: <Docs />,
        },
      ],
    },
    {
      path: '*',
      element: (
        <Navigate
          to={{
            pathname: RouterConstants.APP.BASE,
            search: location.search,
          }}
          replace
        />
      ),
    },
  ]);
}
