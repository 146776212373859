export const lightPrimary = {
  contrast: '#ffffff',
  dark: '#073492',
  light: '#72C1FE',
  main: '#1682FC',
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#073492',
  light: '#72C1FE',
  main: '#1682FC',
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#85372b',
  light: '#eb6d57',
  main: '#e6492d',
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#975b1e',
  light: '#e09b55',
  main: '#d9822b',
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0D468D',
  light: '#A9EEFD',
  main: '#29AAF4',
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#136A2B',
  light: '#C5F7B5',
  main: '#3CB73E',
};

export const lightBackground = {
  default: '#ffffff',
  paper: '#ffffff',
};

export const lightText = {
  primary: '#2d3748',
  secondary: '#4a5568',
};

export const lightNeutral = {
  100: '#f3f4f7',
  200: '#e1e3ea',
  300: '#d5d8e1',
  400: '#b7bdcc',
  500: '#9aa1b7',
  600: '#7c85a2',
  700: '#3f455a',
  800: '#2a2f3c',
  900: '#1e212a',
};

export const darkPrimary = {
  contrast: '#fff',
  dark: '#073492',
  light: '#72c1fe',
  main: '#1682fc',
};

export const darkSecondary = {
  contrast: '#fff',
  dark: '#073492',
  light: '#72c1fe',
  main: '#1682fc',
};

export const darkError = {
  contrast: '#000',
  dark: '#FFAB88',
  light: '#FFE9D7',
  main: '#F0805F',
};

export const darkWarning = {
  contrast: '#000',
  dark: '#8D682d',
  light: '#f7bb57',
  main: '#f6ab2e',
};

export const darkInfo = {
  contrast: '#000',
  dark: '#0D468D',
  light: '#A9EEFD',
  main: '#29AAF4',
};

export const darkSuccess = {
  contrast: '#000',
  dark: '#9AE98B',
  light: '#E4FBD9',
  main: '#C5F7B5',
};

export const darkBackground = {
  default: '#1A1A2E',
  paper: '#16213E',
};

export const darkText = {
  primary: '#f7fafc',
  secondary: '#e2e8f0',
};

export const darkNeutral = {
  100: '#1e212a',
  200: '#2b2f3c',
  300: '#40444f',
  400: '#545863',
  500: '#6c6f7f',
  600: '#868998',
  700: '#a1a4af',
  800: '#bdbfc7',
  900: '#d8dade',
};
