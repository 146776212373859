import { CheckCircle } from '@mui/icons-material';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { number, object, string } from 'yup';
import Input from '../../components/Input';
import { Modal } from '../../components/Modal';
import { getCustomChains, saveChain } from '../../services/ChainService';
import { uuidv4 } from '../../utils/UUIDV4';
import NetworkSelector from '../NetworkSelector';

const AddOrEditChainModal = ({
  isOpen,
  onClose,
  onSubmit,
  editedChainData,
  chainListLength = 0,
}) => {
  const [networkSelectorData, setNetworkSelectorData] = useState({
    selectedChain: null,
    chainName: '',
    chainId: '',
    rpc: '',
    isCustomAdd: false,
    network: null,
    type: null,
  });
  const {
    selectedChain,
    chainName,
    chainId,
    rpc,
    warningMsg,
    isCustomAdd,
    network,
    type,
  } = networkSelectorData;

  const [name, setName] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [isActive, setIsActive] = useState(false);

  const customChains = getCustomChains();

  function resetNetworkState() {
    setNetworkSelectorData({
      selectedChain: null,
      chainName: '',
      chainId: '',
      rpc: '',
      network: null,
      type: null,
    });
  }

  function handleSubmit() {
    const selectedChainId =
      get(selectedChain, 'chainId') ?? parseInt(chainId, 10);
    const selectedRPC = get(selectedChain, 'rpc.0') || rpc;
    const selectedChainName = get(selectedChain, 'name') || chainName;
    const selectedType = get(selectedChain, 'type') || type;
    const selectedNetworkStatus = get(selectedChain, 'network') || network;

    const getTypeBySchema = () => {
      if (selectedType?.id === 'near') {
        return {
          network: string().required("Network should'nt be empty"),
        };
      }
      return {
        chainId: number().nullable().required("Chain should'nt be empty"),
      };
    };

    const schema = object().shape({
      name: string().required("Contract Name should'nt be empty"),
      address: string().required("Contract Address should'nt be empty"),
      rpc: string().required("RPC URL should'nt be empty"),
      ...getTypeBySchema(),
      chainName: string().required("Chain name should'nt be empty"),
    });

    const getTypeByDefaultPayload = () => {
      if (selectedType?.id === 'near') {
        return {
          network: selectedNetworkStatus?.id,
        };
      }
      return {
        chainId: selectedChainId || null,
      };
    };

    const defaultPayload = {
      address: contractAddress,
      name,
      rpc: selectedRPC,
      ...getTypeByDefaultPayload(),
      chainName: selectedChainName,
    };

    const toastId = toast.loading('Loading...');
    schema
      .validate(defaultPayload)
      .then(() => {
        onSubmit({
          id: editedChainData?.id || uuidv4(),
          name,
          address: contractAddress,
          network: {
            chainId: selectedChainId,
            rpc: selectedRPC,
            chainName: selectedChainName,
            type: selectedType,
            network: selectedNetworkStatus,
          },
          isActive,
        });
        setName('');
        setContractAddress('');
        setIsActive(false);
        resetNetworkState();
        setIsActive(false);
        toast.success(
          `${!isEmpty(editedChainData) ? 'Updated' : 'Added'} Chain`,
          {
            id: toastId,
          }
        );
        if (!warningMsg && isCustomAdd) {
          saveChain({
            chainId,
            rpc,
            name: selectedChainName,
            type: selectedType,
            network: selectedNetworkStatus,
            id: uuidv4(),
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message, {
          id: toastId,
        });
      });
  }

  useEffect(() => {
    const { address, network } = editedChainData || {};
    setName(editedChainData?.name);
    setContractAddress(address);
    setIsActive(editedChainData?.isActive ?? false);
    const formatNetwork = {
      name: network?.chainName,
      chainId: network?.chainId,
      rpc: [network?.rpc],
      type: network?.type,
      network: network?.network,
    };
    let selectedChain = formatNetwork;
    if (isEmpty(editedChainData)) {
      selectedChain = network;
    }
    const isCustomChain = some(customChains, (_chain) => {
      return get(_chain, 'name') === get(selectedChain, 'name');
    });

    setNetworkSelectorData((prev) => ({
      ...prev,
      selectedChain: isCustomChain ? null : selectedChain,
      chainName: isCustomChain ? get(selectedChain, 'name') : '',
      chainId: isCustomChain ? get(selectedChain, 'chainId') : '',
      rpc: isCustomChain ? get(selectedChain, 'rpc.0') : '',
      isCustomAdd: isCustomChain,
      network: isCustomChain ? get(selectedChain, 'network') : null,
      type: isCustomChain ? get(selectedChain, 'type') : null,
    }));
  }, [editedChainData]);

  useEffect(() => {
    if (chainListLength === 0) {
      setIsActive(true);
    }
  }, []);

  return (
    <div>
      <Modal
        open={isOpen}
        handleClose={onClose}
        submit={handleSubmit}
        primaryBtnText="Submit"
        secondaryBtnText="Cancel"
        maxWidth="md">
        <div className="flex flex-col mx-auto">
          <div className="w-36 text-xl mb-4 font-bold">
            {isEmpty(editedChainData) ? 'Add Contract' : 'Edit Contract'}
          </div>
          <div className="flex">
            <Input
              label="Contract Name"
              placeholder="Enter Contract Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              name="name"
              style={{ marginRight: 10 }}
            />

            <Input
              label="Contract Address"
              placeholder="Contract Address"
              onChange={(e) => setContractAddress(e.target.value)}
              value={contractAddress}
              name="contract-address"
            />
          </div>

          <NetworkSelector
            networkSelectorData={networkSelectorData}
            setNetworkSelectorData={setNetworkSelectorData}
          />

          <FormGroup className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  icon={<PanoramaFishEyeIcon />}
                  checkedIcon={<CheckCircle />}
                  onClick={() => setIsActive(!isActive)}
                  checked={isActive}
                  disabled={
                    (!isEmpty(editedChainData) && chainListLength <= 1) ||
                    chainListLength === 0
                  }
                />
              }
              label="Mark as Active"
            />
          </FormGroup>
        </div>
      </Modal>
    </div>
  );
};
export default AddOrEditChainModal;
