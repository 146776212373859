import { Tooltip } from '@mui/material';

const TabSelector = ({
  isActive,
  children,
  onClick,
  onClose,
  index,
  totalCount,
  isNewTab,
  badge,
}) => {
  return (
    <li
      className={`flex space-x-2 w-full items-center justify-center px-2 ${
        isActive &&
        'bg-primary font-medium rounded-t-lg border-t border-r border-l -mb-px border-secondary'
      }`}>
      <p
        className={`text-sm border-transparent text-center cursor-default w-full ${
          isActive ? 'text-accent' : 'text-primary'
        } cursor-pointer`}
        onClick={onClick}>
        {children}
      </p>
      {badge} &nbsp; &nbsp;
      {index === 0 && isNewTab && totalCount === 1 ? null : (
        <Tooltip title="Close tab">
          <div
            onClick={onClose}
            className="rounded-full cursor-pointer text-sm text-primary focus:outline-none focus:shadow-outline hover:bg-secondary hover:text-primary transition duration-300 ease-in-out">
            <svg
              className="w-3 h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
        </Tooltip>
      )}
    </li>
  );
};

export default TabSelector;
