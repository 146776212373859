import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import get from 'lodash/get';
import map from 'lodash/map';
import { useRecoilState, useRecoilValue } from 'recoil';
import TextOverflowTruncate from '../../../../components/TextOverflowTruncate';
import { CollectionListState } from '../../../../states/CollectionState';
import { MobileDrawerOpenState } from '../../../../states/ResponsiveViewState';
import ABIStateMutuability from './ABIStateMutuability';
import getClone from '../../../../utils/getClone';
import { uuidv4 } from '../../../../utils/UUIDV4';
import CollectionService from '../../../../services/CollectionService';
import TelemetryService from '../../../../services/TelemetryService';
import { TelemetryEvents } from '../../../../constants/EventsConstants';
import DeleteConfirmationModal from '../../../modals/DeleteConfirmationModal';
import TabService from '../../../../services/TabService';
import IconWrapper from '../../../../components/IconWrapper';
import useTabs from '../../../../hooks/useTabs';

const MethodListItem = ({ stateMutability, _abi, _c }) => {
  const [isHover, setIsHover] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );

  // store ABI list in recoil state
  const collectionList = useRecoilValue(CollectionListState);

  const { removeTabByMethodId, selectedTab, setSelectedTab } = useTabs();

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  function handleSelectABI(collection, abi) {
    setSelectedTab(`${get(collection, 'id')}$${get(abi, 'id')}`);
  }

  /**
   * clone method
   * @param {*} e
   */
  const triggerMethodClone = (e) => {
    e.stopPropagation?.();
    const fullCollections = getClone(collectionList);
    const selectedMethod = getClone(_abi);
    const selectedCollection = getClone(_c);
    selectedMethod.id = uuidv4();
    selectedMethod.name = `${selectedMethod.name} - copy`;
    selectedCollection?.abi?.push(selectedMethod);

    const changedCollections = map(fullCollections, (list) => {
      if (get(list, 'id') === get(selectedCollection, 'id')) {
        return selectedCollection;
      }
      return list;
    });
    CollectionService.replaceCollections(changedCollections);
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_METHOD_CLONE);
  };

  /**
   * show confirm message for delate method
   * @param {*} e
   */
  const showDeleteConfirmMsg = (e) => {
    e?.stopPropagation?.();
    setModalOpen(true);
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_METHOD_DELETE);
  };

  /**
   * delete Method
   * @param {*} e
   */
  const triggerDeleteMethod = (e) => {
    setModalOpen(false);
    const fullCollections = getClone(collectionList);
    const selectedCollection = getClone(_c);
    const abiId = get(_abi, 'id');

    const filterMethods = get(selectedCollection, 'abi')?.filter?.((list) => {
      return get(list, 'id') !== abiId;
    });
    const changedCollections = map(fullCollections, (list) => {
      if (get(list, 'id') === get(selectedCollection, 'id')) {
        return { ...selectedCollection, abi: filterMethods };
      }
      return list;
    });
    removeTabByMethodId(abiId);
    TelemetryService.sendEvent(TelemetryEvents.METHOD_DELETE_SUCCESS);
    setTimeout(() => {
      CollectionService.replaceCollections(changedCollections);
    }, 300);
  };

  const handleMethodClick = () => {
    if (!isSelectedMethod) {
      handleDrawerToggle();
      handleSelectABI(_c, _abi);
    }
  };

  const isSelectedMethod =
    TabService.getCollectionMethodId(selectedTab) === get(_abi, 'id');

  const handleClose = () => {
    setModalOpen(false);
    TelemetryService.sendEvent(TelemetryEvents.METHOD_DELETE_CANCEL);
  };

  return (
    <div>
      <ListItemButton
        selected={isSelectedMethod}
        className="!pl-3 !h-7"
        onClick={handleMethodClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <div className="w-[90%]">
          <ListItemText
            primary={
              <div className="flex p-1 items-center">
                <ABIStateMutuability stateMutability={stateMutability} />
                <div className="flex text-sm ml-2 overflow-hidden ">
                  <TextOverflowTruncate name={get(_abi, 'name')} />
                </div>
              </div>
            }
          />
        </div>
        {isHover && isSelectedMethod && (
          <>
            <Tooltip title="Clone">
              <IconWrapper>
                <FileCopyIcon
                  onClick={triggerMethodClone}
                  sx={{
                    mr: 1.2,
                    '&:hover': {
                      opacity: '0.7',
                    },
                  }}
                  className="!w-full !h-full items-center my-1.5"
                />
              </IconWrapper>
            </Tooltip>

            <IconWrapper>
              <Tooltip title="Delete" onClick={showDeleteConfirmMsg}>
                <DeleteIcon
                  sx={{
                    '&:hover': {
                      color: 'error.main',
                      opacity: '0.7',
                    },
                  }}
                  className="!w-full !h-full my-1.5"
                />
              </Tooltip>
            </IconWrapper>
          </>
        )}
      </ListItemButton>
      <DeleteConfirmationModal
        title={get(_abi, 'name')}
        isOpen={modalOpen}
        onClose={handleClose}
        onSubmit={triggerDeleteMethod}
      />
    </div>
  );
};

export default MethodListItem;
