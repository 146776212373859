import isEmpty from 'lodash/isEmpty';
import StorageConstants, {
  getStorageConstants,
} from '../constants/StorageConstants';
import GlobalStorageService from './GlobalStorageService';

export default {
  flattenObject(eventData) {
    return eventData.map((item) => {
      const newObj = {};
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'object' && !isEmpty(item[key])) {
          Object.keys(item[key]).forEach((innerKey) => {
            newObj[`${key}_${innerKey}`] = item[key][innerKey];
          });
        } else {
          newObj[key] = item[key];
        }
      });

      return newObj;
    });
  },

  sendEvent(eventName, eventData) {
    if (isEmpty(eventName)) return;

    let flattenEventData = {};
    if (eventData !== undefined) {
      flattenEventData = this.flattenObject([eventData]);
    }
    if (Array.isArray(flattenEventData)) {
      flattenEventData = flattenEventData['0'];
    }
    flattenEventData = {
      ...flattenEventData,
      // app_client: PlatformService.getClientName(),
      // app_client_version: PlatformService.getClientVersion(),
      // app_platform: PlatformService.getPlatform(),
      // pwa_version: PlatformService.getSemverVersion(),
    };
    const Telemetry = GlobalStorageService.getItem(
      getStorageConstants(StorageConstants.TELEMETRY)
    );
    console.log('eventName, eventData: ', eventName, flattenEventData);
    Telemetry?.sendEvent(eventName, flattenEventData);
  },
  setUser() {
    const Telemetry = GlobalStorageService.getItem(
      getStorageConstants(StorageConstants.TELEMETRY)
    );
    Telemetry?.setUser();
  },
};
