import { useEffect } from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CurrentExpandedCollectionId } from '../states/CollectionState';
import { TabsState, ActiveTabState } from '../states/TabStates';
import TabService from '../services/TabService';
import getClone from '../utils/getClone';
import { sendToUpdateActiveTab } from '../services/EventService';

/**
 * control tabs
 * @returns {object}
 */
const useTabs = () => {
  const [tabs, setTabs] = useRecoilState(TabsState);
  const activeTab = useRecoilValue(ActiveTabState);
  const setExpandedId = useSetRecoilState(CurrentExpandedCollectionId);

  /**
   * set tab active
   * @param {string} tab id
   * @returns
   */
  const setSelectedTab = (tab) => {
    // check whether already exist tab or not
    const isAlreadyExists = !!find(tabs, (_t) => _t === tab);

    if (isAlreadyExists) {
      // set tab active from exist tabs
      sendToUpdateActiveTab(tab);
      setExpandedId(TabService.getCollectionId(tab));
    } else {
      // set tab active as new tab and insert into tabs list
      const findNewTab = find(tabs, (_t) => {
        return (
          TabService.isNewTab(tab) &&
          TabService.isNewTab(_t) === TabService.isNewTab(tab)
        );
      });
      if (findNewTab) {
        sendToUpdateActiveTab(findNewTab);
        return;
      }
      const cloneTabs = getClone(tabs);
      setTabs([...cloneTabs, tab]);
      sendToUpdateActiveTab(tab);
    }
  };

  /**
   * remove tab by ABI id from tab list
   * @param {string} ABI id
   */
  const removeTabByAbiId = (id) => {
    const filterTabs = filter(
      tabs,
      (_t) => TabService.getCollectionId(_t) !== id
    );
    setTabs(filterTabs);
  };

  /**
   * remove tab by method id from tab list
   * @param {string} method id
   */
  const removeTabByMethodId = (id) => {
    const filterTabs = filter(
      tabs,
      (_t) => TabService.getCollectionMethodId(_t) !== id
    );
    setTabs(filterTabs);
  };

  /**
   * remove tabs by method ids from tab list
   * @param {array} tab ids
   */
  const removeTabsByMethodIds = (ids) => {
    const filterTabs = filter(
      tabs,
      (_t) => !ids?.includes(TabService.getCollectionMethodId(_t))
    );
    setTabs(filterTabs);
  };

  /**
   * remove particular tab id from tab list
   * @param {string} tab id
   */
  const removeTab = (tab) => {
    const filterTabs = filter(tabs, (_t) => _t !== tab);
    setTabs(filterTabs);
  };

  /**
   *  remove all tabs from tabs list
   */
  const removeAllTabs = () => {
    setTabs([]);
  };

  /**
   *  get all tabs from tabs list
   */
  const getAllTabs = () => {
    return tabs;
  };

  /**
   * remove all tabs expect given tab id from tabs list
   * @param {string} tab id
   */
  const removeOtherTabs = (tab) => {
    setTabs([tab]);
  };

  /**
   * remove tab by method id
   * @param {string} id
   * @param {string} replaceTab
   */
  const replaceTabByMethodId = (id, replaceTab) => {
    const formatTab = map(tabs, (_t) => {
      if (TabService.getCollectionMethodId(_t) === id) {
        return replaceTab;
      }
      return _t;
    });
    setTabs(formatTab);
    sendToUpdateActiveTab(replaceTab);
  };

  useEffect(() => {
    // add first tab as active tab
    if (!activeTab) {
      sendToUpdateActiveTab(tabs?.[0]);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(tabs)) {
      // set initial state (tab list and active tab)
      setTabs([TabService.generateCreateAbiTabName()]);
      sendToUpdateActiveTab(tabs?.[0]);
    }
  }, [tabs]);

  return {
    selectedTab: activeTab,
    setSelectedTab,
    removeTab,
    removeTabByAbiId,
    removeTabByMethodId,
    removeTabsByMethodIds,
    replaceTabByMethodId,
    removeAllTabs,
    getAllTabs,
    removeOtherTabs,
  };
};

export default useTabs;
