import { Add } from '@mui/icons-material';
import { Alert, Button, Link, Tooltip } from '@mui/material';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import Input from '../components/Input';
import SelectField from '../components/SelectField';
import { getAllChains } from '../services/ChainService';
import ChainSelector from './ChainSelector';

const NetworkSelector = ({
  networkSelectorData,
  setNetworkSelectorData,
  isImportChainExplorer,
}) => {
  const {
    selectedChain,
    chainName,
    chainId,
    rpc,
    isCustomAdd,
    warningMsg,
    type,
    network,
  } = networkSelectorData;

  function handleChainSelection(_, value) {
    const findChainData = find(getAllChains(), (list) => {
      return get(list, 'id') === value?.id;
    });
    setNetworkSelectorData({
      selectedChain: findChainData,
      chainName: '',
      chainId: '',
      rpc: '',
      isCustomAdd: false,
      network: null,
      type: {
        label: 'Ethereum',
        id: 'ethereum',
      },
    });
  }

  useEffect(() => {
    if (isCustomAdd) {
      const alreadyExistsChainName = find(getAllChains(), (_chain) => {
        return get(_chain, 'name') === chainName;
      });
      if (alreadyExistsChainName) {
        setNetworkSelectorData((prev) => ({
          ...prev,
          warningMsg:
            'The Chain Name is already in use and cannot be stored again.',
        }));
      } else {
        setNetworkSelectorData((prev) => ({
          ...prev,
          warningMsg: '',
        }));
      }
    }
    if (type?.id === 'ethereum') {
      setNetworkSelectorData((prev) => ({
        ...prev,
        network: null,
      }));
    } else if (type?.id === 'near') {
      setNetworkSelectorData((prev) => ({
        ...prev,
        chainId: '',
      }));
    }
  }, [isCustomAdd, type, chainName]);

  return (
    <div>
      <div className="font-semibold">Add a Network</div>
      <div className="mb-2 text-secondary text-sm">
        {isImportChainExplorer
          ? 'Add from a list of Popular Networks'
          : 'Add from a list of Popular Networks or add a network manually'}
      </div>
      <div className="flex w-full">
        <div className="flex-1">
          <ChainSelector
            name="chain-name"
            placeholder="Type to Search. Eg: Eth"
            onChange={handleChainSelection}
            value={get(selectedChain, 'name')}
            label="Select Network"
            options={getAllChains(isImportChainExplorer)}
            isClearable
          />
        </div>
        <div className="flex flex-1 ml-4">
          <div
            className={`flex-col w-full ${
              !isEmpty(selectedChain) ? 'flex' : 'invisible'
            }`}>
            <div className="flex w-full">
              <div className="flex flex-col mr-6">
                <div className="text-sm font-semibold">Chain Name</div>
                <div>{get(selectedChain, 'name', 'Chain Name')}</div>
              </div>
              <div className="flex flex-col mr-6">
                <div className="text-sm font-semibold">Type</div>
                <div>{get(selectedChain, 'type.id', 'ethereum')}</div>
              </div>
              {get(selectedChain, 'type.id') === 'near' ? (
                <div className="flex flex-col">
                  <div className="text-sm font-semibold">Network</div>
                  <div>{get(selectedChain, 'network.id', '-')}</div>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="text-sm font-semibold">Chain ID</div>
                  <div>{get(selectedChain, 'chainId', 0)}</div>
                </div>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <div className="text-sm font-semibold">RPC URL</div>
              {get(selectedChain, 'rpc.0', 'rpc-url')}
            </div>
          </div>
        </div>
      </div>
      {!isCustomAdd && !isImportChainExplorer && (
        <div className="flex">
          <Tooltip title="Add New Custom Network">
            <Button
              size="small"
              variant="outlined"
              startIcon={<Add />}
              onClick={() => {
                setNetworkSelectorData((prev) => ({
                  ...prev,
                  isCustomAdd: true,
                  type: {
                    label: 'Ethereum',
                    id: 'ethereum',
                  },
                }));
              }}
              disabled={
                !isEmpty(get(selectedChain, 'name')) || isImportChainExplorer
              }>
              Add Network Manually
            </Button>
          </Tooltip>
        </div>
      )}

      {isCustomAdd && isEmpty(get(selectedChain, 'name')) && (
        <div className="flex flex-col mt-3 border rounded-md p-4 shadow-sm">
          <div className="flex space-x-5">
            <div className="w-1/2">
              <Input
                label="Chain Name"
                placeholder="Enter Chain Name"
                onChange={(e) => {
                  setNetworkSelectorData((prev) => ({
                    ...prev,
                    chainName: e.target.value,
                  }));
                }}
                value={chainName}
                name="chain-name"
              />
            </div>
            <div className="w-1/2">
              <SelectField
                label="Type"
                value={type}
                onChange={(e, newInputValue) => {
                  setNetworkSelectorData((prev) => ({
                    ...prev,
                    type: newInputValue,
                  }));
                }}
                options={[
                  {
                    label: 'Ethereum',
                    id: 'ethereum',
                  },
                  {
                    label: 'Near',
                    id: 'near',
                  },
                ]}
                isNoClosable
              />
            </div>
          </div>
          <div className="flex space-x-5">
            <div className={`w-1/2 ${type?.id === 'near' ? 'opacity-50' : ''}`}>
              <Input
                label="Chain ID"
                placeholder="Enter Chain ID"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setNetworkSelectorData((prev) => ({
                      ...prev,
                      chainId: Number(e.target.value),
                    }));
                  }
                }}
                value={chainId}
                name="chain-id"
                disabled={type?.id === 'near'}
              />
            </div>
            <div
              className={`w-1/2 ${
                type?.id === 'ethereum' ? 'opacity-50' : ''
              }`}>
              <SelectField
                label="Network"
                value={network}
                onChange={(e, newInputValue) => {
                  setNetworkSelectorData((prev) => ({
                    ...prev,
                    network: newInputValue,
                  }));
                }}
                options={[
                  {
                    label: 'Mainnet',
                    id: 'mainnet',
                  },
                  {
                    label: 'Testnet',
                    id: 'testnet',
                  },
                ]}
                // isDisabledLabel
                disabled={type?.id === 'ethereum'}
                placeholder="Select Network"
              />
            </div>
          </div>
          <Input
            label="RPC URL"
            placeholder="Enter RPC URL"
            onChange={(e) => {
              setNetworkSelectorData((prev) => ({
                ...prev,
                rpc: e.target.value,
              }));
            }}
            value={rpc}
            name="chain-rpc"
          />
          <div className="flex justify-between items-center">
            <Button
              size="small"
              onClick={() => {
                setNetworkSelectorData((prev) => ({
                  ...prev,
                  chainName: '',
                  chainId: '',
                  rpc: '',
                  isCustomAdd: false,
                  network: null,
                  type: {
                    label: 'Ethereum',
                    id: 'ethereum',
                  },
                }));
              }}>
              Cancel
            </Button>
            <div className="flex item-center">
              <Link
                href="https://chainlist.org/"
                target="_blank"
                underline="hover">
                Chain List
              </Link>
            </div>
          </div>
        </div>
      )}
      {warningMsg && isCustomAdd && !isImportChainExplorer && (
        <Alert
          className={`mt-3 ${
            warningMsg && isCustomAdd ? 'visible' : 'invisible'
          }`}
          severity="warning">
          {warningMsg}
        </Alert>
      )}
    </div>
  );
};

export default NetworkSelector;
