import { Events } from '@poriyaalar/js-event';
import EventsConstants from '../constants/EventsConstants';

const EventsEmitter = new Events();

// Collection Update
//----------------------------------------------------------------
export const subscribeToCollectionUpdate = (cb) => {
  return EventsEmitter.subscribe(EventsConstants.COLLECTION_UPDATE, (value) => {
    cb && cb(value);
  });
};
export const sendCollectionUpdate = (options) => {
  return EventsEmitter.emit(EventsConstants.COLLECTION_UPDATE, options);
};
//----------------------------------------------------------------

// History Update
//----------------------------------------------------------------
export const subscribeToHistoryUpdate = (cb) => {
  return EventsEmitter.subscribe(
    EventsConstants.HISTORY_COLLECTION_METHOD_UPDATE,
    (value) => {
      cb && cb(value);
    }
  );
};
export const sendHistoryUpdate = () => {
  return EventsEmitter.emit(EventsConstants.HISTORY_COLLECTION_METHOD_UPDATE);
};
//----------------------------------------------------------------

// Update Active Tab
//----------------------------------------------------------------
export const subscribeToUpdateActiveTab = (cb) => {
  return EventsEmitter.subscribe(EventsConstants.UPDATE_ACTIVE_TAB, (value) => {
    cb && cb(value);
  });
};
export const sendToUpdateActiveTab = (value) => {
  return EventsEmitter.emit(EventsConstants.UPDATE_ACTIVE_TAB, value);
};
//----------------------------------------------------------------

// update metamask chain
//----------------------------------------------------------------
export const subscribeToUpdateMetamaskChain = (cb) => {
  return EventsEmitter.subscribe(
    EventsConstants.UPDATE_METAMASK_CHAIN,
    (value) => {
      cb && cb(value);
    }
  );
};
export const sendToUpdateMetamaskChain = (value) => {
  return EventsEmitter.emit(EventsConstants.UPDATE_METAMASK_CHAIN, value);
};
//----------------------------------------------------------------

// update near wallet chain
//----------------------------------------------------------------
export const subscribeToNearAuthState = (cb) => {
  return EventsEmitter.subscribe(
    EventsConstants.NEAR_WALLET_AUTH_STATE,
    (value) => {
      cb && cb(value);
    }
  );
};
export const sendNearWalletAuthChange = (value) => {
  return EventsEmitter.emit(EventsConstants.NEAR_WALLET_AUTH_STATE, value);
};
//----------------------------------------------------------------
