import { CssBaseline, ThemeProvider } from '@mui/material';
import '@near-wallet-selector/modal-ui/styles.css';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { TelemetryEvents } from './constants/EventsConstants';
import Router from './routes';
import { fetchChain } from './services/ChainService';
import TelemetryService from './services/TelemetryService';
import { AppSettings } from './states/SettingsState';
import { createCustomTheme } from './theme';

let initialRun = true;

const App = () => {
  const [settings] = useRecoilState(AppSettings);
  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });
  TelemetryService.setUser();
  useEffect(() => {
    // * Enable this to avoid right click.
    // document.addEventListener("contextmenu", (e) => {
    //   e.preventDefault();
    // });
    fetchChain();
  }, []);

  useEffect(() => {
    if (initialRun) {
      TelemetryService.sendEvent(TelemetryEvents.APP_OPENED);
      initialRun = false;
    }
  }, []);

  return (
    <ThemeProvider theme={theme} className="overflow-hidden">
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

export default App;
