import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupHereWallet } from '@near-wallet-selector/here-wallet';
import { setupMeteorWallet } from '@near-wallet-selector/meteor-wallet';
import { setupModal } from '@near-wallet-selector/modal-ui-js';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupNearFi } from '@near-wallet-selector/nearfi';
import { setupSender } from '@near-wallet-selector/sender';
import { distinctUntilChanged, map } from 'rxjs';
import { sendNearWalletAuthChange } from '../../services/EventService';

export default {
  broadcastAuthStateChange(event) {
    sendNearWalletAuthChange(event);
  },
  async setupSelectorForNetWorkIfNotAvailable(network) {
    if (!window.nearSelectors) {
      window.nearSelectors = {};
    }
    if (!window.nearSelectors[network]) {
      window.nearSelectors[network] = {};
      const _selector = await setupWalletSelector({
        network,
        debug: true,
        allowMultipleSelectors: true,
        modules: [
          setupMeteorWallet(),
          setupMyNearWallet(),
          setupSender(),
          setupHereWallet(),
          setupNearFi(),
        ],
      });
      window.nearSelectors[network].selector = _selector;
      const subscription = _selector.store.observable
        .pipe(
          map((state) => state.accounts),
          distinctUntilChanged()
        )
        .subscribe((nextAccounts) => {
          this.broadcastAuthStateChange({ network, nextAccounts });
          console.log('Accounts Update for ', network, nextAccounts);
        });
      window.nearSelectors[network].subscription = subscription;
    }
  },

  async login(network) {
    if (!network) {
      throw new Error(
        'Network information is unavailable to open near wallet selector'
      );
    }
    await this.setupSelectorForNetWorkIfNotAvailable(network);
    if (!this.isLoggedIntoNetwork(network)) {
      const _modal = setupModal(window.nearSelectors[network].selector, {
        contractId: '',
      });
      _modal.show();
    }
  },

  isLoggedIntoNetwork(network) {
    if (!window.nearSelectors) {
      return false;
    }
    if (!window.nearSelectors[network]) {
      return false;
    }

    if (!window.nearSelectors[network].selector) {
      return false;
    }

    if (!window.nearSelectors[network].selector) {
      return false;
    }

    return window.nearSelectors[network].selector.isSignedIn();
  },

  async logOutFromNetwork(network) {
    if (this.isLoggedIntoNetwork(network)) {
      const wallet = await window.nearSelectors[network].selector.wallet();
      wallet.signOut();
    }
    return Promise.resolve();
  },

  async getWalletForNetwork(network) {
    await this.login(network);
    return window.nearSelectors[network].selector.wallet();
  },

  async signInForContract(network, contract) {
    await this.login(network);
    const wallet = await this.getWalletForNetwork(network);
    wallet.signIn({ contract });
  },

  isNetWorkChanged(expectedNetWork) {
    if (window.selector) {
      return window.selector.options.network.networkId !== expectedNetWork;
    }
    return true;
  },
};
