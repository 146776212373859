import ObjectStorage from './ObjectStorageService';
import StorageConstants from '../constants/StorageConstants';
import { TABS } from '../constants/AppConstants';
import { uuidv4 } from '../utils/UUIDV4';

export const setTabsDetails = (TabsDetailsPayload) => {
  ObjectStorage.setItem(StorageConstants.TAB_DETAILS, TabsDetailsPayload);
};

export const getTabsDetails = () => {
  const TabsDetails = ObjectStorage.getItem(StorageConstants.TAB_DETAILS);
  return TabsDetails;
};
export default {
  /**
   * get ABI id from tab id
   * @param {string} tab id
   * @returns ABI id
   */
  getCollectionId(tab) {
    return tab?.split('$')?.[0];
  },
  /**
   * get method id from tab id
   * @param {string} tab id
   * @returns method id
   */
  getCollectionMethodId(tab) {
    return tab?.split('$')?.[1];
  },
  /**
   * check whether untitled tab or not
   * @param {string} tab id
   * @returns {boolean}
   */
  isUntitledTab(tab) {
    return tab?.split('$')?.[2] === TABS.UNTITLED_TAB;
  },
  /**
   * check whether create ABI tab or not
   * @param {string} tab id
   * @returns {boolean}
   */
  isNewTab(tab) {
    return tab?.split('$')?.[2] === TABS.CREATE_ABI_TAB;
  },
  /**
   * check whether history tab or not
   * @param {string} tab id
   * @returns {boolean}
   */
  isHistoryTab(tab) {
    return tab?.split('$')?.[2] === TABS.HISTORY_METHOD;
  },
  // TODO: (ABI-id $ ABI-method-id $ (TABS.CREATE_ABI_TAB or TABS.HISTORY_METHOD or TABS.UNTITLED_TAB))
  //----------------------------------------------------------------
  /**
   * generate Create Abi Tab Name
   * @returns {string} Create Abi Tab Name
   */
  generateCreateAbiTabName() {
    return `undefined$undefined$${TABS.CREATE_ABI_TAB}`;
  },
  /**
   * generate history Tab Name
   * @returns {string} history Tab Name
   */
  generateHistoryAbiMethodTabName(abiMethodId) {
    return `undefined$${abiMethodId}$${TABS.HISTORY_METHOD}`;
  },
  /**
   * generate untitled Tab Name
   * @returns {string} untitled Tab Name
   */
  generateUntitledTabName() {
    return `undefined$${uuidv4()}$${TABS.UNTITLED_TAB}`;
  },
  //----------------------------------------------------------------
};
