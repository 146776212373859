const Messages = {
  INFO: {
    COPY_CLIPBOARD: 'Copied to Clipboard',
    SAVED_METHOD: 'Saved Method',
    UNSAVED_CHANGES_WILL_BE_LOST_CONTINUE:
      'Unsaved changes will be lost. Continue?',
    SELECT_ABI: 'select ABI',
    METHOD_NAME_IS_REQUIRED: 'Method name is required',
    STATE_MUTABILITY: 'State Mutability is required',
    ADD_CONTRACT: 'Add Contract',
  },
};
export default Messages;
