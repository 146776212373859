import get from 'lodash/get';
import API from '../APIService';

const APIURL = process.env.REACT_APP_CLIENT_API_URL;

export default {
  getContractMethods(accountId, finality = 'final') {
    return new Promise((resolve, reject) => {
      const url = `${APIURL}/api/v1/contract/details`;
      API.fetch(
        url,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            contractId: accountId,
            final: finality,
          }),
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(get(data, 'data'));
        }
      );
    });
  },
};
