import { atom } from 'recoil';
import types from '../constants/Types';

// All ABIs List
export const CollectionListState = atom({
  key: types.COLLECTION.COLLECTION_LIST_STATE,
  default: [],
});

// Current open accordion id from ABIs list
export const CurrentExpandedCollectionId = atom({
  key: types.COLLECTION.CURRENT_EXPANDED_COLLECTION_ID,
  default: '',
});
