import forEach from 'lodash/forEach';
import StorageConstants from '../../constants/StorageConstants';
import ABIVersionService from '../ABIVersionService';
import CollectionService from '../CollectionService';
import ObjectStorage from '../ObjectStorageService';

export default {
  boot(cb) {
    const abiList = CollectionService.getCollection();
    const data = [];
    forEach(abiList, (abi) => {
      data.push(ABIVersionService.migrateABI(abi));
    });
    ObjectStorage.setItem(StorageConstants.COLLECTIONS, { data });
    cb && cb();
  },
};
