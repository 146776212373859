const EventsConstants = {
  COLLECTION_UPDATE: 'collection-update',
  HISTORY_COLLECTION_METHOD_UPDATE: 'history-collection-method-update',
  EXECUTE_ABI: 'execute-abi',
  UPDATE_ACTIVE_TAB: 'update-active-tab',
  UPDATE_METAMASK_CHAIN: 'update-metamask-chain',
  NEAR_WALLET_AUTH_STATE: 'near-wallet-auth-state',
};
export const TelemetryEvents = {
  ON_CLICK_NEW_ABI: 'on_click_new_abi',
  SIDE_BAR_SHARE_CLICK: 'share_abi_click',
  SHARE_ABI_COPY: 'share_abi_copy',
  SHARE_DOCS_COPY: 'share_docs_copy',
  ABI_CREATION_SUCCESS: 'on_click_new_abi_success',
  ON_CLICK_IMPORT_BACK: 'on_click_import_back',
  ON_CLICK_CLONE: 'on_click_clone',
  ON_CLICK_NEW_METHOD: 'on_click_new_method',
  ON_CLICK_RENAME: 'on_click_rename',
  ON_DBL_CLICK_RENAME: 'on_dblclick_rename',
  ON_CLICK_EXPORT: 'on_click_export',
  ON_CLICK_DELETE: 'on_click_delete',
  DELETE_SUCCESS: 'delete_success',
  DELETE_CANCEL: 'delete_cancel',
  ON_CLICK_METHOD_CLONE: 'on_click_method_clone',
  ON_CLICK_METHOD_DELETE: 'on_click_method_delete',
  METHOD_DELETE_SUCCESS: 'method_delete_success',
  METHOD_DELETE_CANCEL: 'method_delete_cancel',
  SHARE_ABI_CLICK: 'share_abi_click',
  ON_CLICK_SEND: 'on_click_send',
  ON_CLICK_SAVE: 'on_click_save',
  ON_CLICK_SAVE_AS: 'on_click_save_as',
  ON_CLICK_CONNECT_WALLET: 'on_click_connect_wallet',
  ON_CONNECT_WALLET_SUCCESS: 'on_connect_wallet_success',
  ON_DISCONNECT_WALLET: 'on_disconnect_wallet',
  ON_SWITCH_CHAIN: 'on_switch_chain',
  ON_ADD_CHAIN: 'on_add_chain',
  ADD_CHAIN_SUCCESS: 'add_chain_success',
  EDIT_CHAIN_SUCCESS: 'add_chain_success',
  ADD_CHAIN_CANCEL: 'add_chain_cancel',
  APP_OPENED: 'app_opened',
};
export default EventsConstants;
