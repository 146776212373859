import isArray from 'lodash/isArray';
import InternalEventService from '../../../../services/API/InternalAPIService';
import baseController from './Base';

const firebaseController = {
  boot(cb) {
    console.log(`Internal~Initiate in ${this.type} telemetry service`);
    cb && cb(null, this);
  },
  setUser(cb) {
    const userInfo = this._getUserInfo();
    this.uniqueUserId = userInfo.id.toString() || '0';
    cb && cb();
  },
  removeUser(cb) {
    // Remove user is equivalent to setting the user id to 0
    this.analytics.setUserId('0');
    cb && cb();
  },
  sendEvent(key = '', value = {}) {
    if (typeof value !== 'object' || isArray(value)) {
      console.error('Internal~sendEvent: failure value must be of type object');
    }
    const source = 'web3client-web-app';
    const eventValue = {
      ...value,
      source,
      channel: process.env.REACT_APP_CHANNEL,
    };
    const eventKey = key.toLowerCase();
    InternalEventService.sendEvent({
      userId: this.uniqueUserId,
      name: eventKey,
      meta: JSON.stringify(eventValue),
    });
    if (process.env.REACT_APP_CHANNEL === 'dev') {
      console.log('Internal~sendEvent: success', eventKey, {
        userId: this.uniqueUserId,
        name: eventKey,
        meta: JSON.stringify(eventValue),
      });
    }
  },
};
export default () =>
  Object.assign(Object.create(baseController), firebaseController, {
    type: 'internal',
  });
