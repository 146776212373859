import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  FormControl,
  InputLabel,
  // Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import { useMetaMask } from 'metamask-react';
import { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { useRecoilValue } from 'recoil';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import CryptoIcon from '../../../../components/CryptoIcon';
import { AppSettings } from '../../../../states/SettingsState';
import OverflowTruncate from '../../../../components/OverflowTruncate';
import { STATE_MUTUABLITIY_PARAMETER } from '../../../../constants/AppConstants';
import Messages from '../../../../constants/Messages';
import useTabs from '../../../../hooks/useTabs';
import {
  AddOrSwitchChainService,
  getActiveChain,
  getAllEVMChains,
  getChainName,
  getChainSymbol,
} from '../../../../services/ChainService';
import CollectionService from '../../../../services/CollectionService';
import { sendToUpdateMetamaskChain } from '../../../../services/EventService';
import HistoryService from '../../../../services/HistoryService';
import { getABIPayload } from '../../../../services/PlaygroundService';
import TabService from '../../../../services/TabService';
import { CollectionListState } from '../../../../states/CollectionState';
import { HistoryMethodListState } from '../../../../states/HistoryState';
import validation from '../../../../utils/validation';
// import { ShareABIs } from '../../../Dialogs/ShareABIs';
import CreateOrSelectAbiModal from '../../../modals/CreateOrSelectAbiModal';
import SaveAsModal from '../../../modals/SaveAsModal';
import ABIPreference from '../ABIPreference';
import CodeGen from '../CodeGen';
import SignConfigModal from './SignConfigModal';
import WalletOptions from './WalletOptions';
// import Input, { INPUT_VARIANTS } from '../../../../components/Input';
import getClone from '../../../../utils/getClone';
import AutoGenerateDocsMethod from '../../../Drawer/AutoGenerateDocsMethod';

const Header = ({ collectionMethodFormData, setCollectionMethodFormData }) => {
  const [switchChainRef, setSwitchChainRef] = useState(null);
  const collectionList = useRecoilValue(CollectionListState);
  const { account } = useMetaMask();
  const setting = useRecoilValue(AppSettings);

  const [openDrawer, setOpenDrawer] = useState(false);

  const HistoryMethodList = useRecoilValue(HistoryMethodListState);

  const { selectedTab } = useTabs();

  const currentCollection = CollectionService.findCollection(selectedTab);
  const currentCollectionMethod =
    CollectionService.findCollectionMethod(selectedTab);

  const { description } = currentCollection || {};

  const currentAddress = CollectionService.getActiveContractAddress(
    get(currentCollection, 'meta')
  );

  const [isEditDescription, setIsEditDescription] = useState(false);

  const descriptionTypeValue = useRef();

  const {
    contractAddress: formAddress,
    names: formParamNames,
    types: formParamTypes,
    values: formValues,
    stateMutability: formStateMutability,
    methodName: formMethodName,
    selectedChain: formSelectedChain,
    tempABIs,
    deposit,
    gas,
    description: formDescription,
  } = collectionMethodFormData;

  const { meta } = currentCollection ?? {};
  const isNewMethod = TabService.isUntitledTab(selectedTab);
  const isHistoryTab = TabService.isHistoryTab(selectedTab);

  const isNewMethodOrHistoryTab = isNewMethod || isHistoryTab;

  const getHistoryMethod = () => {
    return HistoryService.findHistoryByMethodId({
      methodId: TabService.getCollectionMethodId(selectedTab),
      allHistory: HistoryMethodList,
    });
  };

  const tempABIsActiveChain =
    getActiveChain(tempABIs) ||
    getActiveChain(getHistoryMethod()?.tempABIs || []);

  const isNear = CollectionService.isActiveContractNear(
    isEmpty(meta) ? { chainList: [tempABIsActiveChain] } : meta
  );

  const networkID = CollectionService.getActiveContractNetworkID(
    isEmpty(meta) ? { chainList: [tempABIsActiveChain] } : meta
  );
  const networkTypeID = CollectionService.getActiveContractNetworkTypeID(
    isEmpty(meta) ? { chainList: [tempABIsActiveChain] } : meta
  );

  function onChangeContractAddress(e) {
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      contractAddress: e.target.value,
    });
  }

  const handleOnChangeStateMutability = (e) => {
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      stateMutability: e.target.value,
    });
  };

  const handleOnChangeMethodName = (e) => {
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      methodName: e.target.value,
    });
  };

  const handleOnChangeDeposit = (e) => {
    const value = e?.target?.value;
    if (value && !validation.isValidNumber(value)) return;

    setCollectionMethodFormData({
      ...collectionMethodFormData,
      deposit: value === '' ? '' : e.target.value,
    });
  };

  const handleOnChangeGas = (e) => {
    const value = e?.target?.value;
    if (value && !validation.isValidNumber(value)) return;

    setCollectionMethodFormData({
      ...collectionMethodFormData,
      gas: value === '' ? '' : e.target.value,
    });
  };

  const explorersChains = getAllEVMChains();
  const chains = map(explorersChains, (_c) => ({
    ..._c,
    label: _c?.name,
    value: _c?.chainId,
  }));

  const handleSwitchChainClose = (event) => {
    event?.stopPropagation?.();
    setSwitchChainRef(null);
  };

  function handleChainSelection(_, value) {
    const findChainData = find(chains, (list) => {
      return get(list, 'chainId') === value?.id;
    });
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      selectedChain: findChainData,
    });
  }

  /**
   * switching chain for particular ABI
   * @param {object} selectedChain
   */
  const handleSwitchChain = (data) => {
    const selectedChain = find(
      get(currentCollection, 'meta.chainList'),
      (list) => {
        const onChangeChainId = data.value.split(' ');
        const finalOnChangeChainId = Number(onChangeChainId[0]);
        return get(list, 'chainId') === finalOnChangeChainId;
      }
    );
    AddOrSwitchChainService({
      ABIList: collectionList,
      SelectedABI: currentCollection,
      selectedChain,
      isSwitching: true,
    });
    sendToUpdateMetamaskChain(get(selectedChain, 'chainId'));
  };

  const darkThemeStyles = {
    selectedBackgroundColor: '#2d3748',
    backgroundColor: '#1a1a2e',
    hoverBackgroundColor: '#2d3748',
    textColor: '#e2e8f0',
    labelColor: '#1a1a2e',
  };

  const lightThemeStyles = {
    selectedBackgroundColor: '#edf2f7',
    backgroundColor: '#fff',
    hoverBackgroundColor: '#edf2f7',
    textColor: '#2d3748',
    labelColor: '#fff',
  };

  const creatableSelectStyle = () => {
    if (setting.theme === 'dark') {
      return darkThemeStyles;
    }
    return lightThemeStyles;
  };
  const creatableSelectStyleFinal = creatableSelectStyle();

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      '&:active': {
        borderColor: '#1682fc',
      },
      backgroundColor: creatableSelectStyleFinal.labelColor,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: '100%',
        backgroundColor: isSelected
          ? creatableSelectStyleFinal.selectedBackgroundColor
          : creatableSelectStyleFinal.backgroundColor,
        '&:hover': {
          backgroundColor: isFocused
            ? creatableSelectStyleFinal.hoverBackgroundColor
            : '',
          color: isSelected ? creatableSelectStyleFinal.textColor : '',
        },
      };
    },
  };

  const switchChainOptions = map(
    get(currentCollection, 'meta.chainList'),
    (list) => ({
      label: get(list, 'address'),
      value: `${get(list, 'chainId')} ${get(list, 'id')}`,
    })
  );

  const activeState = find(
    get(currentCollection, 'meta.chainList'),
    (list) => list.isActive === true
  );

  const activeStateObjects = {
    label: activeState?.address,
    value: `${activeState?.chainId} ${activeState?.id}`,
  };

  const formatOptionLabel = ({ label, value }) => {
    const splitValueChainId = value.split(' ');
    const finalSplitChainId = Number(splitValueChainId[0]);

    return (
      <div
        className="flex text-sm pl-1"
        style={{ color: creatableSelectStyleFinal.textColor }}>
        {!label.includes('Create') && (
          <CryptoIcon
            symbol={getChainSymbol(finalSplitChainId)}
            size={16}
            className="mr-2"
          />
        )}
        <div className=" flex flex-col">
          <div>{getChainName(finalSplitChainId)}</div>
          <OverflowTruncate name={label} />
        </div>
      </div>
    );
  };

  const convertFormToMethod = CollectionService.convertFormToMethod({
    formData: collectionMethodFormData,
    selectedTab,
  });

  useEffect(() => {
    if (isNewMethodOrHistoryTab) {
      setCollectionMethodFormData((prev) => ({
        ...prev,
        tempABIs: getHistoryMethod()?.tempABIs || [],
      }));
    }
  }, []);

  const chainId =
    CollectionService.getActiveChain(meta) ||
    get(tempABIsActiveChain, 'network.chainId');

  const handleDescription = () => {
    const replaceCollections = map(getClone(collectionList), (_c) => {
      if (get(_c, 'id') === get(currentCollection, 'id')) {
        set(_c, 'description', descriptionTypeValue.current?.value);
      }
      return _c;
    });
    // add method into new ABI
    CollectionService.replaceCollections(replaceCollections);
    setIsEditDescription(false);
  };

  const handleMethodDescription = (e) => {
    if (isNewMethodOrHistoryTab) {
      setCollectionMethodFormData({
        ...collectionMethodFormData,
        description: e.target.value,
      });
      return;
    }
    const cloneCollectionList = getClone(collectionList);

    forEach(cloneCollectionList, (_c) => {
      if (get(_c, 'id') === get(currentCollection, 'id')) {
        forEach(get(_c, 'abi'), (_method) => {
          if (get(_method, 'id') === get(currentCollectionMethod, 'id')) {
            set(_method, 'description', e.target.value);
          }
        });
      }
      return _c;
    });
    // add method into new ABI
    CollectionService.replaceCollections(cloneCollectionList);
  };

  return (
    <>
      <div className="flex flex-col w-full mb-1">
        <div className="flex flex-col md:flex-row w-full">
          <div className="flex flex-col pl-2 !pb-0 justify-center">
            <div className="text-xl font-bold">
              {meta?.name ?? 'Untitled ABI'}
            </div>
            <div className="flex">
              {(!isNewMethodOrHistoryTab || !isEmpty(tempABIsActiveChain)) && (
                <>
                  <CryptoIcon
                    symbol={
                      !isNull(chainId) && !isUndefined(chainId)
                        ? getChainSymbol(getChainSymbol(chainId))
                        : 'NEAR'
                    }
                    size={16}
                    className="mr-2"
                  />
                  <Tooltip title="Active Chain">
                    <div className="font-medium text-sm">
                      {CollectionService.getActiveContract(meta)?.network
                        ?.chainName ||
                        get(tempABIsActiveChain, 'network.chainName')}
                    </div>
                  </Tooltip>
                </>
              )}
              {(!isNewMethodOrHistoryTab || !isEmpty(tempABIsActiveChain)) && (
                <>
                  <div className="font-medium text-sm ml-3">
                    <OverflowTruncate
                      toolTipTitle={`Contract ID: ${CollectionService.getActiveContractAddress(
                        meta
                      )}`}
                      name={
                        CollectionService.getActiveContractAddress(meta) ||
                        get(tempABIsActiveChain, 'address')
                      }
                      start={10}
                    />
                  </div>
                  <CopyToClipboard
                    text={
                      CollectionService.getActiveContractAddress(meta) ||
                      get(tempABIsActiveChain, 'address')
                    }
                    className="cursor-pointer flex items-center ml-2"
                    onCopy={() => {
                      toast.success(Messages.INFO.COPY_CLIPBOARD);
                    }}>
                    <div className="text-xs text-slate-400 ml-2">
                      <Tooltip title="Copy">
                        <FileCopyIcon fontSize="14px" />
                      </Tooltip>
                    </div>
                  </CopyToClipboard>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row pt-1.5 md:ml-auto space-y-3">
            {isNear && (
              <div className="flex items-center space-x-3 mr-5 mt-5 md:mt-3">
                <TextField
                  label="Deposit"
                  variant="outlined"
                  sx={{
                    '& .MuiInputBase-input': {
                      boxShadow: 'none',
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  className="w-1/2 md:w-36"
                  size="small"
                  type="text"
                  name="deposit"
                  id="deposit"
                  onChange={handleOnChangeDeposit}
                  value={deposit}
                  autoComplete="off"
                />

                <TextField
                  label="Gas"
                  variant="outlined"
                  sx={{
                    '& .MuiInputBase-input': {
                      boxShadow: 'none',
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  className="w-1/2 md:w-36"
                  size="small"
                  type="text"
                  name="gas"
                  id="gas"
                  onChange={handleOnChangeGas}
                  value={gas}
                  autoComplete="off"
                />
              </div>
            )}
            <div className="flex items-center space-x-3">
              <ABIPreference
                currentCollection={currentCollection}
                isNewMethodOrHistoryTab={isNewMethodOrHistoryTab}
                collectionMethodFormData={collectionMethodFormData}
                setCollectionMethodFormData={setCollectionMethodFormData}
              />
              <WalletOptions network={networkID} networkType={networkTypeID} />
              {/* <ShareABIs isShareIconWithBtn /> */}
              {!isNewMethodOrHistoryTab && (
                <AutoGenerateDocsMethod
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                />
              )}
            </div>
          </div>
        </div>

        {/* {!isNewMethodOrHistoryTab && (
          <div className="ml-3 space-x-1 flex items-center mb-1">
            {!description && !isEditDescription && (
              <div className="font-medium">Description</div>
            )}
            {isEditDescription ? (
              <div className="pt-1 w-[28vw] flex items-center">
                <TextField
                  inputRef={descriptionTypeValue}
                  variant="outlined"
                  sx={{
                    '& .MuiInputBase-input': {
                      boxShadow: 'none',
                    },
                  }}
                  className="w-full"
                  size="small"
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Description"
                  autoComplete="off"
                />

                <HighlightOffIcon
                  onClick={() => {
                    setIsEditDescription(false);
                  }}
                  className="mb-1 ml-3 mr-1.5 cursor-pointer"
                  color="error"
                />
                <CheckCircleOutlineIcon
                  color="success"
                  onClick={handleDescription}
                  className="mb-1 cursor-pointer"
                />
              </div>
            ) : (
              <div>{description}</div>
            )}
            {!isEditDescription && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsEditDescription(true);
                  setTimeout(() => {
                    set(
                      descriptionTypeValue,
                      'current.value',
                      get(currentCollection, 'description', '')
                    );
                  }, 0);
                }}>
                <BorderColorIcon fontSize="0.7rem" />
              </div>
            )}
          </div>
        )} */}

        <div className="flex">
          <div className="p-2 text-sm flex-1 flex md:flex-row flex-col md:space-y-0 space-y-4 md:items-center">
            <div className="min-w-[150px]">
              <FormControl fullWidth size="small">
                <InputLabel id="state-mutability-select">
                  State Mutability
                </InputLabel>
                <Select
                  labelId="state-mutability-select"
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleOnChangeStateMutability}
                  value={formStateMutability}
                  label="State Mutability"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}>
                  {map(STATE_MUTUABLITIY_PARAMETER, (obj, key) => {
                    if (key !== 'default') {
                      return (
                        <MenuItem value={key} key={key}>
                          {obj}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="w-full md:pl-2 pl-0">
              <TextField
                variant="outlined"
                sx={{
                  '& .MuiInputBase-input': {
                    boxShadow: 'none',
                  },
                }}
                className="w-full"
                size="small"
                type="text"
                name="method-name"
                id="method-name"
                placeholder="Method Name"
                onChange={handleOnChangeMethodName}
                value={formMethodName}
                autoComplete="off"
              />
            </div>
            <div className="flex flex-row items-center">
              <div className="md:pl-2 pl-0 w-full">
                <SignConfigModal
                  collectionMethodFormData={collectionMethodFormData}
                  setCollectionMethodFormData={setCollectionMethodFormData}
                />
              </div>
              <div className="flex flex-row pl-2 md:items-center">
                <CreateOrSelectAbiModal
                  collectionMethodFormData={collectionMethodFormData}
                />
              </div>
              <div>
                <SaveAsModal
                  collectionMethodFormData={collectionMethodFormData}
                />
              </div>
              {!isNear && (
                <div>
                  <CodeGen
                    options={() =>
                      getABIPayload({
                        formAddress: currentAddress || formAddress,
                        currentCollectionMethod: convertFormToMethod,
                        formMethodName,
                        formStateMutability,
                        formParamNames,
                        formParamTypes,
                        formValues,
                        account,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <TextField
          variant="outlined"
          sx={{
            '& .MuiInputBase-input': {
              boxShadow: 'none',
            },
          }}
          className="w-[calc(82%+1px)] !ml-2"
          size="small"
          type="text"
          name="method-description"
          id="method-description"
          placeholder="Description"
          onChange={handleMethodDescription}
          value={
            isNewMethodOrHistoryTab
              ? formDescription
              : get(currentCollectionMethod, 'description', '')
          }
          autoComplete="off"
        /> */}
      </div>
    </>
  );
};

export default Header;
