/* eslint-disable no-empty */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

/* eslint-disable no-restricted-syntax */
const generateReadme = (data) => {
  if (isEmpty(data)) return null;

  let result = '';

  const objs = data;

  const filtered = objs.abi.filter((x) => x.type == 'function');

  const abiName = objs.meta.name;
  const contractFilter = objs.meta.chainList.filter((y) => y.isActive == true);

  result += `## ${abiName}\n`;

  result += `${get(objs, 'description', '')}\n`;

  let conTable = '';
  conTable += `| **Contract Name** | *${contractFilter[0].name}* |\n| --- | --- |\n`;
  conTable += `| **Contract Address** | *${contractFilter[0].address}* |\n`;
  conTable += `| **Chain Name** | *${contractFilter[0].network.chainName}* |\n`;
  conTable += `| **Chain ID** | *${contractFilter[0].network.chainId}* |\n`;

  if (contractFilter[0].network.type != null) {
    conTable += `| **Type** | *${contractFilter[0].network.type.id}* |\n`;
  }

  conTable += `| **RPC URL** | *${contractFilter[0].network.rpc}* |\n`;

  result += `${conTable}\n`;

  for (let x in filtered) {
    result += `### ${filtered[x].name}\n`;

    result += `${get(filtered, `${x}.description`, '')}\n`;

    let fnTable = '| Name | State Mutability |\n| --- | --- |\n';
    fnTable += `| *${filtered[x].name}* | *${filtered[x].stateMutability}* |\n`;
    result += `${fnTable}\n`;
    if (
      filtered[x].inputs == null ||
      filtered[x].inputs == '' ||
      filtered[x].inputs == []
    ) {
    } else {
      result += '#### Input param(s) :\n';
      let ipTable = '| Input | Type |\n| --- | --- |\n';
      for (let m in filtered[x].inputs) {
        ipTable += `| *${filtered[x].inputs[m].name}* | *${filtered[x].inputs[m].type}* |\n`;
      }
      result += `${ipTable}\n`;
    }
    if (
      filtered[x].outputs == null ||
      filtered[x].outputs == '' ||
      filtered[x].outputs == []
    ) {
    } else {
      result += '#### Output param(s) :\n';
      let opTable = '| Type |\n| --- |\n';
      for (let ot in filtered[x].outputs) {
        opTable += `| *${filtered[x].outputs[ot].type}* |\n`;
      }
      result += `${opTable}\n`;
    }
  }

  return result;
};

export default generateReadme;

/* eslint-disable no-restricted-syntax */
export const generateABIMarkDown = (data) => {
  if (isEmpty(data)) return null;

  let result = '';

  const objs = data;

  const abiName = objs.meta.name;
  const contractFilter = objs.meta.chainList.filter((y) => y.isActive == true);

  result += `## ${abiName}\n`;

  result += `${get(objs, 'description', '')}\n`;

  let conTable = '';
  conTable += `| **Contract Name** | *${contractFilter[0].name}* |\n| --- | --- |\n`;
  conTable += `| **Contract Address** | *${contractFilter[0].address}* |\n`;
  conTable += `| **Chain Name** | *${contractFilter[0].network.chainName}* |\n`;
  conTable += `| **Chain ID** | *${contractFilter[0].network.chainId}* |\n`;

  if (contractFilter[0].network.type != null) {
    conTable += `| **Type** | *${contractFilter[0].network.type.id}* |\n`;
  }

  conTable += `| **RPC URL** | *${contractFilter[0].network.rpc}* |\n`;

  result += `${conTable}\n`;

  return result;
};

/* eslint-disable no-restricted-syntax */
export const generateMethodMarkDown = (data) => {
  if (isEmpty(data)) return null;

  let result = '';

  const objs = data;

  const filtered = objs.abi.filter((x) => x.type == 'function');

  for (let x in filtered) {
    result += `### ${filtered[x].name}\n`;

    result += `${get(filtered, `${x}.description`, '')}\n`;

    let fnTable = '| Name | State Mutability |\n| --- | --- |\n';
    fnTable += `| *${filtered[x].name}* | *${filtered[x].stateMutability}* |\n`;
    result += `${fnTable}\n`;
    if (
      filtered[x].inputs == null ||
      filtered[x].inputs == '' ||
      filtered[x].inputs == []
    ) {
    } else {
      result += '#### Input param(s) :\n';
      let ipTable = '| Input | Type |\n| --- | --- |\n';
      for (let m in filtered[x].inputs) {
        ipTable += `| *${filtered[x].inputs[m].name}* | *${filtered[x].inputs[m].type}* |\n`;
      }
      result += `${ipTable}\n`;
    }
    if (
      filtered[x].outputs == null ||
      filtered[x].outputs == '' ||
      filtered[x].outputs == []
    ) {
    } else {
      result += '#### Output param(s) :\n';
      let opTable = '| Type |\n| --- |\n';
      for (let ot in filtered[x].outputs) {
        opTable += `| *${filtered[x].outputs[ot].type}* |\n`;
      }
      result += `${opTable}\n`;
    }
  }

  return result;
};

// ----------------------------------------------------------------

export const generateABINameMarkDown = (abiData) => {
  const abiName = abiData?.meta?.name;
  return `## ${abiName}\n`;
};

export const generateABIDetailsMarkDown = (abiData) => {
  let result = '';

  const objs = abiData;

  const contractFilter = objs.meta.chainList.filter((y) => y.isActive == true);

  result += `${get(objs, 'description', '')}\n`;

  let conTable = '';
  conTable += `| **Contract Name** | *${contractFilter[0].name}* |\n| --- | --- |\n`;
  conTable += `| **Contract Address** | *${contractFilter[0].address}* |\n`;
  conTable += `| **Chain Name** | *${contractFilter[0].network.chainName}* |\n`;
  conTable += `| **Chain ID** | *${contractFilter[0].network.chainId}* |\n`;

  if (contractFilter[0].network.type != null) {
    conTable += `| **Type** | *${contractFilter[0].network.type.id}* |\n`;
  }

  conTable += `| **RPC URL** | *${contractFilter[0].network.rpc}* |\n`;

  result += `${conTable}\n`;

  return result;
};

export const generateMethodNameMarkDown = (methodData) => {
  return `### ${methodData?.name}\n`;
};

export const generateMethodDetailsMarkDown = (methodData) => {
  let result = '';

  result += `${get(methodData, `description`, '')}\n`;

  let fnTable = '| Name | State Mutability |\n| --- | --- |\n';
  fnTable += `| *${methodData.name}* | *${methodData.stateMutability}* |\n`;
  result += `${fnTable}\n`;
  if (
    methodData.inputs == null ||
    methodData.inputs == '' ||
    methodData.inputs == []
  ) {
  } else {
    result += '#### Input param(s) :\n';
    let ipTable = '| Input | Type |\n| --- | --- |\n';
    for (let m in methodData.inputs) {
      ipTable += `| *${methodData.inputs[m].name}* | *${methodData.inputs[m].type}* |\n`;
    }
    result += `${ipTable}\n`;
  }
  if (
    methodData.outputs == null ||
    methodData.outputs == '' ||
    methodData.outputs == []
  ) {
  } else {
    result += '#### Output param(s) :\n';
    let opTable = '| Type |\n| --- |\n';
    for (let ot in methodData.outputs) {
      opTable += `| *${methodData.outputs[ot].type}* |\n`;
    }
    result += `${opTable}\n`;
  }
  return result;
};

// ----------------------------------------------------------------
