import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { useRecoilState } from 'recoil';
import get from 'lodash/get';
import TextOverflowTruncate from '../../../../components/TextOverflowTruncate';
import ABIStateMutuability from '../ABIs/ABIStateMutuability';
import HistoryService from '../../../../services/HistoryService';
import { MobileDrawerOpenState } from '../../../../states/ResponsiveViewState';
import TabService from '../../../../services/TabService';
import DeleteConfirmationModal from '../../../modals/DeleteConfirmationModal';
import IconWrapper from '../../../../components/IconWrapper';
import useTabs from '../../../../hooks/useTabs';

const MethodListItem = ({ historyMethod }) => {
  const [isHover, setIsHover] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );

  const { setSelectedTab, selectedTab, removeTabByMethodId } = useTabs();

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const stateMutability = get(
    historyMethod,
    'method.stateMutability',
    'viewable'
  );

  /**
   * show confirm message for delate method
   * @param {*} e
   */
  const showDeleteConfirmMsg = (e) => {
    e?.stopPropagation?.();
    setModalOpen(true);
  };

  /**
   * delete Method
   * @param {*} e
   */
  const triggerDeleteMethod = (e) => {
    HistoryService.deleteHistory(get(historyMethod, 'id'));

    removeTabByMethodId(get(historyMethod, 'method.id'));

    setModalOpen(false);
  };

  function handleSelectABI() {
    setSelectedTab(
      TabService.generateHistoryAbiMethodTabName(
        get(historyMethod, 'method.id')
      )
    );
  }

  const handleCollectionMethod = () => {
    if (!isSelectedMethod) {
      handleDrawerToggle();
      handleSelectABI();
    }
  };

  const isSelectedMethod =
    TabService.getCollectionMethodId(selectedTab) ===
    get(historyMethod, 'method.id');

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <ListItemButton
        selected={isSelectedMethod}
        className="!pl-3 !h-7"
        onClick={handleCollectionMethod}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <div className="w-[95%]">
          <ListItemText
            primary={
              <div className="flex p-1 items-center">
                <ABIStateMutuability stateMutability={stateMutability} />
                <div className="flex text-sm ml-2 overflow-hidden ">
                  <TextOverflowTruncate
                    name={get(historyMethod, 'method.name')}
                  />
                </div>
              </div>
            }
          />
        </div>
        {isHover && isSelectedMethod && (
          <>
            <IconWrapper>
              <Tooltip title="Delete" onClick={showDeleteConfirmMsg}>
                <DeleteIcon
                  sx={{
                    '&:hover': {
                      color: 'error.main',
                      opacity: '0.7',
                    },
                  }}
                  className="!w-full !h-full"
                  fontSize="0.65rem"
                />
              </Tooltip>
            </IconWrapper>
          </>
        )}
      </ListItemButton>
      <DeleteConfirmationModal
        title={get(historyMethod, 'method.name')}
        isOpen={modalOpen}
        onClose={handleClose}
        onSubmit={triggerDeleteMethod}
      />
    </>
  );
};

export default MethodListItem;
