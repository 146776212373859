import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  DialogActions,
  Divider,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useRecoilValue } from 'recoil';
import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';
import { getAllChains } from '../../services/ChainService';
import ChainSelector from '../ChainSelector';
import { IsShareLoadingState } from '../../states/LoadingState';
import CollectionService from '../../services/CollectionService';
import { W3C_ABI_VERSION } from '../../constants/AppConstants';
import { uuidv4 } from '../../utils/UUIDV4';

const URLExplorer = ({ isOpen, handleClose, onSubmit, payload }) => {
  const isShareLoading = useRecoilValue(IsShareLoadingState);

  const addressData = CollectionService?.getActiveContractAddress(
    payload?.meta
  );
  const chainIdData = CollectionService?.getActiveChain(payload?.meta);

  const isActiveContractNear = CollectionService?.isActiveContractNear(
    payload?.meta
  );

  let shareABI = payload;

  if (!addressData || (!chainIdData && !isActiveContractNear)) {
    const allChains = getAllChains();

    const selectedChain = find(allChains, (chain) => {
      return get(chain, 'chainId') === payload?.meta?.chainId;
    });

    shareABI = {
      meta: {
        version: W3C_ABI_VERSION,
        name: payload?.meta?.name,
        source: payload?.meta?.source,
        chainList: [
          {
            address: payload?.meta?.address,
            id: uuidv4(),
            isActive: true,
            name: get(selectedChain, 'name'),
            network: {
              chainId: payload?.meta?.chainId,
              rpc: Array.isArray(selectedChain?.rpc)
                ? selectedChain?.rpc?.[0]
                : selectedChain?.rpc,
              chainName: get(selectedChain, 'name'),
              type: get(selectedChain, 'type'),
              network: get(selectedChain, 'network') || null,
            },
          },
        ],
      },
      abi: payload?.abi,
      parentId: payload?.parentId,
      id: uuidv4(),
    };
  }

  const { abi, meta } = shareABI;

  const name = meta?.name;
  const address = CollectionService?.getActiveContractAddress(meta);
  const activeContract = CollectionService?.getActiveContract(meta);

  const chains = getAllChains();
  const getSelectedChain = find(chains, (_chain) => {
    return get(_chain, 'name') === get(activeContract, 'network.chainName');
  });

  const getABI = () => map(abi, (_C) => _C);
  function handleOnClose() {
    handleClose();
  }

  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onSubmit(event);
        }
      }}>
      <Dialog
        className="relative"
        fullWidth
        open={isOpen}
        onClose={handleOnClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <div className="text-sm sm:text-lg font-bold">
            Do you want to Import Existing ABI
          </div>
          <IconButton
            className="p-2"
            aria-label="close"
            onClick={handleOnClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ width: '100%' }}>
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col mb-4">
              <div className="text-sm">Collection Name</div>
              <TextField sx={{ mt: 1 }} disabled value={name} />
            </div>
            <div className="w-full flex flex-col mb-4">
              <div className="text-sm">Contract ID</div>
              <TextField sx={{ mt: 1 }} disabled value={address} />
            </div>
            <div className="w-full flex flex-col mb-4 disabled disabled:opacity-75:">
              <ChainSelector
                name="chain-name"
                value={getSelectedChain}
                label="Select Chain"
                options={chains}
                disableChain={true}
              />
            </div>
            <div className="w-full flex flex-col mb-4">
              <div className="text-sm">ABI</div>
              <textarea
                className="mt-1 cursor-not-allowed focus:ring-primary focus:border-secondary block shadow-sm sm:text-sm border-secondary bg-primary rounded-md w-full"
                style={{ height: 300, opacity: 0.5 }}
                placeholder="Paste ABI JSON here"
                name="abi"
                id="abi-json"
                value={JSON.stringify(getABI())}
                autoComplete="off"
              />
            </div>
          </div>
        </DialogContent>

        {isShareLoading && (
          <div className="absolute w-full h-[91.9vh] flex justify-center items-center font-bold text-lg bg-black bg-opacity-20">
            <CircularProgress size={60} />
          </div>
        )}

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => onSubmit(shareABI)}
            disabled={isShareLoading}>
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default URLExplorer;
