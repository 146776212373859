import { decToHex, hexToDec } from 'hex2dec';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useMetaMask } from 'metamask-react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import { META_MASK_STATUS, NETWORKS } from '../constants/AppConstants';
import {
  AddOrSwitchChainService,
  getActiveChain,
  getAllChains,
  getChainName,
} from '../services/ChainService';
import CollectionService from '../services/CollectionService';
import { subscribeToUpdateMetamaskChain } from '../services/EventService';
import { CollectionListState } from '../states/CollectionState';
import { uuidv4 } from '../utils/UUIDV4';
import useTabs from './useTabs';

const useSwitchMetamaskChain = () => {
  const { status, switchChain, addChain, chainId } = useMetaMask();

  // selected metamask chain
  const connectedChain = hexToDec(chainId ?? '');

  // all ABI list
  const collectionList = useRecoilValue(CollectionListState);

  // selected Tab
  const { selectedTab } = useTabs();

  // selected ABI list
  const currentCollection = CollectionService.findCollection(selectedTab);

  function switchEVMChain(value) {
    switchChain(decToHex(`${value?.chainId}`))
      .then(() => {
        CollectionService.updateChain(currentCollection?.id, value?.chainId);
      })
      .catch((err) => {
        if (err.code === 4902) {
          const blockExplorerUrlsData = {};

          const blockExplorerUrls = map(
            get(value, 'explorers', []),
            (_ex) => _ex?.url || ''
          );

          if (!isEmpty(blockExplorerUrls)) {
            blockExplorerUrlsData.blockExplorerUrls = blockExplorerUrls;
          }

          const newChainPayload = {
            chainId: decToHex(`${get(value, 'chainId')}`),
            chainName: get(value, 'name'),
            rpcUrls: get(value, 'rpc'),
            nativeCurrency: get(value, 'nativeCurrency'),
            ...blockExplorerUrlsData,
          };
          addChain(newChainPayload)
            .then(() => {
              CollectionService.updateChain(
                currentCollection?.id,
                value?.chainId
              );
            })
            .catch((err) => {
              toast.error(err?.message || 'Failed to add chain');
            });
        }
      });
  }
  function handleChainSelection(_, value) {
    if (status === META_MASK_STATUS.CONNECTED) {
      const findChainData = find(getAllChains(), (list) => {
        return get(list, 'chainId') === value?.id;
      });
      switchEVMChain(findChainData);
    }
  }
  //  Switch Chain
  //----------------------------------------------------------------
  useEffect(() => {
    if (isEmpty(currentCollection)) return;
    const activeChain =
      getActiveChain(currentCollection?.meta?.chainList) || {};
    const chainId = activeChain?.network?.chainId;
    if (`${chainId}` === `${connectedChain}`) return;
    handleChainSelection({}, { id: chainId });
  }, [selectedTab, status]);

  useEffect(() => {
    const unSubscribeMetamaskChain = subscribeToUpdateMetamaskChain((id) => {
      if (`${id}` === `${connectedChain}`) return;
      handleChainSelection({}, { id });
    });
    return () => {
      unSubscribeMetamaskChain && unSubscribeMetamaskChain();
    };
  }, [connectedChain, status]);
  //----------------------------------------------------------------

  // trigger when changing metamask chain
  useEffect(() => {
    if (!connectedChain) return;
    const isNear =
      CollectionService.getActiveContractNetworkTypeID(
        get(currentCollection, 'meta')
      ) === NETWORKS.NEAR;

    if (isNear) return;
    const connected = find(
      getAllChains(),
      (_chain) => _chain.chainId === Number(connectedChain)
    );

    const chainList = get(currentCollection, 'meta.chainList');

    const activeChain = getActiveChain(chainList);
    if (get(activeChain, 'network.chainId') === get(connected, 'chainId'))
      return;

    const chainIds = map(chainList, (chain) => get(chain, 'network.chainId'));

    if (chainIds?.includes(get(connected, 'chainId'))) {
      // switch chain
      AddOrSwitchChainService({
        ABIList: collectionList,
        SelectedABI: currentCollection,
        selectedChain: find(
          chainList,
          (chain) => get(chain, 'network.chainId') === get(connected, 'chainId')
        ),
        isSwitching: true,
      });
    } else if (get(connected, 'chainId')) {
      // add chain
      AddOrSwitchChainService({
        ABIList: collectionList,
        SelectedABI: currentCollection,
        selectedChain: {
          id: uuidv4(),
          name: getChainName(get(connected, 'chainId')),
          address: get(chainList, '0.address'),
          network: {
            chainId: get(connected, 'chainId'),
            rpc: get(connected, 'rpc.0'),
            chainName: getChainName(get(connected, 'chainId')),
            type: get(connected, 'type'),
            network: get(connected, 'network'),
          },
          isActive: true,
        },
      });
    }
  }, [connectedChain]);
};

export default useSwitchMetamaskChain;
