import { Button, IconButton, Tooltip } from '@mui/material';
import get from 'lodash/get';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { useRecoilValue } from 'recoil';
import toast from 'react-hot-toast';
import { Modal } from '../../components/Modal';
import TelemetryService from '../../services/TelemetryService';
import { TelemetryEvents } from '../../constants/EventsConstants';
import { IMPORT_TYPES } from '../../constants/AppConstants';
import SelectField from '../../components/SelectField';
import { CollectionListState } from '../../states/CollectionState';
import IconWrapper from '../../components/IconWrapper';
import AddNewAbiModal from './AddNewAbiModal';
import CollectionService from '../../services/CollectionService';
import Messages from '../../constants/Messages';
import useTabs from '../../hooks/useTabs';
import TabService from '../../services/TabService';
import getClone from '../../utils/getClone';

const SaveAsModal = ({ collectionMethodFormData }) => {
  const [selectAbiModalOpen, setSelectAbiModalOpen] = useState(false);
  const [selectedAbi, setSelectedAbi] = useState(null);
  const [createAbiModalOpen, setCreateAbiModalOpen] = useState(false);
  const collectionList = useRecoilValue(CollectionListState);

  const { selectedTab, replaceTabByMethodId } = useTabs();

  const isNewMethod = TabService.isUntitledTab(selectedTab);
  const isHistoryTab = TabService.isHistoryTab(selectedTab);

  const isNewMethodOrHistoryTab = isNewMethod || isHistoryTab;

  /**
   * save as method
   * @returns
   */
  const triggerSaveAsCollectionFn = () => {
    if (!selectedAbi) {
      toast.error(Messages.INFO.SELECT_ABI);
      return;
    }

    // convert form to method
    const newCollectionMethod = CollectionService.convertFormToMethod({
      formData: collectionMethodFormData,
      selectedTab,
    });

    const copyNewCollectionFn = getClone(newCollectionMethod);
    const copyCollectionList = getClone(collectionList);
    const formatCollections = map(copyCollectionList, (list) => {
      if (get(list, 'id') === selectedAbi?.id) {
        list.abi = [...(list?.abi || []), copyNewCollectionFn];
      }
      return list;
    });

    CollectionService.replaceCollections(formatCollections);

    replaceTabByMethodId(
      TabService.getCollectionMethodId(selectedTab),
      `${get(selectedAbi, 'id')}$${get(newCollectionMethod, 'id')}`
    );

    setSelectAbiModalOpen(false);
  };

  const handleCloseSave = (e) => {
    e?.stopPropagation?.();
    setSelectAbiModalOpen(false);
    setSelectedAbi(null);
  };

  const openSaveAsCollectionFn = () => {
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_SAVE_AS);
    setSelectAbiModalOpen(true);
  };

  const handleBackPress = (e) => {
    e?.stopPropagation?.();
    setCreateAbiModalOpen(false);
    setSelectAbiModalOpen(true);
  };

  /**
   * create ABI
   * @param {object} payload
   */
  const triggerCreateNewABI = (payload) => {
    setCreateAbiModalOpen(false);
    setSelectAbiModalOpen(true);
    setTimeout(() => {
      CollectionService.updateCollectionList(payload);
    }, 300);
  };

  /**
   * open create Abi modal open
   */
  const handleNewABI = () => {
    setSelectAbiModalOpen(false);
    setCreateAbiModalOpen(true);
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_NEW_ABI, {
      import_type: IMPORT_TYPES.SAVE_AS,
    });
  };

  useEffect(() => {
    if (!selectAbiModalOpen) {
      setSelectedAbi(null);
    }
  }, [selectAbiModalOpen]);

  return (
    <>
      <Tooltip title="Save As" className="pl-2">
        <IconButton
          color="primary"
          aria-label="save method as"
          onClick={openSaveAsCollectionFn}
          disabled={isNewMethodOrHistoryTab}>
          <IconWrapper>
            <SaveAsIcon className="!w-full !h-full" />
          </IconWrapper>
        </IconButton>
      </Tooltip>
      <div>
        <Modal
          open={selectAbiModalOpen}
          handleClose={handleCloseSave}
          submit={triggerSaveAsCollectionFn}
          primaryBtnText="Save As"
          secondaryBtnText="Cancel">
          <>
            <div className="flex space-x-3 mb-5">
              <div className="w-36 text-lg mt-2">Select ABI</div>
              <div className="w-full">
                <SelectField
                  value={selectedAbi}
                  onChange={(e, newInputValue) => {
                    setSelectedAbi(newInputValue);
                  }}
                  options={map(collectionList, (list) => ({
                    label: get(list, 'meta.name'),
                    id: get(list, 'id'),
                  }))}
                  isDisabledLabel
                />
              </div>
            </div>
            <div className="flex justify-center">
              <Button
                sx={{
                  '&:focus': {
                    boxShadow: 'none',
                  },
                }}
                variant="text"
                size="large"
                onClick={handleNewABI}>
                <span className="text-lg"> Add New ABI</span>
              </Button>
            </div>
          </>
        </Modal>
      </div>
      <AddNewAbiModal
        isOpen={createAbiModalOpen}
        onClose={handleBackPress}
        onSubmit={triggerCreateNewABI}
      />
    </>
  );
};
export default SaveAsModal;
