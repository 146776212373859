import get from 'lodash/get';
import API from '../APIService';

const ContractService = {
  getABI(address) {
    return new Promise((resolve, reject) => {
      const url = `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}`;
      API.fetch(
        url,
        {
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          try {
            const result = JSON.parse(get(data, 'result', ''));
            resolve(result);
          } catch (er) {
            reject(get(data, 'result'));
          }
        }
      );
    });
  },
  importABI(address, url) {
    return new Promise((resolve, reject) => {
      const _url = `${url}?module=contract&action=getabi&address=${address}`;
      API.fetch(
        _url,
        {
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          try {
            if (data?.status === '0') throw new Error(get(data, 'result', ''));
            const result = JSON.parse(get(data, 'result', ''));
            resolve(result);
          } catch (er) {
            reject(er);
          }
        }
      );
    });
  },
};

export default ContractService;
