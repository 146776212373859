import { MenuItem } from '@mui/material';
import get from 'lodash/get';
import some from 'lodash/some';
import { useRecoilState, useRecoilValue } from 'recoil';
import MenuPopover from '../../../../components/MenuPopover';
import { UnSavedCollectionMethodTabsIdState } from '../../../../states/TabStates';
import { ModalOpenState } from '../../../../states/ModalState';
import UnSaveModal from '../../../modals/UnSaveModal';
import useTabs from '../../../../hooks/useTabs';

const MoreOPtionsPopOver = ({ open, anchorEl, onClose, conditionTabClose }) => {
  const [modalOpen, setModalOpen] = useRecoilState(ModalOpenState);

  const unSavedCollectionMethodTabsId = useRecoilValue(
    UnSavedCollectionMethodTabsIdState
  );

  const { selectedTab, removeAllTabs, removeOtherTabs, getAllTabs } = useTabs();

  const triggerCloseAllLogic = () => {
    removeAllTabs();
    onClose();
  };

  const triggerCloseOthersLogic = () => {
    removeOtherTabs(selectedTab);
    onClose();
  };

  /**
   * close all tabs
   * @returns
   */
  const triggerCloseAll = () => {
    const isAnyUnSaveTab = some(getAllTabs(), (_tab) => {
      return unSavedCollectionMethodTabsId?.includes(_tab);
    });
    if (isAnyUnSaveTab) {
      setModalOpen({ open: true, key: 'close_all' });
      return;
    }
    triggerCloseAllLogic();
  };

  /**
   * close all tabs except active tab
   * @returns
   */
  const triggerCloseOthers = () => {
    const isAnyUnSaveTab = some(getAllTabs(), (_tab) => {
      if (_tab === selectedTab) return false;
      return unSavedCollectionMethodTabsId?.includes(_tab);
    });

    if (isAnyUnSaveTab) {
      setModalOpen({ open: true, key: 'close_others' });
      return;
    }
    triggerCloseOthersLogic();
  };

  /**
   * trigger when user click submit in modal
   * @returns
   */
  const triggerModalSubmit = () => {
    switch (get(modalOpen, 'key')) {
      case 'close_all':
        triggerCloseAllLogic();
        setModalOpen({ open: false });
        unSavedCollectionMethodTabsId([]);
        return;
      case 'close_others':
        triggerCloseOthersLogic();
        setModalOpen({ open: false });
        unSavedCollectionMethodTabsId([selectedTab]);
        return;
      default:
        return conditionTabClose();
    }
  };

  const handleClose = (e) => {
    e?.stopPropagation?.();
    setModalOpen({ open: false });
  };

  return (
    <>
      <MenuPopover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.5,

          '& .MuiMenuItem-root': {
            minHeight: '30px',
            borderRadius: 0.75,
            fontSize: '0.65rem',
          },
        }}>
        <MenuItem
          sx={{
            height: '1.9rem',
          }}
          onClick={triggerCloseAll}>
          Close All
        </MenuItem>
        <MenuItem
          sx={{
            height: '1.9rem',
          }}
          onClick={triggerCloseOthers}>
          Close Others
        </MenuItem>
      </MenuPopover>
      <UnSaveModal
        isOpen={get(modalOpen, 'open')}
        onClose={handleClose}
        onSubmit={triggerModalSubmit}
      />
    </>
  );
};

export default MoreOPtionsPopOver;
