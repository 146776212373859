import executor from '../../../../modules/Executor';
import { getABIPayload } from '../../../../services/PlaygroundService';

/**
 * execute method
 * @param {object} params
 */
const sendCollectionFn = async (params) => {
  const {
    formAddress,
    currentCollectionMethod,
    account,
    collectionMethodFormData,
    setCollectionMethodFormData,
    network,
    setIsResponseLoading,
    privateKey,
  } = params;

  const {
    names: formParamNames,
    types: formParamTypes,
    values: formValues,
    stateMutability: formStateMutability,
    methodName: formMethodName,
    deposit,
    gas,
  } = collectionMethodFormData;

  // console.log('deposit', deposit);
  // console.log('gas', gas);

  try {
    setIsResponseLoading(true);
    const resp = await executor.get(network?.type?.id).executeMethod(
      getABIPayload({
        formAddress,
        currentCollectionMethod,
        formMethodName,
        formStateMutability,
        formParamNames,
        formParamTypes,
        formValues,
        account,
        network,
        privateKey,
        options: { deposit, gas },
      })
    );
    setIsResponseLoading(false);
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      abiResponse: resp,
    });
  } catch (e) {
    setIsResponseLoading(false);
    setCollectionMethodFormData({
      ...collectionMethodFormData,
      abiResponse: {
        message: e.message,
      },
    });
  }
};

export default sendCollectionFn;
