import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Back = ({ handleBack, title }) => {
  return (
    <div className="flex items-center justify-start h-full space-x-2 font-semibold">
      <div
        className="flex cursor-pointer"
        onClick={() => {
          handleBack(title);
        }}>
        <ArrowBackIosIcon
          sx={{ marginBottom: 1 }}
          className="mt-2 cursor-pointer"
          fontSize="small"
        />
        <div className="text-lg mt-1">{title}</div>
      </div>
    </div>
  );
};

export default Back;
