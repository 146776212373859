import map from 'lodash/map';
import { parallel, series } from 'async-es';

export default (services) => ({
  services,
  setUser(cb) {
    series(
      map(this.services, (service) => {
        return (internalCb) => {
          service.setUser.call(service, internalCb);
        };
      }),
      (err, data) => {
        cb && cb(err, data);
      }
    );
  },

  removeUser() {
    parallel(
      map(this.services, (service) => {
        return () => {
          service.removeUser.call(service);
        };
      }),
      () => {
        // no-op
      }
    );
  },

  sendEvent(key, value) {
    parallel(
      map(this.services, (service) => {
        return (internalCb) => {
          service?.sendEvent?.call?.(service, key, value);
          internalCb();
        };
      }),
      () => {
        // no-op
      }
    );
  },

  setCurrentScreen(screenName, screenClassOverride) {
    parallel(
      map(this.services, (service) => {
        return (internalCb) => {
          service.setCurrentScreen.call(
            service,
            screenName,
            screenClassOverride
          );
          internalCb();
        };
      }),
      () => {
        // no-op
      }
    );
  },
});
