import { useEffect, useState } from 'react';
// import { usePrefersDarkMode } from './usePrefersDarkMode';
// import { useSafeLocalStorage } from './useSafeLocalStorage';

export function useDarkMode() {
  // const [isEnabled, setIsEnabled] = useSafeLocalStorage('dark-mode', undefined);
  const [isEnabled, setIsEnabled] = useState(false);

  // const prefersDarkMode = usePrefersDarkMode();

  // const enabled = isEnabled === undefined ? prefersDarkMode : isEnabled;
  const enabled = isEnabled;

  useEffect(() => {
    if (window === undefined) return;
    const root = window.document.documentElement;
    root.classList.remove(enabled ? 'light' : 'dark');
    root.classList.add(enabled ? 'dark' : 'light');
  }, [enabled]);

  return [enabled, setIsEnabled];
}
