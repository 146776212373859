import { atom } from 'recoil';
import types from '../constants/Types';
import TabService, {
  getTabsDetails,
  setTabsDetails,
} from '../services/TabService';

// Active tab in workspace
export const ActiveTabState = atom({
  key: types.COLLECTION.ACTIVE_TAB_STATE,
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      onSet((newVal) => {
        setTimeout(() => {
          const TabsDetails = getTabsDetails();
          TabsDetails.currentActiveTab = newVal;
          setTabsDetails(TabsDetails);
        }, 200);
      });
    },
  ],
});

// All tabs in workspace
export const TabsState = atom({
  key: types.COLLECTION.TABS_STATE,
  default: [TabService.generateCreateAbiTabName()],
  effects: [
    ({ setSelf, onSet }) => {
      onSet((newVal) => {
        setTimeout(() => {
          const TabsDetails = getTabsDetails();
          TabsDetails.tabs = newVal;
          setTabsDetails(TabsDetails);
        }, 200);
      });
    },
  ],
});

// Track unSave tabs list
export const UnSavedCollectionMethodTabsIdState = atom({
  key: types.COLLECTION.UN_SAVE_COLLECTION_METHOD_TABS_ID_STATE,
  default: [],
});
