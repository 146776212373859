export default {
  dev: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: 'web3client.firebaseapp.com',
    projectId: 'web3client',
    storageBucket: 'web3client.appspot.com',
    messagingSenderId: '92115193097',
    appId: '1:92115193097:web:b15f06064280f2567257d8',
    measurementId: 'G-LS41PN7ZLW',
  },
  prod: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: 'web3client.firebaseapp.com',
    projectId: 'web3client',
    storageBucket: 'web3client.appspot.com',
    messagingSenderId: '92115193097',
    appId: '1:92115193097:web:b15f06064280f2567257d8',
    measurementId: 'G-LS41PN7ZLW',
  },
  beta: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: 'web3client.firebaseapp.com',
    projectId: 'web3client',
    storageBucket: 'web3client.appspot.com',
    messagingSenderId: '92115193097',
    appId: '1:92115193097:web:b15f06064280f2567257d8',
    measurementId: 'G-LS41PN7ZLW',
  },
  stage: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: 'web3client.firebaseapp.com',
    projectId: 'web3client',
    storageBucket: 'web3client.appspot.com',
    messagingSenderId: '92115193097',
    appId: '1:92115193097:web:b15f06064280f2567257d8',
    measurementId: 'G-LS41PN7ZLW',
  },
};
