import get from 'lodash/get';
import CodeGen from '../modules/CodeGen';

const CodeGenService = {
  generateCode(options) {
    return CodeGen.generateCode({
      contractAddress: get(options, 'contractAddress', ''),
      account: get(options, 'account', ''),
      abi: get(options, 'ABI', []),
      method: get(options, 'method', ''),
      values: get(options, 'values', ''),
    });
  },
};

export default CodeGenService;
