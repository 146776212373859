/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/no-autofocus */
import { TextField } from '@mui/material';
import React from 'react';

export const INPUT_VARIANTS = {
  SMALL: 'small',
};

const Input = React.forwardRef(
  (
    {
      label,
      onChange,
      value,
      name,
      placeholder,
      type = 'text',
      isDisabledLabel,
      size,
      margin,
      autoFocus,
      onKeyUp,
      helperText,
      style,
      disabled,
    },
    ref
  ) => {
    return (
      <div className={`w-full flex flex-col ${margin} mb-4`} style={style}>
        {!isDisabledLabel && (
          <div className="text-sm mb-1 font-medium">{label}</div>
        )}
        <TextField
          autoFocus={autoFocus}
          inputRef={ref}
          variant="outlined"
          type={type}
          name={name}
          onChange={onChange}
          onKeyUp={onKeyUp}
          value={value}
          placeholder={placeholder}
          sx={{
            '& .MuiInputBase-input': {
              boxShadow: 'none',
            },
          }}
          size="small"
          InputProps={{
            sx: {
              height: size === INPUT_VARIANTS?.SMALL ? '20px' : 'auto',
            },
          }}
          helperText={helperText}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default Input;
