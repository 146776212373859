import { useRecoilState, useRecoilValue } from 'recoil';
import CodeEditor from '../../../../components/CodeEditor';
import { IsResponseLoadingState } from '../../../../states/LoadingState';
import { AppSettings } from '../../../../states/SettingsState';

const ResponseView = ({ collectionMethodFormData }) => {
  const [settings] = useRecoilState(AppSettings);

  const isResponseLoading = useRecoilValue(IsResponseLoadingState);

  const { abiResponse: formAbiResponse } = collectionMethodFormData;

  return (
    <div className="p-2 flex flex-col w-full h-full">
      <div className="font-semibold mb-4 mt-2">Response</div>
      {isResponseLoading ? (
        <div className="text-center mt-12 font-bold text-lg">
          Sending request...
        </div>
      ) : (
        <>
          {formAbiResponse === '' ? (
            <div className="text-center md:mt-12 mt-5 text-lg ">
              Press send button to view the response
            </div>
          ) : (
            <CodeEditor
              language={
                typeof formAbiResponse === 'object' ? 'json' : 'javascript'
              }
              value={JSON.stringify(formAbiResponse, null, '\t')}
              options={{
                readOnly: true,
                autoIndent: true,
                formatOnPaste: true,
                formatOnType: true,
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
              }}
              mode={settings.theme}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ResponseView;
