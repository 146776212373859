import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import MoreOPtionsPopOver from './MoreOPtionsPopOver';

const MoreOptions = ({ conditionTabClose }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation?.();
    setOpen(null);
  };

  return (
    <>
      <Tooltip title="More Options">
        <MoreHorizIcon
          fontSize="small"
          ref={anchorRef}
          onClick={handleOpen}
          className="cursor-pointer"
        />
      </Tooltip>
      <MoreOPtionsPopOver
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        conditionTabClose={conditionTabClose}
      />
    </>
  );
};

export default MoreOptions;
