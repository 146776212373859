import isEmpty from 'lodash/isEmpty';
import StorageConstants, {
  getStorageConstants,
} from '../../constants/StorageConstants';
import { uuidv4 } from '../../utils/UUIDV4';
import ObjectStorage from '../ObjectStorageService';

export default {
  boot(cb) {
    // Create or Set user;

    const userInfo = ObjectStorage.getItem(
      getStorageConstants(StorageConstants.USER)
    );
    if (isEmpty(userInfo)) {
      ObjectStorage.setItem(getStorageConstants(StorageConstants.USER), {
        id: uuidv4(),
      });
    }
    cb && cb();
  },
};
