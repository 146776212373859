import { Copyright } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import packageJson from '../../package.json';
import Accounts from '../sections/Accounts';

export default function MainLayout() {
  return (
    <>
      <nav className="bg-primary border-b border-secondary text-primary">
        <Accounts />
      </nav>
      <main className="relative w-full h-full">
        <Outlet />
      </main>
      <div className="rounded-none bg-primary flex border-t border-secondary">
        <div className="text-xs text-primary flex !items-center p-1">
          <div className="ml-2 mr-3">v{packageJson.version}</div>
          <Copyright fontSize="18" />
          <span className="ml-1">Web3Client.app</span>
        </div>
      </div>
    </>
  );
}
