import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';

import get from 'lodash/get';
import map from 'lodash/map';
import { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { number, object, string } from 'yup';
import { uuidv4 } from '../../../../utils/UUIDV4';
import {
  IMPORT_SOURCE,
  TEMPLATE_ID,
  TEMPLATE_META,
  W3C_ABI_VERSION,
} from '../../../../constants/AppConstants';
import ERC20 from '../../../../constants/presets/ERC20.json';
import ERC721 from '../../../../constants/presets/ERC721.json';
import ERC1155 from '../../../../constants/presets/ERC1155.json';
import Back from './Back';
import Input from '../../../../components/Input';
import NetworkSelector from '../../../NetworkSelector';

const SelectFromTemplate = ({ handleBack, onSubmit, title }) => {
  const [networkSelectorData, setNetworkSelectorData] = useState({
    selectedChain: null,
    chainName: '',
    chainId: '',
    rpc: '',
    network: null,
    type: null,
  });
  const {
    selectedChain,
    chainName,
    chainId,
    rpc,
    warningMsg,
    isCustomAdd,
    network,
    type,
  } = networkSelectorData;

  function resetNetworkState() {
    setNetworkSelectorData({
      selectedChain: null,
      chainName: '',
      chainId: '',
      rpc: '',
      network: null,
      type: null,
    });
  }

  const templateMap = {
    [TEMPLATE_ID.ERC20]: ERC20,
    [TEMPLATE_ID.ERC721]: ERC721,
    [TEMPLATE_ID.ERC1155]: ERC1155,
  };
  const [abiName, setAbiName] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleTemplateChange = (_, template) => {
    setSelectedTemplate(template || '');
  };

  function onChangeABIName(e) {
    setAbiName(e.target.value);
  }
  function onChangeContractAddress(e) {
    setContractAddress(e.target.value);
  }

  function createFromTemplate(payload) {
    const { name, abi, id, parentId, address, template, network } = payload;
    onSubmit(
      {
        abi,
        id,
        parentId,
        meta: {
          name,
          version: W3C_ABI_VERSION,
          chainList: [
            {
              name: network?.chainName,
              address,
              isActive: true,
              network: {
                chainId: network?.chainId,
                rpc: network?.rpc,
                chainName: network?.chainName,
                type: network?.type,
                network: network?.network,
              },
            },
          ],
          source: IMPORT_SOURCE.TEMPLATE,
          template,
        },
      },
      !warningMsg && isCustomAdd
    );
    resetNetworkState();
  }
  const generateInputIds = (inputsArray) => {
    return map(inputsArray, (obj) => {
      return {
        ...obj,
        id: uuidv4(),
      };
    });
  };
  function generateId(abiJSON) {
    return map(abiJSON, (_a) => ({
      ..._a,
      id: uuidv4(),
      inputs: generateInputIds(_a.inputs),
    }));
  }

  function handleSubmit() {
    const selectedChainId =
      get(selectedChain, 'chainId') ?? parseInt(chainId, 10);
    const selectedRPC = get(selectedChain, 'rpc.0') || rpc;
    const selectedChainName = get(selectedChain, 'name') || chainName;
    const selectedType = get(selectedChain, 'type') || type;
    const selectedNetworkStatus = get(selectedChain, 'network') || network;

    const getTypeBySchema = () => {
      if (selectedType?.id === 'near') {
        return {
          network: string().required("Network should'nt be empty"),
        };
      }
      return {
        chainId: number().nullable().required("Chain should'nt be empty"),
      };
    };

    const schema = object().shape({
      name: string().required("Collection Name Should'nt be empty"),
      address: string().required("Contract ID Should'nt be empty"),
      template: string().required('Select template'),
      rpc: string().required("RPC URL should'nt be empty"),
      ...getTypeBySchema(),
      chainName: string().required("Chain name should'nt be empty"),
    });

    const getTypeByDefaultPayload = () => {
      if (selectedType?.id === 'near') {
        return {
          network: selectedNetworkStatus?.id,
        };
      }
      return {
        chainId: selectedChainId || null,
      };
    };

    const defaultPayload = {
      name: abiName,
      address: contractAddress,
      rpc: selectedRPC,
      ...getTypeByDefaultPayload(),
      chainName: selectedChainName,
      template: selectedTemplate,
    };

    const formatDefaultPayload = {
      name: abiName,
      address: contractAddress,
      network: {
        chainId: selectedChainId,
        rpc: selectedRPC,
        chainName: selectedChainName,
        type: selectedType,
        network: selectedNetworkStatus,
      },
      id: uuidv4(),
      parentId: '',
      template: selectedTemplate,
    };

    const toastId = toast.loading('Loading...');
    schema
      .validate(defaultPayload) // todo:  Validate ABI json, Files
      .then(() => {
        const json = templateMap[selectedTemplate];
        createFromTemplate({
          ...formatDefaultPayload,
          abi: generateId(json),
        });
        toast.success('Created ABI', {
          id: toastId,
        });
      })
      .catch((err) => {
        toast.error(err?.message, {
          id: toastId,
        });
      });
  }

  useEffect(() => {
    setAbiName('');
    setContractAddress('');
    setSelectedTemplate('');
  }, []);

  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit(event);
        }
      }}>
      <Back handleBack={handleBack} title={title} />
      <div className="flex flex-col mt-5 w-11/12 mx-auto">
        <div className="w-full flex flex-col mb-4">
          <div className="text-sm mb-2">Standards</div>
          <div className="flex w-full">
            <ToggleButtonGroup
              color="primary"
              value={selectedTemplate}
              exclusive
              onChange={handleTemplateChange}
              aria-label="Platform">
              {map(TEMPLATE_META, (temp) => (
                <ToggleButton value={temp?.id}>{temp?.label}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </div>
        <Input
          label="ABI Name"
          onChange={onChangeABIName}
          value={abiName}
          name="abi-name"
        />

        <Input
          label="Contract ID"
          onChange={onChangeContractAddress}
          value={contractAddress}
          name="contract-address"
        />

        <NetworkSelector
          networkSelectorData={networkSelectorData}
          setNetworkSelectorData={setNetworkSelectorData}
        />

        <Button
          variant="contained"
          className="w-20 !mt-4"
          onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SelectFromTemplate;
