import { Button, Divider, Tooltip } from '@mui/material';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import reverse from 'lodash/reverse';
import get from 'lodash/get';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import HistoryService from '../../../../services/HistoryService';
import getClone from '../../../../utils/getClone';
import { HistoryMethodListState } from '../../../../states/HistoryState';
import MethodListItem from './MethodListItem';
import AllHistoryMethodModal from '../../../modals/AllHistoryMethodModal';
import useTabs from '../../../../hooks/useTabs';

const History = () => {
  const HistoryMethodList = useRecoilValue(HistoryMethodListState);
  const cloneHistoryMethods = getClone(HistoryMethodList);
  const reverseHistoryMethodList = reverse(cloneHistoryMethods);
  const [modalOpen, setModalOpen] = useState(false);

  const { removeTabsByMethodIds } = useTabs();

  const deleteAllHistory = () => {
    setModalOpen(true);
  };

  /**
   * delete Method
   * @param {*} e
   */
  const triggerDeleteAllMethod = (e) => {
    removeTabsByMethodIds(
      map(HistoryMethodList, (HistoryMethod) => get(HistoryMethod, 'method.id'))
    );
    HistoryService.deleteAllHistory();
    setModalOpen(false);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="flex justify-between m-1.5">
        <div className="text-lg ml-3 flex justify-center items-center font-bold">
          History
        </div>
        <Tooltip title="Clear All History">
          <Button
            size="small"
            className="!mr-5"
            variant="contained"
            onClick={deleteAllHistory}
            disabled={isEmpty(reverseHistoryMethodList)}>
            Clear All
          </Button>
        </Tooltip>
      </div>
      <Divider />
      <div
        className="mt-2 overflow-auto"
        style={{ height: 'calc(100vh - 165px)' }}>
        {map(reverseHistoryMethodList, (historyMethod, index) => {
          return (
            <MethodListItem
              reverseOrder={true}
              key={index}
              historyMethod={historyMethod}
            />
          );
        })}
      </div>
      <AllHistoryMethodModal
        isOpen={modalOpen}
        onClose={handleClose}
        onSubmit={triggerDeleteAllMethod}
      />
    </>
  );
};

export default History;
