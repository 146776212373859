import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRecoilState } from 'recoil';
import { MobileDrawerOpenState } from '../states/ResponsiveViewState';

const Accounts = () => {
  const matches = useMediaQuery('(min-width:1160px)');
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );

  // toggle mobile drawer
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <div className="flex justify-between items-center pl-5 lg:pl-1.5 pr-1.5 h-14">
      <div className="w-44">
        <img
          className="h-full w-full"
          src="/web3client logo.png"
          alt="Web3client"
        />
      </div>
      <div className="flex ml-auto">
        {!matches ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            className="!ml-2">
            <MenuIcon onClick={handleDrawerToggle} />
          </IconButton>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Accounts;
