const BASE = 'web3C_';
const StorageConstants = {
  ETH_CHAINS: 'web3C_eth_chains',
  COLLECTIONS: 'web3C_collections',
  TAB_DETAILS: 'web3C_tabDetails',
  CUSTOM_NETWORKS: 'web3C_customNetworks',
  TELEMETRY: 'telemetry',
  USER: 'user',
};
/**
 * Use this to get storage key
 * @param {*} key
 * @returns
 */
export const getStorageConstants = (key) => {
  return `${BASE}${key || 'unknown'}`;
};

export default StorageConstants;
