import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import {
  STATE_MUTUABLITIY,
  STATE_MUTUABLITIY_COLORS,
} from '../../../../constants/AppConstants';

const ABIStateMutuability = ({ stateMutability }) => {
  return (
    <Tooltip placement="right" title={stateMutability}>
      <Chip
        label={STATE_MUTUABLITIY[stateMutability || 'default'] || 'NA'}
        color={STATE_MUTUABLITIY_COLORS[stateMutability || 'default']}
        variant="outlined"
        size="small"
        sx={{
          height: '16px',
          fontSize: '0.55rem',
          fontWeight: 500,
          width: '3rem',
        }}
      />
    </Tooltip>
  );
};
export default ABIStateMutuability;
