import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import StorageConstants, {
  getStorageConstants,
} from '../../../../constants/StorageConstants';
import ObjectStorage from '../../../ObjectStorage';

export default {
  _getUserInfo() {
    const userInfo = ObjectStorage.getItem(
      getStorageConstants(StorageConstants.USER)
    );
    if (isEmpty(userInfo)) {
      console.error(
        'BaseTelemetryController~_getUserInfo: Error in parsing user data'
      );
      return { id: '0' };
    }
    return {
      id: get(userInfo, 'id', '0'),
    };
  },
  boot(cb) {
    console.log(
      `BaseTelemetry~Override the initiate in ${this.type} telemetry service`
    );
    cb && cb(null, this);
  },

  setUser(cb) {
    console.log(
      `BaseTelemetry~Override the setUser state in ${this.type} telemetry service`
    );
    cb && cb();
  },

  removeUser(cb) {
    console.log(
      `BaseTelemetry~Override the removeUser in ${this.type} telemetry service`
    );
    cb && cb();
  },

  sendEvent(key, value) {
    console.log(
      `BaseTelemetry~Override the sendEvent in ${this.type} telemetry service`,
      {
        key,
        value,
      }
    );
  },

  setCurrentScreen(screenName, screenClassOverride) {
    console.log(
      `BaseTelemetry~Override the setCurrentScreen in ${this.type} telemetry service`,
      {
        screenName,
        screenClassOverride,
      }
    );
  },
};
