import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MenuItem from '@mui/material/MenuItem';
import ArticleIcon from '@mui/icons-material/Article';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import set from 'lodash/set';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import MenuPopover from '../../../../components/MenuPopover';
import { IMPORT_TYPES } from '../../../../constants/AppConstants';
import { TelemetryEvents } from '../../../../constants/EventsConstants';
import NewMethod from '../../../../constants/NewMethod.json';
import exportToJson from '../../../../utils/exportToJson';
import { uuidv4 } from '../../../../utils/UUIDV4';
import CollectionService from '../../../../services/CollectionService';
import TelemetryService from '../../../../services/TelemetryService';
import { CollectionListState } from '../../../../states/CollectionState';
import createNewMethod from './createNewMethod';
import DeleteConfirmationModal from '../../../modals/DeleteConfirmationModal';
import IconWrapper from '../../../../components/IconWrapper';
import useTabs from '../../../../hooks/useTabs';
import AutoGenerateDocsABI from '../../../Drawer/AutoGenerateDocsABI';

const ABIListPopOver = ({
  open,
  anchorEl,
  onClose,
  _c,
  setIsRenameFieldOpen,
  renameInputRef,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  // all ABI list
  const collectionsState = useRecoilValue(CollectionListState);

  const { setSelectedTab, removeTabByAbiId } = useTabs();

  /**
   * clone ABI
   * @param {object} e
   */
  const triggerClone = (e) => {
    e?.stopPropagation?.();
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_CLONE);
    const copyCollection = JSON.parse(JSON.stringify(_c));
    set(
      copyCollection,
      'meta.name',
      `${get(copyCollection, 'meta.name')} - copy`
    );
    set(copyCollection, 'id', uuidv4());
    forEach(get(copyCollection, 'abi'), (list) => {
      set(list, 'id', uuidv4());
    });
    CollectionService.updateCollectionList(copyCollection);
    onClose();
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_NEW_ABI, {
      import_type: IMPORT_TYPES.CLONE,
    });
  };

  /**
   * export ABI
   * @param {object} e
   */
  const triggerExport = (e) => {
    e?.stopPropagation?.();
    exportToJson({ data: _c, fileName: `${_c.meta.name}.w3c.json` });
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_EXPORT);
    onClose();
  };

  /**
   * trigger rename ABI flow
   * @param {object} e
   */
  const triggerRename = (e) => {
    e?.stopPropagation?.();
    setIsRenameFieldOpen(true);
    setTimeout(() => {
      renameInputRef.current?.focus?.();
    }, 100);
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_RENAME);
    onClose();
  };

  /**
   * trigger delete ABI flow
   * @param {object} e
   */
  const triggerDelete = (e) => {
    e?.stopPropagation?.();
    setModalOpen(true);
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_DELETE);
    onClose();
  };

  /**
   * delete ABI
   * @param {object} e
   */
  const deleteCollection = (e) => {
    e?.stopPropagation?.();
    setModalOpen(false);
    const filterCollections = filter(collectionsState, (list) => {
      return get(_c, 'id') !== get(list, 'id');
    });
    removeTabByAbiId(get(_c, 'id'));
    CollectionService.replaceCollections(filterCollections);
    TelemetryService.sendEvent(TelemetryEvents.DELETE_SUCCESS);
  };

  /**
   *  Add New Method in ABI
   *  @param {object} e
   */
  const addNewMethod = (e) => {
    e?.stopPropagation?.();
    createNewMethod({
      NewMethod,
      _c,
      collectionsState,
      setSelectedTab,
    });
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_NEW_METHOD);
    onClose();
  };

  const handleClose = (e) => {
    e?.stopPropagation?.();
    setModalOpen(false);
    TelemetryService.sendEvent(TelemetryEvents.DELETE_CANCEL);
  };

  return (
    <div>
      <MenuPopover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.5,

          '& .MuiMenuItem-root': {
            borderRadius: 0.75,
            minHeight: '30px',
            fontSize: '0.65rem',
          },
        }}>
        <MenuItem
          className="min-h-[20px]"
          sx={{
            height: '1.9rem',
            '&.css-xgn4u4-MuiButtonBase-root-MuiMenuItem-root': {
              minHeight: '50px',
            },
          }}
          onClick={addNewMethod}>
          <IconWrapper>
            <AddIcon
              sx={{ mr: 1, opacity: '0.7' }}
              className="!w-full !h-full"
            />
          </IconWrapper>
          <span className="ml-2"> New Method</span>
        </MenuItem>
        <MenuItem
          sx={{
            height: '1.9rem',
          }}
          onClick={triggerRename}>
          <IconWrapper>
            <CreateIcon
              sx={{ mr: 1, opacity: '0.7' }}
              className="!w-full !h-full"
            />
          </IconWrapper>
          <span className="ml-2"> Rename</span>
        </MenuItem>
        <MenuItem sx={{ height: '1.9rem' }} onClick={triggerClone}>
          <IconWrapper>
            <FileCopyIcon
              sx={{ mr: 1, opacity: '0.7' }}
              className="!w-full !h-full"
            />
          </IconWrapper>
          <span className="ml-2"> Clone</span>
        </MenuItem>

        <MenuItem
          className="min-h-[20px] flex space-x-2"
          sx={{
            height: '1.9rem',

            '&.css-xgn4u4-MuiButtonBase-root-MuiMenuItem-root': {
              minHeight: '50px',
            },
          }}
          onClick={(event) => {
            event?.stopPropagation?.();
            onClose();
            setOpenDrawer(true);
          }}>
          <IconWrapper>
            <ArticleIcon sx={{ opacity: '0.7' }} className="!w-full !h-full" />
          </IconWrapper>
          <span>Documentation</span>
        </MenuItem>

        <MenuItem sx={{ height: '1.9rem' }} onClick={triggerExport}>
          <IconWrapper>
            <FileDownloadIcon
              sx={{ mr: 1, opacity: '0.7' }}
              className="!w-full !h-full"
            />
          </IconWrapper>
          <span className="ml-2"> Export</span>
        </MenuItem>
        <MenuItem
          sx={{
            height: '1.9rem',
            '&:hover': {
              bgcolor: 'error.main',
              color: 'error.contrastText',
            },
          }}
          onClick={triggerDelete}>
          <IconWrapper>
            <DeleteIcon
              sx={{ mr: 1, opacity: '0.7' }}
              className="!w-full !h-full"
            />
          </IconWrapper>
          <span className="ml-2"> Delete </span>
        </MenuItem>
      </MenuPopover>
      <DeleteConfirmationModal
        title={get(_c, 'meta.name')}
        isOpen={modalOpen}
        onClose={handleClose}
        onSubmit={deleteCollection}
      />
      <AutoGenerateDocsABI
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        activeCollection={_c}
      />
    </div>
  );
};
export default ABIListPopOver;
