import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import toast from 'react-hot-toast';
import { IconButton, Tooltip } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Modal } from '../../components/Modal';
import { IMPORT_TYPES } from '../../constants/AppConstants';
import SelectField from '../../components/SelectField';
import { CollectionListState } from '../../states/CollectionState';
import { UnSavedCollectionMethodTabsIdState } from '../../states/TabStates';
import NewABI from '../AppHome/WorkSpace/NewTabDetails/NewABI';
import IconWrapper from '../../components/IconWrapper';
import CollectionService from '../../services/CollectionService';
import TelemetryService from '../../services/TelemetryService';
import { TelemetryEvents } from '../../constants/EventsConstants';
import useTabs from '../../hooks/useTabs';
import TabService from '../../services/TabService';
import Messages from '../../constants/Messages';
import { HistoryMethodListState } from '../../states/HistoryState';

const CreateOrSelectAbiModal = ({ collectionMethodFormData }) => {
  const [selectedAbi, setSelectedAbi] = useState(null);
  const [createOrSelectModalOpen, setCreateOrSelectModalOpen] = useState(false);

  const [ABIName, setABIName] = useState('');

  // all ABI list
  const collectionList = useRecoilValue(CollectionListState);

  const { selectedTab, replaceTabByMethodId } = useTabs();

  // selected ABI
  const currentCollection = CollectionService.findCollection(selectedTab);

  // track unSave method tab ids
  const [unSavedCollectionMethodTabsId, setUnSavedCollectionMethodTabsId] =
    useRecoilState(UnSavedCollectionMethodTabsIdState);

  const allHistory = useRecoilValue(HistoryMethodListState);

  const { contractAddress: formAddress, tempABIs } = collectionMethodFormData;

  const isNewMethod = TabService.isUntitledTab(selectedTab);
  const isHistoryTab = TabService.isHistoryTab(selectedTab);

  const isNewMethodOrHistoryTab = isNewMethod || isHistoryTab;

  const handleCloseSelectABI = (e) => {
    e?.stopPropagation?.();
    setCreateOrSelectModalOpen(false);
    setSelectedAbi(null);
  };

  /**
   * save method
   */
  const triggerSaveCollectionFn = () => {
    TelemetryService.sendEvent(TelemetryEvents.ON_CLICK_SAVE);

    // convert form list to method
    const newCollectionMethod = CollectionService.convertFormToMethod({
      formData: collectionMethodFormData,
      selectedTab,
      isSaveFlow: true,
    });

    const newCollectionFns = map(get(currentCollection, 'abi'), (list) => {
      if (get(list, 'id') === get(newCollectionMethod, 'id'))
        return newCollectionMethod;
      return list;
    });

    // change ABI details
    const collection = {
      ...(currentCollection || []),
      abi: newCollectionFns,
    };

    // replace ABI list in local storage
    // ----------------------------------------------------------------
    const replaceCollection = map(collectionList, (list) => {
      if (get(list, 'id') === get(collection, 'id')) {
        return collection;
      }
      return list;
    });
    CollectionService.replaceCollections(replaceCollection);
    // ----------------------------------------------------------------

    toast.success(Messages.INFO.SAVED_METHOD);

    // remove unSave tab id from  unSave list
    CollectionService.removeUnSavedCollectionMethodTabsId({
      setUnSavedCollectionMethodTabsId,
      unSavedCollectionMethodTabsId,
      tab: selectedTab,
    });
  };

  /**
   * add method into ABI
   * @param {object} methods
   * @returns {object} methods
   */
  const addDataInCollection = (data) => {
    const addMethod = CollectionService.convertFormToMethod({
      formData: collectionMethodFormData,
      selectedTab,
      allHistory,
    });
    data?.abi?.push?.(addMethod);
    replaceTabByMethodId(
      TabService.getCollectionMethodId(selectedTab),
      `${get(data, 'id')}$${get(addMethod, 'id')}`
    );
    return data;
  };

  /**
   * add method into new ABI or exist ABI
   * @param {object}
   */
  const createOrSelectFormOnSubmit = (payload) => {
    // add method into  exist ABI
    if (selectedAbi) {
      const replaceCollection = addDataInCollection(
        CollectionService.findCollection(get(selectedAbi, 'id'))
      );
      const replaceCollections = map(collectionList, (_c) => {
        if (
          get(_c, 'id') ===
          CollectionService.findCollection(get(selectedAbi, 'id'))?.id
        ) {
          return replaceCollection;
        }
        return _c;
      });

      // add method into new ABI
      CollectionService.replaceCollections(replaceCollections);
      setCreateOrSelectModalOpen(false);
      setSelectedAbi(null);
      return;
    }

    const replaceCollection = addDataInCollection(payload);
    CollectionService.updateCollectionList(replaceCollection);
    setCreateOrSelectModalOpen(false);
  };

  const getABIName = (name) => {
    setABIName(name);
  };

  return (
    <>
      <Tooltip title="Save">
        <IconButton
          color="primary"
          aria-label="save method"
          onClick={
            !isNewMethodOrHistoryTab
              ? triggerSaveCollectionFn
              : () => {
                  // execute there are no method name
                  if (!get(collectionMethodFormData, 'methodName')) {
                    toast.error(Messages.INFO.METHOD_NAME_IS_REQUIRED);
                    return;
                  }
                  if (isEmpty(get(collectionMethodFormData, 'tempABIs'))) {
                    toast.error(Messages.INFO.ADD_CONTRACT);
                    return;
                  }
                  setCreateOrSelectModalOpen(true);
                }
          }
          disabled={
            !(
              unSavedCollectionMethodTabsId?.includes?.(selectedTab) ||
              isNewMethodOrHistoryTab
            )
          }>
          <IconWrapper>
            <SaveIcon className="!w-full !h-full" />
          </IconWrapper>
        </IconButton>
      </Tooltip>
      <Modal
        open={createOrSelectModalOpen}
        submit={createOrSelectFormOnSubmit}
        handleClose={handleCloseSelectABI}
        isActionRequired={false}>
        <NewABI
          handleBack={handleCloseSelectABI}
          onSubmit={createOrSelectFormOnSubmit}
          title={IMPORT_TYPES?.CREATE_OR_SELECT_ABI}
          isSelectedAbi={selectedAbi}
          formAddress={formAddress}
          tempABIs={tempABIs}
          isCreateOrSelectAbiFlow={true}
          getABIName={getABIName}>
          <div className="my-4">
            <div className="text-center text-lg font-bold my-4">OR</div>
            <div className="text-sm mb-4">Select ABI</div>
            <SelectField
              value={selectedAbi}
              onChange={(e, newInputValue) => {
                setSelectedAbi(newInputValue);
              }}
              options={map(collectionList, (list) => ({
                label: get(list, 'meta.name'),
                id: get(list, 'id'),
              }))}
              isDisabledLabel
              disabled={ABIName}
            />
          </div>
        </NewABI>
        <div className="mb-12"></div>
      </Modal>
    </>
  );
};
export default CreateOrSelectAbiModal;
