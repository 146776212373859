import Chains from '../../constants/Chains';

const ChainService = {
  getChains() {
    return new Promise((resolve) => {
      resolve(Chains);
    });
  },
};

export default ChainService;
