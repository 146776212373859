import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import { useNavigate } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import { useRecoilState, useSetRecoilState } from 'recoil';
import IconWrapper from '../components/IconWrapper';
import { QUERY } from '../constants/AppConstants';
import RouterConstants from '../constants/RouterConstants';
import { uuidv4 } from '../utils/UUIDV4';
import useUpdateActiveTab from '../hooks/useUpdateActiveTab';
import useSwitchMetamaskChain from '../hooks/useSwitchMetamaskChain';
import SidePannel from '../sections/AppHome/SidePannel';
import WorkSpace from '../sections/AppHome/WorkSpace';
import URLExplorer from '../sections/Dialogs/URLExplorer';
import ABIService from '../services/API/ABIService';
import CollectionService from '../services/CollectionService';
import {
  subscribeToCollectionUpdate,
  subscribeToHistoryUpdate,
} from '../services/EventService';
import HistoryService from '../services/HistoryService';
import { CollectionListState } from '../states/CollectionState';
import { MobileDrawerOpenState } from '../states/ResponsiveViewState';
import { HistoryMethodListState } from '../states/HistoryState';
import { IsShareLoadingState } from '../states/LoadingState';
import { AppSettings } from '../states/SettingsState';

const AppHome = () => {
  // handle metamask chain switching
  useSwitchMetamaskChain();

  // update active tab in react-headless-tabs package useTab hooks
  useUpdateActiveTab();

  // set ABI List in recoil State
  const setCollectionState = useSetRecoilState(CollectionListState);

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [modalState, setModalState] = useState(false);
  const [settings] = useRecoilState(AppSettings);
  const matches = useMediaQuery('(min-width:1160px)');

  // mobile screen drawer state
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );

  // set loading for share ABI
  const setIsShareLoading = useSetRecoilState(IsShareLoadingState);

  // history and ABI switching tab State
  const [selectedSidePannelTab, setSelectedSidePannelTab] = useState(0);

  // all history list
  const setHistoryMethodList = useSetRecoilState(HistoryMethodListState);

  // toggle mobile drawer
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  function handleCollectionCreation(shareABI) {
    const copyCollection = JSON.parse(JSON.stringify(shareABI));
    set(copyCollection, 'id', uuidv4());
    forEach(get(copyCollection, 'abi'), (list) => {
      set(list, 'id', uuidv4());
    });

    CollectionService.updateCollectionList(copyCollection);
    updateCollection();
    hideURLExplorer();
    navigateToOrigin();
  }

  /**
   * set ABI-list which is from from local storage into recoil state
   */
  function updateCollection() {
    setCollectionState(CollectionService.getCollection());
  }

  /**
   * set history-list which is from from local storage into recoil state
   */
  function updateHistory() {
    HistoryService.getAllHistory((result) => {
      setHistoryMethodList(result);
    });
  }

  function navigateToOrigin() {
    if (window.location.href.includes(QUERY.SHARE_QUERY)) {
      navigate(`${RouterConstants.APP.EVM_HOME}`, { replace: true });
    }
  }

  function importFromLink() {
    if (window.location.href.includes(QUERY.SHARE_QUERY)) {
      const queryParameters = new URLSearchParams(window.location.search);
      const web3token = queryParameters.get(QUERY.SHARE_QUERY);
      getABICollection(web3token);
    }
  }
  const getABICollection = async (d) => {
    setIsShareLoading(true);
    try {
      const response = await ABIService.getCollection(d);
      const { abi, meta, token } = get(response, 'data');
      const defaultPayload = {
        meta,
        id: uuidv4(),
        parentId: token,
        abi,
      };
      // to be set Collection below
      setData(defaultPayload);
      showURLExplorer();
      setIsShareLoading(false);
    } catch (e) {
      setIsShareLoading(false);
      toast.error('Invalid Link');
    }
  };
  function showURLExplorer() {
    setModalState(true);
  }
  function hideURLExplorer() {
    setModalState(false);
  }

  // subscribe to collection update
  //----------------------------------------------------------------
  useEffect(() => {
    updateCollection();
    const unSubcribeCollectionUpdate = subscribeToCollectionUpdate(() => {
      updateCollection();
    });
    return () => {
      unSubcribeCollectionUpdate && unSubcribeCollectionUpdate();
    };
  }, []);
  //----------------------------------------------------------------

  // subscribe to history update
  //----------------------------------------------------------------
  useEffect(() => {
    updateHistory();
    const unSubscribeToHistoryUpdate = subscribeToHistoryUpdate(() => {
      updateHistory();
    });
    return () => {
      unSubscribeToHistoryUpdate && unSubscribeToHistoryUpdate();
    };
  }, []);
  //----------------------------------------------------------------

  useEffect(() => {
    importFromLink();
  }, []);

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event, selectedTab) => {
    setSelectedSidePannelTab(selectedTab);
  };

  const tabs = (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={selectedSidePannelTab}
      onChange={handleChange}
      sx={{
        borderRight: 1,
        borderColor: 'divider',
        zIndex: 999,
        height: 'calc(100vh - 72px)',
        '& .MuiButtonBase-root': {
          minWidth: {
            xs: 0,
            md: '20px',
          },
        },
      }}>
      <Tab
        icon={
          <Tooltip title="ABIs">
            <IconWrapper>
              <FolderCopyIcon className="!w-full !h-full" />
            </IconWrapper>
          </Tooltip>
        }
        {...a11yProps(0)}
      />
      <Tab
        icon={
          <Tooltip title="History">
            <IconWrapper>
              <AccessTimeIcon className="!w-full !h-full" />
            </IconWrapper>
          </Tooltip>
        }
        {...a11yProps(1)}
      />
    </Tabs>
  );

  return (
    <div className="flex">
      {matches && tabs}
      <div className="flex overflow-hidden">
        <div className="flex absolute h-full overflow-hidden min-h-full flex-col top-0 min-lg:left-14 min-lg:w-[96.6%] w-full">
          {matches ? (
            <SplitPane
              split="vertical"
              primary="first"
              defaultSize={350}
              maxSize={470}
              minSize={350}
              resizerStyle={{
                background: '#d7d9d7',
                width: '1px',
                position: 'relative',
                float: 'right',
                cursor: 'col-resize',
                height: '100%',
                opacity: settings.theme === 'dark' ? 0.25 : 0.9,
                marginLeft: '0.3px',
              }}>
              <SidePannel selectedSidePannelTab={selectedSidePannelTab} />
              <WorkSpace />
            </SplitPane>
          ) : (
            <>
              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileDrawerOpen}
                ModalProps={{
                  keepMounted: true,
                }}>
                <div className="flex-1 item-center pt-1 pb-12 overflow-hidden md:w-[90vw] w-[80vw]">
                  <div className="pl-2 pt-5 pb-3 flex justify-between">
                    <img
                      className="h-full w-40"
                      src="/web3client logo.png"
                      alt="Web3client"
                    />
                    <div className="mb-4 mt-0 mr-3">
                      <CloseIcon
                        fontSize="large"
                        onClick={handleDrawerToggle}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    {tabs}
                    <SidePannel selectedSidePannelTab={selectedSidePannelTab} />
                  </div>
                </div>
              </Drawer>
              <WorkSpace />
            </>
          )}
          <URLExplorer
            isOpen={modalState}
            handleClose={hideURLExplorer}
            onSubmit={handleCollectionCreation}
            payload={data}
          />
        </div>
      </div>
    </div>
  );
};

export default AppHome;
