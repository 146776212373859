const ether = `import { parse } from '@ethersproject/transactions';
import { ethers } from 'ethers';
import get from 'lodash/get';

const _getEtheriumProvider = () => {
  return new ethers.providers.Web3Provider(window.ethereum);
};

const _getABInterface = (abi, method) => {
  const ethInterface = new ethers.utils.Interface(abi);

  return (
    ethInterface.fragments.filter((fragment) => {
      return fragment.name === method;
    })[0] || {}
  );
};

const _getSigner = ({ provider, account }) => {
  return provider.getSigner(account);
};

const _isMethodInterface = (methodInterface) => {
  const type = get(methodInterface, 'type');
  return type === 'function';
};

const _parseUInt256 = (data) => {
  return ethers.utils.parseUnits(data, 18);
};

const _parseAddress = (data) => {
  return ethers.utils.getAddress(data);
};

const parseArguments = (type, value) => {
  if (type === 'uint256') {
    return _parseUInt256(value);
  }
  if (type === 'address') {
    return _parseAddress(value);
  } else if (type === 'uint256[]') {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return value.map((v) => {
      return parseArguments('uint256', v);
    });
  } else if (type === 'address[]') {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return value.map((v) => {
      return parseArguments('address', v);
    });
  }
  return value;
};

const _getArguments = ({ methodInterface, values }) => {
  const inputs = get(methodInterface, 'inputs', []);
  return inputs.map((inp) => {
    const name = inp.name;
    const type = inp.type;
    return parseArguments(type, values[name]);
  });
};

function executeMethod({ contractAddress, ABI, method, values, account }) {
    return new Promise((res, rej) => {
      const provider = _getEtheriumProvider();
      const signer = _getSigner({ provider, account });
      const interfaceToExecute = _getABInterface(ABI, method);
      const isMethod = _isMethodInterface(interfaceToExecute);
      if (isMethod) {
        const methodArguments = _getArguments({
          methodInterface: interfaceToExecute,
          values
        });
        const Contract = new ethers.Contract(contractAddress, ABI, signer);
        res(Contract[method](...methodArguments));
      } else {
        rej();
      }
    });
  }

executeMethod($$PARAMS$$)
`;

export default ether;
