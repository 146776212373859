import { Button } from '@mui/material';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { number, object, string } from 'yup';
import { uuidv4 } from '../../../../utils/UUIDV4';
import // getAllEVMChains,
// getChainName,
'../../../../services/ChainService';
import Back from './Back';
import Input from '../../../../components/Input';
import {
  IMPORT_SOURCE,
  W3C_ABI_VERSION,
} from '../../../../constants/AppConstants';
import NetworkSelector from '../../../NetworkSelector';

const NewABI = ({
  handleBack,
  onSubmit,
  title,
  children,
  isSelectedAbi,
  formAddress,
  tempABIs,
  isCreateOrSelectAbiFlow,
  getABIName,
}) => {
  const [networkSelectorData, setNetworkSelectorData] = useState({
    selectedChain: null,
    chainName: '',
    chainId: '',
    rpc: '',
    network: null,
    type: null,
  });
  const {
    selectedChain,
    chainName,
    chainId,
    rpc,
    warningMsg,
    isCustomAdd,
    network,
    type,
  } = networkSelectorData;

  function resetNetworkState() {
    setNetworkSelectorData({
      selectedChain: null,
      chainName: '',
      chainId: '',
      rpc: '',
      network: null,
      type: null,
    });
  }

  const [abiName, setAbiName] = useState('');
  const [contractAddress, setContractAddress] = useState('');

  function onChangeABIName(e) {
    setAbiName(e.target.value);
    getABIName?.(e.target.value);
  }
  function onChangeContractAddress(e) {
    setContractAddress(e.target.value);
  }

  function handleSubmit() {
    const selectedChainId =
      get(selectedChain, 'chainId') ?? parseInt(chainId, 10);
    const selectedRPC = get(selectedChain, 'rpc.0') || rpc;
    const selectedChainName = get(selectedChain, 'name') || chainName;
    const selectedType = get(selectedChain, 'type') || type;
    const selectedNetworkStatus = get(selectedChain, 'network') || network;
    const getTypeBySchema = () => {
      if (selectedType?.id === 'near') {
        return {
          network: string().required("Network should'nt be empty"),
        };
      }
      return {
        chainId: number().nullable().required("Chain should'nt be empty"),
      };
    };
    const schema = object().shape(
      isCreateOrSelectAbiFlow
        ? {}
        : {
            name: string().required("Contract Name should'nt be empty"),
            address: string().required("Contract Address should'nt be empty"),
            rpc: string().required("RPC URL should'nt be empty"),
            ...getTypeBySchema(),
            chainName: string().required("Chain name should'nt be empty"),
          }
    );

    const getTypeByDefaultPayload = () => {
      if (selectedType?.id === 'near') {
        return {
          network: selectedNetworkStatus?.id,
        };
      }
      return {
        chainId: selectedChainId ?? null,
      };
    };

    const defaultPayload = {
      address: contractAddress,
      name: abiName,
      rpc: selectedRPC,
      ...getTypeByDefaultPayload(),
      chainName: selectedChainName,
    };

    const toastId = toast.loading('Loading...');
    schema
      .validate(defaultPayload)
      .then(() => {
        onSubmit(
          {
            id: uuidv4(),
            parentId: '',
            abi: [],
            meta: {
              name: abiName,
              version: W3C_ABI_VERSION,
              chainList: isCreateOrSelectAbiFlow
                ? tempABIs
                : [
                    {
                      id: uuidv4(),
                      name: selectedChainName,
                      address: contractAddress,
                      isActive: true,
                      network: {
                        chainId: selectedChainId,
                        rpc: selectedRPC,
                        chainName: selectedChainName,
                        type: selectedType,
                        network: selectedNetworkStatus,
                      },
                    },
                  ],
              source: IMPORT_SOURCE.NEW_ABI,
            },
          },
          !warningMsg && isCustomAdd
        );
        toast.success('Created ABI', {
          id: toastId,
        });
        resetNetworkState();
      })
      .catch((err) => {
        toast.error(err?.message, {
          id: toastId,
        });
      });
  }

  useEffect(() => {
    setContractAddress(formAddress ?? '');
  }, [formAddress]);

  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          isSelectedAbi ? onSubmit() : handleSubmit();
        }
      }}>
      <Back handleBack={handleBack} title={title} />
      <div className="flex flex-col mt-5 w-11/12 mx-auto">
        <Input
          label="ABI Name"
          onChange={onChangeABIName}
          value={abiName}
          name="abi-name"
          disabled={isSelectedAbi}
        />

        {!isCreateOrSelectAbiFlow && (
          <>
            <Input
              label="Contract ID"
              onChange={onChangeContractAddress}
              value={contractAddress}
              name="contract-address"
            />
            <NetworkSelector
              networkSelectorData={networkSelectorData}
              setNetworkSelectorData={setNetworkSelectorData}
            />
          </>
        )}

        {children}
        <Button
          variant="contained"
          className="w-20 !mt-4"
          onClick={isSelectedAbi ? onSubmit : handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default NewABI;
