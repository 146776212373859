import { Button, Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useRecoilState, useRecoilValue } from 'recoil';
import NewMethod from '../../../../constants/NewMethod.json';
import useTabs from '../../../../hooks/useTabs';
import {
  CollectionListState,
  CurrentExpandedCollectionId,
} from '../../../../states/CollectionState';
import { MobileDrawerOpenState } from '../../../../states/ResponsiveViewState';
import MethodListItem from './MethodListItem';
import createNewMethod from './createNewMethod';

const ABIListItemMethod = ({ _c }) => {
  const collectionsState = useRecoilValue(CollectionListState);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );
  const expandedId = useRecoilValue(CurrentExpandedCollectionId);
  const { setSelectedTab } = useTabs();

  /**
   *  Add New Method in ABI
   *  @param {object} e
   */
  const triggerNewMethod = (e) => {
    e?.stopPropagation?.();
    createNewMethod({
      NewMethod,
      _c,
      collectionsState,
      setSelectedTab,
    });
  };
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const filterFunctionTypeMethods = filter(
    get(_c, 'abi', []),
    (_ite) => _ite.type === 'function'
  );

  return (
    <Collapse in={_c.id === expandedId} timeout="auto" unmountOnExit>
      {!isEmpty(_c.abi) &&
        map(filterFunctionTypeMethods, (_abi) => {
          const stateMutability = get(_abi, 'stateMutability', '');
          return (
            <MethodListItem
              stateMutability={stateMutability}
              _c={_c}
              _abi={_abi}
            />
          );
        })}
      {isEmpty(filterFunctionTypeMethods) && (
        <Tooltip title="Create New Method">
          <div className="flex justify-center p-4">
            <Button
              variant="contained"
              fontsize="small"
              onClick={() => {
                triggerNewMethod();
                handleDrawerToggle();
              }}
              className="inline-flex w-[50%] items-center text-xs ml-2 h-7">
              <p className="md:text-sm text-xs">NEW METHOD</p>
            </Button>
          </div>
        </Tooltip>
      )}
    </Collapse>
  );
};
export default ABIListItemMethod;
