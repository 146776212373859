const chaincolor = [
  {
    symbolName: 'ETH',
    color: '#215CAF',
  },
  {
    symbolName: 'MATIC',
    color: '#8247e5',
  },
  {
    symbolName: 'AVAX',
    color: '#E84142',
  },
  {
    symbolName: 'BNB',
    color: '#F0B90B',
  },
  {
    symbolName: 'UBQ',
    color: '#00EA90',
  },
  {
    symbolName: 'ELA',
    color: '#87CEEB',
  },
  {
    symbolName: 'ETC',
    color: '#00FF00',
  },
  {
    symbolName: 'FLR',
    color: '#F0B90B',
  },
  {
    symbolName: 'SYS',
    color: '#00AFAF',
  },
  {
    symbolName: 'SNT',
    color: '#0080FF',
  },
  {
    symbolName: 'POA',
    color: '#0055FF',
  },
  {
    symbolName: 'HPB',
    color: '#00AFAF',
  },
  {
    symbolName: 'KCS',
    color: '#00AAFF',
  },
  {
    symbolName: 'BTX',
    color: '#FF1493',
  },
  {
    symbolName: 'IOTX',
    color: '#00d4d5',
  },
  {
    symbolName: 'ELLA',
    color: '#006363',
  },
  {
    symbolName: 'ONE',
    color: '#0055FF',
  },
  {
    symbolName: 'ONG',
    color: '#343434',
  },
  {
    symbolName: 'EOS',
    color: '#343434',
  },
  {
    symbolName: 'HT',
    color: '#43BFC7',
  },
  {
    symbolName: 'BTM',
    color: '#343434',
  },
  {
    symbolName: 'BTT',
    color: '#343434',
  },
];

export default chaincolor;

export const defaultColor = '#F0B90B';
