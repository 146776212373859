/**
 *
 * @param {Object} input -  filename, dot start point, dot end point
 * @returns {string}
 */
export default function trimFileName({ filename, startLength, endLength }) {
  if (!filename) return '';
  if (filename?.length <= startLength + endLength) return filename;
  const start = filename?.slice?.(0, startLength);
  const middle = '...';
  const end = filename?.substr?.(filename.length - endLength);
  return `${start}${middle}${end}`;
}
