import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, Checkbox, MenuItem, Popover, Tooltip } from '@mui/material';
import { useState } from 'react';
import IconWrapper from '../../../../components/IconWrapper';
import Input, { INPUT_VARIANTS } from '../../../../components/Input';
import ChainDetails from '../../../ChainDetails';
import DeleteConfirmationModal from '../../../modals/DeleteConfirmationModal';

let clickCount = 0;

const ChainListItem = ({
  chain,
  chainListLength,
  onEditClick,
  onDeleteClick,
  onActiveChange,
  onRename,
}) => {
  const [isOpenDeleteOpen, setIsOpenDeleteOpen] = useState(false);

  const [isRenameChainNameOpen, setIsRenameChainNameOpen] = useState(false);
  const [newName, setNewName] = useState('');

  const { name, network, address, isActive } = chain || {};

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    setAnchorEl(null);
  };

  const newOpen = Boolean(anchorEl);
  const id = newOpen ? 'simple-popover' : undefined;

  const submitDeleteData = (e) => {
    e?.stopPropagation();
    onDeleteClick(chain);
    setIsOpenDeleteOpen(false);
  };

  const isOneChainList = chainListLength === 1;

  /**
   * cancel rename chain name for particular ABI
   */
  const cancelRename = (e) => {
    e?.stopPropagation();
    setIsRenameChainNameOpen(false);
  };

  /**
   * rename chain name for particular ABI
   */
  const submitRename = (e) => {
    e?.stopPropagation();
    onRename(chain, newName);
    cancelRename();
  };

  /**
   * change chain active status for particular ABI
   */
  const submitChainActiveStatus = (e) => {
    e.stopPropagation();
    setTimeout(() => {
      if (clickCount !== 2) {
        onActiveChange(chain, true);
      }
    }, 150);
  };

  // show chain name for particular ABI
  const getNameHTML = () => {
    if (isRenameChainNameOpen) {
      return (
        <div
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              submitRename();
            }
          }}
          className="w-52 flex items-center opacity-70">
          <Input
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            value={newName}
            name="rename"
            isDisabledLabel
            size={INPUT_VARIANTS?.SMALL}
            margin="mb-1"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <HighlightOffIcon
            onClick={cancelRename}
            className="mb-1 ml-3 mr-1.5"
            fontSize="small"
            color="error"
          />
          <CheckCircleOutlineIcon
            color="success"
            onClick={submitRename}
            className="mb-1"
            fontSize="small"
          />
        </div>
      );
    }
    return (
      <div
        className="w-full"
        onClick={(e) => {
          clickCount += 1;
          if (clickCount === 2) {
            setNewName(name);
            setIsRenameChainNameOpen(true);
          }
          setTimeout(() => {
            clickCount = 0;
          }, 350);
        }}>
        <div className="font-semibold text-lg">{name || 'NA'}</div>
      </div>
    );
  };

  return (
    <Card
      className="w-[97%] ml-1.5 p-4 mt-3 items-center mb-4 !bg-primary"
      sx={{ boxShadow: '5px 5px 5px 5px EEEEEE' }}>
      <div className="flex cursor-pointer">
        <div className="items-center p-1" onClick={submitChainActiveStatus}>
          <Checkbox
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircle />}
            checked={isActive}
            className="!cursor-pointer"
            disabled={isOneChainList}
          />
        </div>
        <div className="flex-grow flex flex-col">
          <ChainDetails
            name={getNameHTML()}
            chainId={network?.chainId}
            chainName={network?.chainName}
            address={address}
            disableCopy
          />
          <div className="flex items-center text-sm pl-2 pt-1">
            {network?.rpc || 'NA'}
          </div>
        </div>

        <div className="flex items-center p-1">
          <Tooltip title="More Options">
            <IconWrapper>
              <MoreVertIcon
                aria-describedby={id}
                className="!w-full !h-full cursor-pointer"
                sx={{ opacity: '0.7' }}
                fontSize="small"
                onClick={handleClick}
              />
            </IconWrapper>
          </Tooltip>
        </div>

        <div>
          <Popover
            id={id}
            open={newOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.5,

              '& .MuiMenuItem-root': {
                borderRadius: 0.75,
                minHeight: '30px',
                fontSize: '0.65rem',
              },
            }}>
            <MenuItem
              sx={{
                height: '1.9rem',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(chain);
              }}>
              <IconWrapper>
                <Tooltip title="Edit Chain">
                  <CreateIcon
                    sx={{ mr: 1, opacity: '0.7' }}
                    className="!w-full !h-full cursor-pointer"
                  />
                </Tooltip>
              </IconWrapper>
              <span className="ml-2"> Edit</span>
            </MenuItem>

            <MenuItem
              className={`${isOneChainList ? '!cursor-not-allowed' : ''}`}
              sx={{
                height: '1.9rem',
                '&:hover': {
                  bgcolor: 'error.main',
                  color: 'error.contrastText',
                },
              }}
              onClick={(e) => {
                e?.stopPropagation();
                !isOneChainList && setIsOpenDeleteOpen(true);
              }}>
              <IconWrapper>
                <Tooltip
                  title="Delete Chain"
                  disableHoverListener={isOneChainList}>
                  <DeleteIcon
                    sx={{
                      '&:hover': !isOneChainList
                        ? {
                            color: 'error.main',
                            opacity: '0.7',
                            cursor: 'pointer',
                          }
                        : {},
                      opacity: isOneChainList ? '0.3' : 1,
                    }}
                  />
                </Tooltip>
              </IconWrapper>
              <span className="ml-2"> Delete</span>
            </MenuItem>
          </Popover>
        </div>

        <DeleteConfirmationModal
          isOpen={isOpenDeleteOpen}
          onClose={(e) => {
            e?.stopPropagation();
            setIsOpenDeleteOpen(false);
          }}
          onSubmit={submitDeleteData}
          title={`${name} chain`}
        />
      </div>
    </Card>
  );
};

export default ChainListItem;
