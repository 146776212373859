import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Link, Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { forEach } from 'lodash';
import findIndex from 'lodash/findIndex';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import set from 'lodash/set';
import * as React from 'react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import IconWrapper from '../../../../components/IconWrapper';
import { TelemetryEvents } from '../../../../constants/EventsConstants';
import {
  AddOrSwitchChainService,
  deleteChain,
  editChain,
} from '../../../../services/ChainService';
import { sendToUpdateMetamaskChain } from '../../../../services/EventService';
import TelemetryService from '../../../../services/TelemetryService';
import { CollectionListState } from '../../../../states/CollectionState';
import getClone from '../../../../utils/getClone';
import AddOrEditChainModal from '../../../modals/AddOrEditChainModal';
import ChainListItem from './ChainListItem';

export default function ABIPreference({
  currentCollection,
  isNewMethodOrHistoryTab,
  collectionMethodFormData,
  setCollectionMethodFormData,
}) {
  const [open, setOpen] = React.useState(false);

  const [openAddChainModal, setOpenAddChainModal] = useState(false);

  const [editedChainData, setEditedChainData] = useState({});

  const collectionList = useRecoilValue(CollectionListState);

  const tempABIs = get(collectionMethodFormData, 'tempABIs');

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(isOpen);
  };

  const handleAddChainModalClose = () => {
    TelemetryService.sendEvent(TelemetryEvents.ADD_CHAIN_CANCEL, {
      id: currentCollection?.id,
    });
    setOpenAddChainModal(false);
    if (!isEmpty(editedChainData)) {
      setEditedChainData({});
    }
  };

  function sendEditTelementry(payload) {
    TelemetryService.sendEvent(TelemetryEvents.EDIT_CHAIN_SUCCESS, {
      name: payload?.name,
      chain_id: payload?.network?.chainId,
      contract_address: payload?.address,
      id: payload?.id,
      is_active: payload?.isActive,
    });
  }

  function sendAddTelementry(payload) {
    TelemetryService.sendEvent(TelemetryEvents.ADD_CHAIN_SUCCESS, {
      name: payload?.name,
      chain_id: payload?.network?.chainId,
      contract_address: payload?.address,
      id: payload?.id,
      is_active: payload?.isActive,
    });
  }

  function sendSwitchChainReq(payload) {
    if (get(payload, 'isActive')) {
      sendToUpdateMetamaskChain(get(payload, 'network.chainId'));
    }
  }

  /**
   * add and edit chain  for particular ABI
   * @param {object} chain
   */
  const handleChainForm = (payload) => {
    if (isNewMethodOrHistoryTab) {
      const clone = getClone(tempABIs);
      const newItem = clone || [];

      if (!isEmpty(editedChainData)) {
        const index = findIndex(newItem, (_it) => _it.id === payload.id);
        newItem[index] = payload;
      } else {
        let newPayload = payload;
        if (isEmpty(newItem)) {
          newPayload = { ...payload, isActive: true };
          sendSwitchChainReq(payload);
        }
        if (newPayload?.isActive) {
          forEach(newItem, (item) => set(item, 'isActive', false));
        }
        newItem.push(newPayload);
      }

      setCollectionMethodFormData((prev) => ({
        ...prev,
        tempABIs: newItem,
      }));

      if (!isEmpty(editedChainData)) sendEditTelementry(payload);
      else sendAddTelementry(payload);

      setEditedChainData({});
      setOpenAddChainModal(false);
      sendSwitchChainReq(payload);
      return;
    }
    if (!isEmpty(editedChainData)) {
      // edit chain for particular ABI
      editChain({
        ABIList: collectionList,
        SelectedABI: currentCollection,
        selectedChain: payload,
      });

      setEditedChainData({});
      sendEditTelementry(payload);
    } else {
      // add chain  for particular ABI
      AddOrSwitchChainService({
        ABIList: collectionList,
        SelectedABI: currentCollection,
        selectedChain: payload,
      });
      sendAddTelementry(payload);
    }
    sendSwitchChainReq(payload);
    setOpenAddChainModal(false);
  };

  const handleEditChain = (chain) => {
    setOpenAddChainModal(true);
    setEditedChainData(chain);
  };

  /**
   * delete chain  for particular ABI
   * @param {object} chain
   */
  const handleDeleteChain = (chain) => {
    if (isNewMethodOrHistoryTab) {
      const clone = getClone(tempABIs);
      const index = findIndex(clone, (_it) => _it.id === chain.id);
      clone.splice(index, 1);
      set(clone, ['0', 'isActive'], true);
      setCollectionMethodFormData((prev) => ({
        ...prev,
        tempABIs: clone,
      }));
      sendSwitchChainReq(get(clone, '0'));
      return;
    }
    deleteChain({
      ABIList: collectionList,
      SelectedABI: currentCollection,
      selectedChain: chain,
      sendSwitchChainReq,
    });
    setOpenAddChainModal(false);
  };

  /**
   * rename chain name for particular ABI
   */
  const handleRename = (chain, newName) => {
    const cloneChain = getClone(chain);
    set(cloneChain, 'name', newName);
    if (isNewMethodOrHistoryTab) {
      const clone = getClone(tempABIs);
      const index = findIndex(clone, (_it) => _it.id === cloneChain.id);
      clone[index] = cloneChain;
      setCollectionMethodFormData((prev) => ({
        ...prev,
        tempABIs: clone,
      }));
      return;
    }
    editChain({
      ABIList: collectionList,
      SelectedABI: currentCollection,
      selectedChain: cloneChain,
    });
  };

  /**
   * change chain active status for particular ABI
   */
  const handleActiveChain = (chain) => {
    const cloneChain = getClone(chain);
    set(cloneChain, 'isActive', true);

    if (isNewMethodOrHistoryTab) {
      let clone = getClone(tempABIs);
      clone = map(clone, (_it) => {
        const temp = _it;
        temp.isActive = false;
        return temp;
      });
      const index = findIndex(clone, (_it) => _it.id === cloneChain.id);
      clone[index] = cloneChain;
      setCollectionMethodFormData((prev) => ({
        ...prev,
        tempABIs: clone,
      }));
      sendSwitchChainReq(cloneChain);
      return;
    }
    editChain({
      ABIList: collectionList,
      SelectedABI: currentCollection,
      selectedChain: cloneChain,
    });
    sendSwitchChainReq(cloneChain);
  };

  function renderDrawer({ chainList }) {
    return (
      <div className="lg:w-[35vw] w-[85vw] flex flex-col h-full p-6 bg-primary">
        <div className="flex items-center mb-5">
          <div className="flex flex-col">
            <div className="font-bold text-xl flex">Contracts</div>
            <div className="flex flex-col w-full">
              <span className="text-sm">
                Add Contracts to ABI. It will be used while executing methods
              </span>
            </div>
          </div>
          <div className="flex ml-auto space-x-5 p-2">
            <Tooltip title="Add Contract">
              <IconWrapper className="flex">
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenAddChainModal(true)}>
                  <Add />
                </IconButton>
              </IconWrapper>
            </Tooltip>
            <Tooltip title="close">
              <IconWrapper className="flex">
                <IconButton aria-label="close" onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </IconWrapper>
            </Tooltip>
          </div>
        </div>

        <div className="mt-2 overflow-auto h-full">
          {isEmpty(chainList) && (
            <div className="w-full flex h-full justify-center flex-col">
              <div className="flex flex-col items-center">
                <div className="font-normal text-sm mb-3">
                  No Contracts Found
                </div>
                <Tooltip title="Add Contract">
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setOpenAddChainModal(true)}>
                    Add New Contract
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}

          {map(chainList, (_c) => {
            return (
              <ChainListItem
                chain={_c}
                onEditClick={handleEditChain}
                onDeleteClick={handleDeleteChain}
                onActiveChange={handleActiveChain}
                onRename={handleRename}
                chainListLength={chainList.length}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex ml-2 items-center">
      <React.Fragment key={'right'}>
        <Tooltip title="Switch Contract">
          <Link
            className="cursor-pointer flex items-center space-x-2 pr-2"
            onClick={toggleDrawer(true)}>
            <SwapHorizIcon />
            <div>Switch Contract</div>
          </Link>
        </Tooltip>

        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          {renderDrawer({
            chainList: isEmpty(currentCollection?.meta?.chainList)
              ? tempABIs
              : currentCollection?.meta?.chainList,
          })}
        </Drawer>
        <AddOrEditChainModal
          isOpen={openAddChainModal}
          onClose={handleAddChainModalClose}
          onSubmit={handleChainForm}
          editedChainData={editedChainData}
          chainListLength={
            isNewMethodOrHistoryTab
              ? tempABIs?.length
              : currentCollection?.meta?.chainList?.length
          }
        />
      </React.Fragment>
    </div>
  );
}
