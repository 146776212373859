import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import CryptoIcon from '../components/CryptoIcon';

const ChainSelector = ({
  value,
  onChange,
  options,
  label,
  isDisabledLabel,
  disableChain = false,
  placeholder,
  isClearable,
}) => {
  const formatOptions = map(options, (option) => {
    return {
      ...option,
      label: get(option, 'name'),
      symbol: get(option, 'nativeCurrency.symbol'),
    };
  });

  const renderOption = (props, option) => {
    const near = 'NEAR';
    const isNearChain = get(option, 'type.id')?.includes?.(near.toLowerCase());
    return (
      <div
        {...props}
        value={get(option, 'label')}
        className="h-10 flex items-center space-x-2 hover:bg-primary">
        <CryptoIcon
          symbol={isNearChain ? near : get(option, 'symbol')}
          size={20}
          className="ml-2"
        />
        <div className="flex w-full text-primary ml-2">
          {get(option, 'label')}
        </div>
      </div>
    );
  };

  const renderInput = (params) => {
    const findChain = find(options, (list) => {
      if (get(list, 'name') === get(params, 'inputProps.value')) {
        return true;
      }
    });
    const near = 'NEAR';

    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              {get(findChain, 'name') && (
                <CryptoIcon
                  symbol={
                    get(findChain, 'type.id') === near.toLowerCase()
                      ? near
                      : get(findChain, 'nativeCurrency.symbol')
                  }
                  size={20}
                  className="ml-2"
                />
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            boxShadow: 'none',
          },
        }}
        size="small"
        placeholder={placeholder}
      />
    );
  };

  return (
    <Box>
      {!isDisabledLabel && (
        <div className="text-sm mb-1 font-medium">{label}</div>
      )}
      <Autocomplete
        value={value || null}
        onChange={onChange}
        renderOption={renderOption}
        options={formatOptions}
        renderInput={renderInput}
        disableClearable={!isClearable}
        disabled={disableChain}
      />
    </Box>
  );
};

export default ChainSelector;
