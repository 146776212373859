import PropTypes from 'prop-types';
import Parameters from './Parameters';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-full">
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const RequestView = ({
  collectionMethodFormData,
  setCollectionMethodFormData,
}) => {
  return (
    <div className="w-full h-full">
      <Parameters
        collectionMethodFormData={collectionMethodFormData}
        setCollectionMethodFormData={setCollectionMethodFormData}
      />
    </div>
  );
};

export default RequestView;
