import { series } from 'async-es';
import TelemetryModule from '../../modules/Telemetry';
import StorageConstants, {
  getStorageConstants,
} from '../../constants/StorageConstants';
import GlobalStorageService from '../GlobalStorageService';

const { Telemetry, Services } = TelemetryModule;
export default {
  boot(cb) {
    this.bootServices((err, TelemetryServices) => {
      const TelemetryInstance = Telemetry(TelemetryServices);
      GlobalStorageService.setItem(
        getStorageConstants(StorageConstants.TELEMETRY),
        TelemetryInstance
      );
      cb && cb();
    });
  },
  bootServices(oCb) {
    // ? Don't boot the services if it is not prod env
    if (process.env.REACT_APP_CHANNEL !== 'prod') {
      oCb(null, []);
    } else {
      const telemetryServices = [
        (cb) => {
          try {
            const Internal = Services.Internal();
            Internal.boot((err) => {
              if (err) {
                console.log('boot~Telemetry: Failed to boot Internal');
                cb && cb();
              } else cb && cb(null, Internal);
            });
          } catch (e) {
            console.error(e);
            cb && cb();
          }
        },
        (cb) => {
          try {
            const Firebase = Services.Firebase();
            Firebase.boot((err) => {
              if (err) {
                console.log('boot~Telemetry: Failed to boot Firebase');
                cb && cb();
              } else cb && cb(null, Firebase);
            });
          } catch (e) {
            console.error(e);
            cb && cb();
          }
        },
        // ? Enable later if require
        // (cb) => {
        //   const Sentry = Services.Sentry();
        //   Sentry.boot((err) => {
        //     if (err) {
        //       console.log('boot~Telemetry: Failed to boot Sentry');
        //       cb && cb();
        //     } else cb && cb(null, Sentry);
        //   });
        // },
        // (cb) => {
        //   const CleverTap = Services.CleverTap();
        //   CleverTap.boot((err) => {
        //     if (err) {
        //       console.log('boot~Telemetry: Failed to boot CleverTap');
        //       cb && cb();
        //     } else cb && cb(null, CleverTap);
        //   });
        // },
      ];

      series(telemetryServices, (err, services) => {
        oCb && oCb(err, services);
      });
    }
  },
};
