import { atom } from 'recoil';
import types from '../constants/Types';

// Loading for execute method
export const IsResponseLoadingState = atom({
  key: types.LOADING.IS_RESPONSE_LOADING_STATE,
  default: false,
});

// Loading for sharing ABIs
export const IsShareLoadingState = atom({
  key: types.LOADING.IS_SHARE_LOADING_STATE,
  default: false,
});
