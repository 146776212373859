import { Button } from '@mui/material';
import get from 'lodash/get';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { number, object, string } from 'yup';
import Input from '../../../../components/Input';
import {
  IMPORT_SOURCE,
  NETWORKS,
  W3C_ABI_VERSION,
} from '../../../../constants/AppConstants';
import ContractService from '../../../../services/API/ContractService';
import NearService from '../../../../services/API/NearService';
import { uuidv4 } from '../../../../utils/UUIDV4';
import NetworkSelector from '../../../NetworkSelector';
import Back from './Back';

const ImportChainExplorer = ({ handleBack, onSubmit, title }) => {
  const [networkSelectorData, setNetworkSelectorData] = useState({
    selectedChain: null,
    chainName: '',
    chainId: '',
    rpc: '',
    network: null,
    type: null,
  });
  const {
    selectedChain,
    chainName,
    chainId,
    rpc,
    warningMsg,
    isCustomAdd,
    network,
    type,
  } = networkSelectorData;

  function resetNetworkState() {
    setNetworkSelectorData({
      selectedChain: null,
      chainName: '',
      chainId: '',
      rpc: '',
      network: null,
      type: null,
    });
  }

  const [abiName, setAbiName] = useState('');
  const [contractAddress, setContractAddress] = useState('');

  function onChangeABIName(e) {
    setAbiName(e.target.value);
  }
  function onChangeContractAddress(e) {
    setContractAddress(e.target.value);
  }

  const generateInputIds = (inputsArray) => {
    return map(inputsArray, (obj) => {
      return {
        ...obj,
        id: uuidv4(),
      };
    });
  };

  function handleSubmit() {
    const selectedChainId =
      get(selectedChain, 'chainId') ?? parseInt(chainId, 10);
    const selectedRPC = get(selectedChain, 'rpc.0') || rpc;
    const selectedChainName = get(selectedChain, 'name') || chainName;
    const selectedType = get(selectedChain, 'type') || type;

    const selectedNetworkStatus = get(selectedChain, 'network') || network;

    const schema = object().shape({
      name: string().required("Collection Name Should'nt be empty"),
      address: string().required("Contract ID Should'nt be empty"),
      chainId: number().nullable(),
      rpc: string().required("RPC URL should'nt be empty"),
      chainName: string().required("Chain name should'nt be empty"),
    });
    const payload = {
      name: abiName,
      address: contractAddress,
      chainId: selectedChainId || null,
      rpc: selectedRPC,
      chainName: selectedChainName,
    };
    const toastId = toast.loading('Loading...');
    schema
      .validate(payload)
      .then(() => {
        return selectedType.id === NETWORKS.NEAR
          ? NearService.getContractMethods(contractAddress)
          : ContractService.importABI(
              contractAddress,
              get(selectedChain, 'url')
            );
      })
      .then((res) => {
        onSubmit(
          {
            meta: {
              name: abiName,
              source: IMPORT_SOURCE.CHAIN_EXPLORER,
              version: W3C_ABI_VERSION,
              chainList: [
                {
                  id: uuidv4(),
                  name: selectedChainName,
                  address: payload.address,
                  network: {
                    chainId: payload.chainId,
                    rpc: selectedRPC,
                    chainName: selectedChainName,
                    type: selectedType,
                    network: selectedNetworkStatus,
                  },
                  isActive: true,
                },
              ],
            },
            abi: map(res, (_a) => ({
              ..._a,
              id: uuidv4(),
              inputs: generateInputIds(_a.inputs),
            })),
            id: uuidv4(),
            parentId: '',
          },
          !warningMsg && isCustomAdd
        );
        toast.success('Created ABI', {
          id: toastId,
        });
        resetNetworkState();
      })
      .catch((err) => {
        toast.error(err?.message || err?.result, {
          id: toastId,
        });
      });
  }

  useEffect(() => {
    setAbiName('');
    setContractAddress('');
  }, []);

  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit(event);
        }
      }}>
      <Back handleBack={handleBack} title={title} />
      <div className="flex flex-col mt-5 w-11/12 mx-auto">
        <Input
          label="ABI Name"
          onChange={onChangeABIName}
          value={abiName}
          name="abi-name"
        />
        <Input
          label="Contract ID"
          onChange={onChangeContractAddress}
          value={contractAddress}
          name="contract-address"
        />

        <NetworkSelector
          networkSelectorData={networkSelectorData}
          setNetworkSelectorData={setNetworkSelectorData}
          isImportChainExplorer
        />

        <Button
          type="submit"
          variant="contained"
          className="w-20 !mt-4"
          onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ImportChainExplorer;
