import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import chainsList from '../constants/Chains';
import EVMChainURLS from '../constants/EVMChainURLS';
import StorageConstants from '../constants/StorageConstants';
import ChainService from './API/ChainService';
import GlobalStorageService from './GlobalStorageService';
import CollectionService from './CollectionService';
import getClone from '../utils/getClone';
import ObjectStorage from './ObjectStorageService';

export async function fetchChain() {
  const chains = await ChainService.getChains();
  GlobalStorageService.setItem(StorageConstants.ETH_CHAINS, chains);
}

export function getAllEVMChains() {
  // return GlobalStorageService.getItem(StorageConstants.ETH_CHAINS, []);
  // Only Explorer
  return filter(
    GlobalStorageService.getItem(StorageConstants.ETH_CHAINS, []),
    (_c) => !isEmpty(_c.rpc)
  );
}

export const getCustomChains = () => {
  const networks = ObjectStorage.getItem(StorageConstants.CUSTOM_NETWORKS);
  const { data = [] } = networks;
  return data;
};

export function getExplorerChains() {
  return EVMChainURLS;
}

export function getAllEVMChainsWithURL() {
  const filterData = filter(EVMChainURLS, (chain) => {
    return get(chain, 'url');
  });
  return filterData;
}

export const getAllChains = (onlyWithUrl) => {
  const explorersChains = getAllEVMChains();
  const customChains = getCustomChains();
  const combinedChainList = [...customChains, ...explorersChains];
  const chains = map(combinedChainList, (_c) => ({
    ..._c,
    label: _c?.name,
    value: _c?.id,
    rpc: Array.isArray(_c?.rpc) ? _c?.rpc : [_c?.rpc],
  }));
  if (onlyWithUrl) {
    return filter(chains, (chain) => {
      return get(chain, 'url');
    });
  }
  return chains;
};

/**
 * get chain symbol by chain id
 * @param {string} chainId
 * @returns {string} chain symbol
 */
export function getChainSymbol(chainId) {
  const chain = find(getAllChains(), (_c) => _c.chainId === chainId);
  return get(chain, 'nativeCurrency.symbol') || 'BNB';
}

/**
 * get chain name by chain id
 * @param {string} chainId
 * @returns {string} chain name
 */
export function getChainName(chainId) {
  const chain = find(getAllChains(), (_c) => _c.chainId === chainId);
  return get(chain, 'name') || get(chain, 'chainName');
}

/**
 * get chain full Details by chain id
 * @param {string} chainId
 * @returns {object} chain full details
 */
export function getChainDetailsByChainId(chainId) {
  const chain = find(getAllChains(), (_c) => _c.chainId === chainId);
  return chain;
}

/**
 * get active chain from particular ABI list
 * @param {array} chainList
 * @returns {object} active chain
 */
export function getActiveChain(chainList) {
  return find(chainList, (_c) => _c.isActive);
}

/**
 * add or switch chain from particular ABI list
 * @param {object}
 */
export const AddOrSwitchChainService = ({
  ABIList,
  SelectedABI,
  selectedChain,
  isSwitching,
}) => {
  const chainList = get(SelectedABI, 'meta.chainList', []);

  let formatChainList = [];

  if (get(selectedChain, 'isActive') && !isSwitching) {
    // add chain with active status
    formatChainList = [
      ...map(chainList, (list) => {
        set(list, 'isActive', false);
        return list;
      }),
      selectedChain,
    ];
  } else if (isSwitching) {
    // switch chain
    formatChainList = map(chainList, (list) => {
      if (get(list, 'id') === get(selectedChain, 'id')) {
        set(list, 'isActive', true);
      } else {
        set(list, 'isActive', false);
      }
      return list;
    });
  } else {
    // add chain without active status
    formatChainList = [...chainList, selectedChain];
  }

  set(SelectedABI, 'meta.chainList', formatChainList);

  // replace local storage collection list
  //----------------------------------------------------------------
  const replaceCollection = map(ABIList, (list) => {
    if (get(list, 'id') === get(SelectedABI, 'id')) {
      return SelectedABI;
    }
    return list;
  });
  CollectionService.replaceCollections(replaceCollection);
  //----------------------------------------------------------------
};

/**
 * edit chain for particular ABI
 * @param {object}
 */
export const editChain = ({ ABIList, SelectedABI, selectedChain }) => {
  const cloneABI = getClone(SelectedABI);
  const chainList = get(cloneABI, 'meta.chainList', []);

  let formatChainList = chainList;

  if (get(selectedChain, 'isActive')) {
    formatChainList = map(chainList, (list) => {
      set(list, 'isActive', false);
      return list;
    });
  }

  const replaceSelectedChain = map(formatChainList, (chain) => {
    if (get(chain, 'id') === get(selectedChain, 'id')) {
      return selectedChain;
    }
    return chain;
  });
  formatChainList = replaceSelectedChain;

  const getAllChainsStatus = map(formatChainList, (list) =>
    get(list, 'isActive')
  );
  if (!getAllChainsStatus?.includes(true)) {
    set(formatChainList, '0.isActive', true);
  }

  set(cloneABI, 'meta.chainList', formatChainList);
  const replaceABIs = map(ABIList, (list) => {
    if (get(list, 'id') === get(cloneABI, 'id')) {
      return cloneABI;
    }
    return list;
  });
  CollectionService.replaceCollections(replaceABIs);
};

/**
 * delete chain for particular ABI
 * @param {object}
 */
export const deleteChain = ({
  ABIList,
  SelectedABI,
  selectedChain,
  sendSwitchChainReq,
}) => {
  const cloneABI = getClone(SelectedABI);
  const chainList = get(cloneABI, 'meta.chainList', []);

  const filterChainList = filter(chainList, (chain) => {
    return get(chain, 'id') !== get(selectedChain, 'id');
  });

  const getAllChainsStatus = map(filterChainList, (list) =>
    get(list, 'isActive')
  );
  if (!getAllChainsStatus?.includes(true)) {
    set(filterChainList, '0.isActive', true);
    sendSwitchChainReq(filterChainList?.[0]);
  }

  set(cloneABI, 'meta.chainList', filterChainList);
  const replaceABIs = map(ABIList, (list) => {
    if (get(list, 'id') === get(cloneABI, 'id')) {
      return cloneABI;
    }
    return list;
  });
  CollectionService.replaceCollections(replaceABIs);
};

export const saveChain = (network) => {
  const networks = ObjectStorage.getItem(StorageConstants.CUSTOM_NETWORKS);
  const { data = [] } = networks;
  data.push(network);
  ObjectStorage.setItem(StorageConstants.CUSTOM_NETWORKS, { data });
};

export const getRpcUrl = (chainId) => {
  const findRpc = find(chainsList, (chain) => get(chain, 'chainId') === chainId)
    ?.rpc?.[0];
  const defaultRpc = find(chainsList, (chain) => get(chain, 'chainId') === 1)
    ?.rpc?.[0];
  return findRpc || defaultRpc;
};
