import toast from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import isNull from 'lodash/isNull';
import { Tooltip } from '@mui/material';
import CryptoIcon from '../components/CryptoIcon';
import Messages from '../constants/Messages';
import OverflowTruncate from '../components/OverflowTruncate';
import { getChainSymbol } from '../services/ChainService';

const ChainDetails = ({ name, chainId, chainName, address, disableCopy }) => {
  return (
    <div className="flex flex-col pl-2 justify-between">
      <div className="font-semibold text-lg">{name}</div>
      <div className="flex">
        <CryptoIcon
          symbol={!isNull(chainId) ? getChainSymbol(chainId) : 'NEAR'}
          size={16}
          className="mr-2"
        />
        <div className="font-medium text-sm">{chainName}</div>
        <div className="font-medium text-sm ml-3">
          <OverflowTruncate name={address} start={10} />
        </div>
        {!disableCopy && (
          <CopyToClipboard
            text={address}
            className="cursor-pointer flex items-center ml-2"
            onCopy={() => {
              toast.success(Messages.INFO.COPY_CLIPBOARD);
            }}>
            <div className="text-xs text-slate-400 ml-2">
              <Tooltip title="Copy">
                <FileCopyIcon fontSize="14px" />
              </Tooltip>
            </div>
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
};

export default ChainDetails;
