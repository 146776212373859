import map from 'lodash/map';
import get from 'lodash/get';
import { W3C_ABI_VERSION } from '../constants/AppConstants';
import { uuidv4 } from '../utils/UUIDV4';
import { getChainName, getRpcUrl } from './ChainService';
import getClone from '../utils/getClone';

function _migrateV4(ABI) {
  const { meta } = ABI;
  const cloned = getClone(meta);

  return {
    ...ABI,
    meta: {
      ...meta,
      version: W3C_ABI_VERSION,
      chainList: map(cloned?.chainList, (_c) => ({
        ..._c,
        network: {
          ..._c.network,
          type: get(_c, 'network.type', { label: 'Ethereum', id: 'ethereum' }),
          network: get(_c, 'network.network', null),
        },
      })),
    },
  };
}

function _migrateV3(ABI) {
  const { abi, parentId, id, meta } = ABI;
  const cloned = getClone(meta);

  return {
    meta: {
      ...meta,
      version: W3C_ABI_VERSION,
      chainList: map(cloned?.chainList, (_c) => ({
        ..._c,
        id: get(_c, 'id', uuidv4()),
        network: {
          chainId: _c.chainId,
          chainName: get(_c, 'name'),
          rpc: getRpcUrl(get(_c, 'chainId')),
        },
        chainId: undefined,
      })),
    },
    abi,
    parentId,
    id,
  };
}
function _migrateV2(ABI) {
  const { address, chainId, name, source, abi, parentId, id } = ABI;
  return {
    meta: {
      version: W3C_ABI_VERSION,
      name,
      source,
      chainList: [
        {
          id: uuidv4(),
          name: getChainName(chainId),
          address,
          chainId,
          isActive: true,
        },
      ],
    },
    abi,
    parentId,
    id,
  };
}

const ABIVersionService = {
  migrateABI(ABI) {
    switch (ABI?.meta?.version) {
      case '4.0':
        return ABI;
      case '3.0':
        return _migrateV4(ABI);
      case '2.0':
        return _migrateV3(ABI);
      default:
        return _migrateV2(ABI);
    }
  },
};
export default ABIVersionService;
