const _serialize = (value) => {
  return JSON.stringify(value);
};

const _deSerialize = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error('Error in Parsing information in Object Store');
    return {};
  }
};
const _localStorage = localStorage;
const ObjectStorage = {
  setItem(key, value) {
    if (typeof value !== 'object') {
      return new Error('Error in storing Object: Value should be of JSON');
    }
    return _localStorage.setItem(key, _serialize(value));
  },

  getItem(key) {
    return _deSerialize(_localStorage.getItem(key) || '{}');
  },

  removeItem(key) {
    return _localStorage.removeItem(key);
  },
};

export default ObjectStorage;
