import SplitPane from 'react-split-pane';
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AppSettings } from '../../../states/SettingsState';
import Header from './Playground/Header';
import RequestView from './Playground/RequestView';
import ResponseView from './Playground/ResponseView';
import { UnSavedCollectionMethodTabsIdState } from '../../../states/TabStates';
import { SplitHeightState } from '../../../states/SplitState';
import CollectionService from '../../../services/CollectionService';
import useTabs from '../../../hooks/useTabs';
import { HistoryMethodListState } from '../../../states/HistoryState';
import TabService from '../../../services/TabService';

const Playground = ({ _tab }) => {
  const [settings] = useRecoilState(AppSettings);
  const setSplitHeight = useSetRecoilState(SplitHeightState);
  const [collectionMethodFormData, setCollectionMethodFormData] = useState({});
  const [unSavedCollectionMethodTabsId, setUnSavedCollectionMethodTabsId] =
    useRecoilState(UnSavedCollectionMethodTabsIdState);

  const { selectedTab } = useTabs();

  const allHistory = useRecoilValue(HistoryMethodListState);

  const isNewMethod = TabService.isUntitledTab(selectedTab);
  const isHistoryTab = TabService.isHistoryTab(selectedTab);

  const isNewMethodOrHistoryTab = isNewMethod || isHistoryTab;

  const SplitHeight = (size) => {
    setSplitHeight(size);
  };

  // convert method to form list when switching tab
  useEffect(() => {
    setCollectionMethodFormData(
      CollectionService.convertMethodToForm({ tab: _tab, allHistory })
    );
  }, []);

  // track whether Save or unSave method
  useEffect(() => {
    if (isEmpty(collectionMethodFormData)) return;

    const ignoreKeys = ['abiResponse', 'contractAddress'];

    if (!isNewMethodOrHistoryTab) {
      ignoreKeys.push('selectedChain');
    }

    if (
      !isEqual(
        omit(collectionMethodFormData, ignoreKeys),
        omit(
          CollectionService.convertMethodToForm({
            tab: _tab,
            allHistory,
          }),
          ignoreKeys
        )
      )
    ) {
      // add tab id in unSave list
      CollectionService.addUnSavedCollectionMethodTabsId({
        setUnSavedCollectionMethodTabsId,
        unSavedCollectionMethodTabsId,
        tab: selectedTab,
      });
    } else {
      // remove tab id from unSave list
      CollectionService.removeUnSavedCollectionMethodTabsId({
        setUnSavedCollectionMethodTabsId,
        unSavedCollectionMethodTabsId,
        tab: selectedTab,
      });
    }
  }, [collectionMethodFormData]);

  if (isEmpty(collectionMethodFormData)) return null;

  return (
    <div className="flex flex-col w-full h-full">
      <Header
        collectionMethodFormData={collectionMethodFormData}
        setCollectionMethodFormData={setCollectionMethodFormData}
      />
      <div className="relative h-full w-full">
        <SplitPane
          split="horizontal"
          defaultSize={330}
          maxSize={500}
          minSize={200}
          onChange={SplitHeight}
          resizerStyle={{
            background: '#d7d9d7',
            marginLeft: '!2px important',
            position: 'relative',
            float: 'right',
            height: '1px',
            opacity: settings.theme === 'dark' ? 0.25 : 0.9,
            paddingTop: '0.5px',
            paddingBottom: '0.5px',
          }}
          style={{ position: 'relative' }}>
          <RequestView
            collectionMethodFormData={collectionMethodFormData}
            setCollectionMethodFormData={setCollectionMethodFormData}
          />
          <ResponseView collectionMethodFormData={collectionMethodFormData} />
        </SplitPane>
      </div>
    </div>
  );
};

export default Playground;
