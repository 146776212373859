import { Button } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { object, string } from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import set from 'lodash/set';
import { uuidv4 } from '../../../../utils/UUIDV4';
import Back from './Back';
import Input from '../../../../components/Input';
import { IMPORT_SOURCE } from '../../../../constants/AppConstants';
import JSONHelper from '../../../../utils/JSONHelper';
import getClone from '../../../../utils/getClone';

const ImportABI = ({ handleBack, onSubmit, title }) => {
  const [abiName, setAbiName] = useState('');
  const [file, setFile] = useState('');

  function onChangeABIName(e) {
    setAbiName(e.target.value);
  }

  const handleFileChange = (file) => {
    setFile(file);
  };

  function handleSubmit() {
    const schema = object().shape({
      file: string().required('File is required'),
      name: string().required("Contract Name should'nt be empty"),
    });
    const defaultPayload = {
      name: abiName,
      file,
    };
    const toastId = toast.loading('Loading...');
    schema
      .validate(defaultPayload)
      .then(() => {
        const fileReader = new FileReader();
        fileReader.readAsText(file, 'UTF-8');
        fileReader.onload = (e) => {
          const abi = e.target.result;
          if (!JSONHelper.hasJsonStructure(abi)) {
            toast.error('Not a valid JSON');
            return;
          }
          const newABI = getClone(JSON.parse(abi)) || {};
          set(newABI, 'id', uuidv4());
          set(newABI, 'meta.name', abiName);
          forEach(get(newABI, 'abi'), (_method) => {
            set(_method, 'id', uuidv4());
          });
          onSubmit({
            ...newABI,
            source: IMPORT_SOURCE.FILE,
          });
        };
        toast.success('Created ABI', {
          id: toastId,
        });
      })
      .catch((err) => {
        toast.error(err?.message, {
          id: toastId,
        });
      });
  }

  return (
    <div
      onKeyDown={(event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit();
        }
      }}>
      <Back handleBack={handleBack} title={title} />
      <div className="flex flex-col mt-5 w-11/12 mx-auto">
        <Input
          label="ABI Name"
          onChange={onChangeABIName}
          value={abiName}
          name="abi-name"
        />
        <div className="flex flex-col mb-4">
          <div className="text-sm mb-2 font-medium">
            Web3client ABI JSON File
          </div>
          <FileUploader
            multiple={false}
            handleChange={handleFileChange}
            accept=".json"
            name="file"
            label="Upload or Drag a Web3client ABI JSON here"
          />
        </div>

        <Button
          variant="contained"
          className="w-20 !mt-4"
          onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ImportABI;
