const types = {
  COLLECTION: {
    COLLECTION_LIST_STATE: 'collection-list-state',
    ACTIVE_TAB_STATE: 'active-tab-state',
    TABS_STATE: 'tabs-state',
    CURRENT_EXPANDED_COLLECTION_ID: 'current-expanded-collection-id',
    UN_SAVE_COLLECTION_METHOD_TABS_ID_STATE:
      'un-save-collection-method-tabs-id-state',
    NEW_ABI_STATE: 'new-collection-state',
  },
  HISTORY: {
    HISTORY_METHOD_LIST_STATE: 'history_method_list_state',
  },
  LOADING: {
    IS_RESPONSE_LOADING_STATE: 'is_response_loading_state',
    IS_SHARE_LOADING_STATE: 'is_share_loading_state',
  },
  MODAL: {
    MODAL_OPEN_STATE: 'modal-open-state',
  },

  RESPONSIVE: {
    MOBILE_DRAWER_OPEN_STATE: 'mobile-drawer-open-state',
  },
  SPLIT: {
    SPLIT_HEIGHT_STATE: 'split-height-state',
  },
  CHAINS: {
    CURRENT_CHAIN_NAME_STATE: 'current-chain-name-state',
  },
  ABIFORM: {
    TYPES: 'abi-form-types',
    NAMES: 'abi-form-names',
  },
  CONTRACT: {
    CONTRACT_INPUT: 'contract-input',
    ABI_STATE: 'abi-state',
    ABI_FETCH_STATE: 'abi-fetch-state',
  },
  SETTING: {
    APP_SETTINGS: 'app-settings',
  },
  CHAINMODAL: {
    ADD_CHAIN_MODAL: 'add_edit_chain_modal',
  },
};

export default types;
