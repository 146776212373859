import { connect } from 'near-api-js';
// import {
//   base_decode as baseDecode,
//   //   base_encode as baseEncode,
// } from 'near-api-js/lib/utils/serialize';
import get from 'lodash/get';
import NearWalletSelector from '../NearWalletSelector';
import executorUtils from './executorUtils';

const KIT_WALLET_API = {
  testnet: 'https://testnet-api.kitwallet.app/publicKey/',
  mainnet: 'https://api.kitwallet.app/publicKey/',
};

const _getNetwork = (rpcURL) => {
  let network = 'mainnet';
  if (rpcURL.includes('testnet')) {
    network = 'testnet';
  }
  return network;
};

const _fetchNearNamedAccountIfAvailable = async (publicKey, network) => {
  const res = await fetch(
    `${KIT_WALLET_API[_getNetwork(network)]}${publicKey}/accounts`
  );
  return res.json();
};

const safeParse = (data) => {
  let parsed = {};
  try {
    parsed = JSON.parse(data);
  } catch (e) {
    console.log(e);
    return parsed;
  }
  console.log(parsed);
  return parsed;
};

const _getFormattedInputs = (inputs, values) => {
  const formatted = {};
  inputs.forEach((element) => {
    const key = element.name;
    if (element.type === 'json') {
      formatted[key] = safeParse(values[element.id]);
    } else {
      formatted[key] = values[element.id];
    }
  });

  return formatted;
};

const near = {
  /**
   *
   * @param { }
   * contractAddress, ABI: Array[], method: String, values, account
   * @returns
   */
  async executeMethod({
    contractAddress,
    ABI,
    method,
    values,
    account,
    stateMutability,
    network,
    privateKey,
    executorOptions,
  }) {
    const formattedInput = _getFormattedInputs(ABI[0].inputs, ABI[0].values);
    const networkId = _getNetwork(network.rpc);
    if (executorUtils.isView(stateMutability)) {
      const near = await connect({
        networkId,
        nodeUrl: network.rpc,
      });
      const tx = await near.connection.provider.query({
        request_type: 'call_function',
        finality: 'final',
        account_id: contractAddress,
        method_name: method,
        args_base64: btoa(JSON?.stringify(formattedInput)),
      });

      return Promise.resolve(JSON.parse(Buffer.from(tx.result).toString()));
    }

    const walletNetwork = network.network.id || 'mainnet';
    // await NearWalletSelector.signInForContract(walletNetwork);

    const wallet = await NearWalletSelector.getWalletForNetwork(walletNetwork);
    if (wallet) {
      wallet
        .signAndSendTransaction({
          receiverId: contractAddress,
          actions: [
            {
              type: 'FunctionCall',
              params: {
                methodName: method,
                args: formattedInput,
                gas: `${get(executorOptions, 'gas', 0)}`,
                deposit: `${get(executorOptions, 'deposit', 0)}`,
              },
            },
          ],
        })
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          throw err;
        });
      return;
    }

    throw new Error('Error in connecting to near wallet');

    // const keyPair = KeyPair.fromString(privateKey);
    // let accountID = baseDecode(
    //   keyPair?.getPublicKey().toString().split(':')[1]
    // ).toString('hex');
    // const keystore = new keyStores.InMemoryKeyStore(
    //   keyPair?.getPublicKey().toString(),
    //   network.rpc
    // );
    // const [namedAccountId] = await _fetchNearNamedAccountIfAvailable(
    //   keyPair?.getPublicKey().toString(),
    //   network.rpc
    // );

    // if (namedAccountId) {
    //   accountID = namedAccountId;
    // }

    // await keystore.setKey(networkId, accountID, keyPair);
    // const near = await connect({
    //   networkId,
    //   nodeUrl: 'https://rpc.mainnet.near.org',
    //   keyStore: keystore,
    // });
    // account = await near.account(accountID);
    // const res = await account.functionCall({
    //   contractId: contractAddress,
    //   methodName: method,
    //   args: formattedInput,
    // });
    // return Promise.resolve(res);
  },
};

export default near;
