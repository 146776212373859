/**
 * export json
 * @param {object}  input - data which is convert to json, filename
 */
const exportToJson = ({ data, fileName = 'download.json' }) => {
  const contentType = 'application/json;charset=utf-8;';
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(data)))],
      {
        type: contentType,
      }
    );
    navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(data))}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export default exportToJson;
