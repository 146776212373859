/**
 *
 * @param {(array|object)} data which is check
 * @returns {boolean}
 */
const isJSON = (str) => {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null) {
      return true;
    }
  } catch {
    return false;
  }
};

export default isJSON;
