import API from '../APIService';

const APIURL = process.env.REACT_APP_CLIENT_API_URL;
const ABIService = {
  shareABI(options) {
    return new Promise((resolve, reject) => {
      const url = `${APIURL}/api/v1/web3share/share`;
      API.fetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getCollection(token) {
    return new Promise((resolve, reject) => {
      const url = `${APIURL}/api/v1/web3share/abi/${token}`;
      API.fetch(
        url,
        {
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};
export default ABIService;
