import { CodeRounded } from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import Code from '@mui/icons-material/CodeOutlined';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import CodeEditor from '../../../../components/CodeEditor';
import IconWrapper from '../../../../components/IconWrapper';
import Messages from '../../../../constants/Messages';
import CodeGenService from '../../../../services/CodeGenService';
import { AppSettings } from '../../../../states/SettingsState';

export default function CodeGen({ options }) {
  const [open, setOpen] = React.useState(false);
  const settings = useRecoilValue(AppSettings);
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(isOpen);
  };

  const list = () => (
    <Box className="lg:w-[35vw] w-[85vw]" role="presentation">
      <div className="flex flex-col w-full h-full mr-2 ml-2">
        <div className="flex items-center justify-between m-2">
          <div className="text-lg font-bold flex items-end">
            <IconWrapper>
              <CodeRounded className="mr-2 !w-full !h-full" />
            </IconWrapper>
            <span className="ml-2">Code snippet </span>
          </div>
          <Tooltip title="Close source code">
            <IconButton aria-label="close" onClick={toggleDrawer(false)}>
              <IconWrapper>
                <CloseIcon className="!w-full !h-full" />
              </IconWrapper>
            </IconButton>
          </Tooltip>
        </div>
        <div
          className="flex flex-col w-full pb-4"
          style={{ height: 'calc(100vh - 50px)' }}>
          <div className="flex items-center">
            <div className="font-semibold mb-4 mt-4 p-2 ">Ether.js</div>
            <CopyToClipboard
              text={open ? CodeGenService.generateCode(options()) : ''}
              onCopy={() => {
                toast.success(Messages.INFO.COPY_CLIPBOARD);
              }}>
              <div className="text-primary mr-4">
                <Tooltip title="Copy">
                  <IconWrapper>
                    <FileCopyIcon fontSize="14px" />
                  </IconWrapper>
                </Tooltip>
              </div>
            </CopyToClipboard>
          </div>

          <CodeEditor
            language={'javascript'}
            value={open ? CodeGenService.generateCode(options()) : ''}
            options={{
              readOnly: true,
              autoIndent: true,
              formatOnPaste: true,
              formatOnType: true,
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              wordWrap: 'on',
            }}
            mode={settings.theme}
          />
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'right'}>
        <Tooltip title="Source Code">
          <IconButton
            color="primary"
            aria-label="code-gen"
            component="label"
            onClick={toggleDrawer(true)}>
            <IconWrapper>
              <Code className="!w-full !h-full" />
            </IconWrapper>
          </IconButton>
        </Tooltip>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          {list()}̇
        </Drawer>
      </React.Fragment>
    </div>
  );
}
