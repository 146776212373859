import { forEach, get, map, set } from 'lodash';
import { DOCS } from '../constants/AppConstants';
import {
  generateABIDetailsMarkDown,
  generateABINameMarkDown,
  generateMethodDetailsMarkDown,
  generateMethodNameMarkDown,
} from '../utils/generateDocReadme';
import getClone from '../utils/getClone';
import CollectionService from './CollectionService';

export default {
  setDocumentationInAbi({
    updateStatus,
    value,
    methodId,
    activeCollection,
    collectionList,
  } = {}) {
    const cloneCurrentCollection = getClone(activeCollection);

    const storeMarkdownContents = () => {
      set(
        cloneCurrentCollection,
        'documentation.content.abiName',
        generateABINameMarkDown(cloneCurrentCollection)
      );
      set(cloneCurrentCollection, 'documentation.content.abiDescription', '');
      set(
        cloneCurrentCollection,
        'documentation.content.abiDetails',
        generateABIDetailsMarkDown(cloneCurrentCollection)
      );

      forEach(get(cloneCurrentCollection, 'abi'), (_method) => {
        if (_method?.type === 'function') {
          set(
            cloneCurrentCollection,
            `documentation.content.methodName.${get(_method, 'id', '')}`,
            generateMethodNameMarkDown(_method)
          );
          set(
            cloneCurrentCollection,
            `documentation.content.methodDescription.${get(_method, 'id', '')}`,
            ''
          );
          set(
            cloneCurrentCollection,
            `documentation.content.methodDetails.${get(_method, 'id', '')}`,
            generateMethodDetailsMarkDown(_method)
          );
        }
      });
    };

    if (updateStatus === DOCS.ABI_NAME) {
      set(cloneCurrentCollection, 'documentation.content.abiName', value);
      set(cloneCurrentCollection, 'documentation.isEdit', true);
    } else if (updateStatus === DOCS.ABI_DESCRIPTION) {
      set(
        cloneCurrentCollection,
        'documentation.content.abiDescription',
        value
      );
      set(cloneCurrentCollection, 'documentation.isEdit', true);
    } else if (updateStatus === DOCS.ABI_DETAILS) {
      set(cloneCurrentCollection, 'documentation.content.abiDetails', value);
      set(cloneCurrentCollection, 'documentation.isEdit', true);
    } else if (updateStatus === DOCS.METHOD_NAME) {
      set(
        cloneCurrentCollection,
        `documentation.content.methodName.${methodId}`,
        value
      );
      set(cloneCurrentCollection, 'documentation.isEdit', true);
    } else if (updateStatus === DOCS.METHOD_DESCRIPTION) {
      set(
        cloneCurrentCollection,
        `documentation.content.methodDescription.${methodId}`,
        value
      );
      set(cloneCurrentCollection, 'documentation.isEdit', true);
    } else if (updateStatus === DOCS.METHOD_DETAILS) {
      set(
        cloneCurrentCollection,
        `documentation.content.methodDetails.${methodId}`,
        value
      );
      set(cloneCurrentCollection, 'documentation.isEdit', true);
    } else if (updateStatus === DOCS.REGENERATE_ABI_AND_METHOD) {
      set(cloneCurrentCollection, 'documentation.isEdit', false);
      storeMarkdownContents();
    } else if (!get(cloneCurrentCollection, 'documentation.isEdit')) {
      storeMarkdownContents();
    }

    const replaceCollections = map(collectionList, (_c) => {
      if (get(_c, 'id') === get(cloneCurrentCollection, 'id')) {
        return cloneCurrentCollection;
      }
      return _c;
    });

    // add method into new ABI
    CollectionService.replaceCollections(replaceCollections);
  },
  setDocumentationInMethod({
    updateStatus,
    value,
    currentCollection,
    currentMethod,
    collectionList,
  } = {}) {
    const cloneCurrentCollection = getClone(currentCollection);

    forEach(get(cloneCurrentCollection, 'abi'), (_m) => {
      const storeMarkdownContents = () => {
        set(
          cloneCurrentCollection,
          'documentation.content.abiName',
          generateABINameMarkDown(currentCollection)
        );
        set(cloneCurrentCollection, 'documentation.content.abiDescription', '');
        set(
          cloneCurrentCollection,
          'documentation.content.abiDetails',
          generateABIDetailsMarkDown(currentCollection)
        );
        set(
          cloneCurrentCollection,
          `documentation.content.methodName.${get(currentMethod, 'id')}`,
          generateMethodNameMarkDown(currentMethod)
        );
        set(
          cloneCurrentCollection,
          `documentation.content.methodDescription.${get(currentMethod, 'id')}`,
          ''
        );
        set(
          cloneCurrentCollection,
          `documentation.content.methodDetails.${get(currentMethod, 'id')}`,
          generateMethodDetailsMarkDown(currentMethod)
        );
      };

      if (get(_m, 'id') === get(currentMethod, 'id')) {
        if (updateStatus === DOCS.ABI_NAME) {
          set(cloneCurrentCollection, 'documentation.content.abiName', value);
          set(cloneCurrentCollection, 'documentation.isEdit', true);
        } else if (updateStatus === DOCS.ABI_DESCRIPTION) {
          set(
            cloneCurrentCollection,
            'documentation.content.abiDescription',
            value
          );
          set(cloneCurrentCollection, 'documentation.isEdit', true);
        } else if (updateStatus === DOCS.ABI_DETAILS) {
          set(
            cloneCurrentCollection,
            'documentation.content.abiDetails',
            value
          );
          set(cloneCurrentCollection, 'documentation.isEdit', true);
        } else if (updateStatus === DOCS.METHOD_NAME) {
          set(
            cloneCurrentCollection,
            `documentation.content.methodName.${get(currentMethod, 'id')}`,
            value
          );
          set(cloneCurrentCollection, 'documentation.isEdit', true);
        } else if (updateStatus === DOCS.METHOD_DESCRIPTION) {
          set(
            cloneCurrentCollection,
            `documentation.content.methodDescription.${get(
              currentMethod,
              'id'
            )}`,
            value
          );
          set(cloneCurrentCollection, 'documentation.isEdit', true);
        } else if (updateStatus === DOCS.METHOD_DETAILS) {
          set(
            cloneCurrentCollection,
            `documentation.content.methodDetails.${get(currentMethod, 'id')}`,
            value
          );
          set(cloneCurrentCollection, 'documentation.isEdit', true);
        } else if (updateStatus === DOCS.REGENERATE_ABI_AND_METHOD) {
          storeMarkdownContents();
        } else if (!get(cloneCurrentCollection, 'documentation.isEdit')) {
          storeMarkdownContents();
        }
      }
    });

    const replaceCollections = map(collectionList, (_c) => {
      if (get(_c, 'id') === get(cloneCurrentCollection, 'id')) {
        return cloneCurrentCollection;
      }
      return _c;
    });

    // add method into new ABI
    CollectionService.replaceCollections(replaceCollections);
  },
};
