import { useEffect } from 'react';
import { useTabs } from 'react-headless-tabs';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { subscribeToUpdateActiveTab } from '../services/EventService';
import { ActiveTabState, TabsState } from '../states/TabStates';

/**
 * update active tab in react-headless-tabs package useTab hooks
 */
const useUpdateActiveTab = () => {
  const tabs = useRecoilValue(TabsState);
  const [useTabsSelectedTab, setUseTabsSelectedTab] = useTabs(tabs);

  const setActiveTab = useSetRecoilState(ActiveTabState);

  useEffect(() => {
    const unSubcribeCollectionUpdate = subscribeToUpdateActiveTab((value) => {
      setUseTabsSelectedTab(value);
    });
    return () => {
      unSubcribeCollectionUpdate && unSubcribeCollectionUpdate();
    };
  }, []);

  useEffect(() => {
    setActiveTab(useTabsSelectedTab);
  }, [useTabsSelectedTab]);
};

export default useUpdateActiveTab;
