import { useEffect } from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import History from './History';
import ABIs from './ABIs';
import { CurrentExpandedCollectionId } from '../../../states/CollectionState';
import { MobileDrawerOpenState } from '../../../states/ResponsiveViewState';
import CustomizedSwitches from '../../../components/MaterialUISwitch';
import IconWrapper from '../../../components/IconWrapper';
import useTabs from '../../../hooks/useTabs';
import TabService from '../../../services/TabService';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-full h-full">
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SidePannel = ({ selectedSidePannelTab }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useRecoilState(
    MobileDrawerOpenState
  );

  const [expandedId, setExpandedId] = useRecoilState(
    CurrentExpandedCollectionId
  );

  const { selectedTab } = useTabs();

  // toggle mobile drawer
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  // set current open accordion ABI id
  useEffect(() => {
    if (selectedTab !== expandedId) {
      setExpandedId(TabService.getCollectionId(selectedTab));
    }
  }, [selectedTab]);

  return (
    <div className="h-full pl-1 pb-[3.3rem] flex-grow">
      <div className="flex justify-center h-[calc(100vh-8.6rem)]">
        <TabPanel value={selectedSidePannelTab} index={0}>
          <ABIs onClick={handleDrawerToggle} />
        </TabPanel>
        <TabPanel value={selectedSidePannelTab} index={1}>
          <History />
        </TabPanel>
      </div>
      <div className="justify-between items-center rounded-none bg-primary flex border-t border-secondary ml-1">
        <div className="items-center flex ml-4">
          <a
            href="https://twitter.com/web3client"
            title="twitter"
            target="_blank"
            rel="noreferrer">
            <IconWrapper>
              <TwitterIcon className="!w-full !h-full" />
            </IconWrapper>
          </a>

          <a
            href="https://t.me/web3client"
            title="telegram"
            target="_blank"
            rel="noreferrer">
            <IconWrapper>
              <TelegramIcon className="!w-full !h-full ml-2 " />
            </IconWrapper>
          </a>

          <a
            href="https://discord.gg/Z8J2VbcstB"
            target="_blank"
            rel="noreferrer"
            className="text-sm mr-5 items-center">
            {/* <div className="text-sm mr-5 items-center"> */}
            <IconWrapper>
              <svg
                className="!w-full !h-6 ml-4 mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="26px"
                height="46px"
                viewBox="0 0 512 512"
                fill="#5865f2"
                aria-label="Discord"
                role="img"
                version="1.1">
                <path
                  d="m386 137c-24-11-49.5-19-76.3-23.7c-.5 0-1 0-1.2.6c-3.3 5.9-7 13.5-9.5 19.5c-29-4.3-57.5-4.3-85.7 0c-2.6-6.2-6.3-13.7-10-19.5c-.3-.4-.7-.7-1.2-.6c-23 4.6-52.4 13-76 23.7c-.2 0-.4.2-.5.4c-49 73-62 143-55 213c0 .3.2.7.5 1c32 23.6 63 38 93.6 47.3c.5 0 1 0 1.3-.4c7.2-9.8 13.6-20.2 19.2-31.2c.3-.6 0-1.4-.7-1.6c-10-4-20-8.6-29.3-14c-.7-.4-.8-1.5 0-2c2-1.5 4-3 5.8-4.5c.3-.3.8-.3 1.2-.2c61.4 28 128 28 188 0c.4-.2.9-.1 1.2.1c1.9 1.6 3.8 3.1 5.8 4.6c.7.5.6 1.6 0 2c-9.3 5.5-19 10-29.3 14c-.7.3-1 1-.6 1.7c5.6 11 12.1 21.3 19 31c.3.4.8.6 1.3.4c30.6-9.5 61.7-23.8 93.8-47.3c.3-.2.5-.5.5-1c7.8-80.9-13.1-151-55.4-213c0-.2-.3-.4-.5-.4Zm-192 171c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38zm125 0c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38z"
                  fill="var(--color-text-primary)"
                />
              </svg>
            </IconWrapper>
            {/* </div> */}
          </a>
        </div>
        <div className="relative left-5 mr-2">
          <CustomizedSwitches />
        </div>
      </div>
    </div>
  );
};

export default SidePannel;
