import { ethers } from 'ethers';
import { STATE_MUTUABLITIY_LIST } from '../../constants/AppConstants';

const _parseUInt256 = (data) => {
  return ethers.utils.parseUnits(data, 18);
};

const _parseAddress = (data) => {
  return ethers.utils.getAddress(data);
};

export default {
  isView(stateMutability) {
    return (
      stateMutability === STATE_MUTUABLITIY_LIST.VIEW ||
      stateMutability === STATE_MUTUABLITIY_LIST.PURE
    );
  },

  parseArguments(type, value) {
    if (type === 'uint256') {
      return _parseUInt256(value);
    }
    if (type === 'address') {
      return _parseAddress(value);
    }
    if (type === 'uint256[]') {
      if (typeof value === 'string') {
        value = JSON.parse(value);
      }
      return value.map((v) => {
        return this.parseArguments('uint256', v);
      });
    }
    if (type === 'address[]') {
      if (typeof value === 'string') {
        value = JSON.parse(value);
      }
      return value.map((v) => {
        return this.parseArguments('address', v);
      });
    }
    return value;
  },
};
