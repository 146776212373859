import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import set from 'lodash/set';
import isNull from 'lodash/isNull';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import IconWrapper from '../../../../components/IconWrapper';
import CryptoIcon from '../../../../components/CryptoIcon';
// import trimFileName from '../../../../utils/trimFileName';
import Input, { INPUT_VARIANTS } from '../../../../components/Input';
import TextOverflowTruncate from '../../../../components/TextOverflowTruncate';
import { TelemetryEvents } from '../../../../constants/EventsConstants';
import CollectionService from '../../../../services/CollectionService';
import TelemetryService from '../../../../services/TelemetryService';
import { CollectionListState } from '../../../../states/CollectionState';
import { ShareABIs } from '../../../Dialogs/ShareABIs';
import ABIListPopOver from './ABILisPopOver';
import { getChainSymbol } from '../../../../services/ChainService';

let clickCount = 0;

const ABIListItem = ({ handleClick, _c, selectChainIcon }) => {
  const collectionsState = useRecoilValue(CollectionListState);

  const renameInputRef = useRef(null);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [rename, setRename] = useState('');
  const [isRenameFieldOpen, setIsRenameFieldOpen] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation?.();
    setOpen(null);
  };

  /**
   * trigger rename ABI name
   * @param {object} e
   */
  const triggerRename = (e) => {
    clickCount += 1;
    if (clickCount === 2) {
      handleClick('');
      setIsRenameFieldOpen(true);
      TelemetryService.sendEvent(TelemetryEvents.ON_DBL_CLICK_RENAME);
    }
    setTimeout(() => {
      clickCount = 0;
    }, 350);
  };

  /**
   * rename ABI name
   * @param {object} e
   */
  const submitRename = (e) => {
    e?.stopPropagation?.();
    if (rename?.trim?.() && rename !== get(_c, 'name')) {
      const copyCollections = JSON.parse(JSON.stringify(collectionsState));
      const formatCollections = map(copyCollections, (list) => {
        const collectionId = get(_c, 'id');
        if (get(list, 'id') === collectionId) {
          set(list, 'meta.name', rename);
        }
        return list;
      });
      setIsRenameFieldOpen(false);
      CollectionService.replaceCollections(formatCollections);
    }
  };

  /**
   * cancel rename ABI name
   * @param {object} e
   */
  const cancelRename = (e) => {
    e?.stopPropagation?.();
    setRename(get(_c, 'meta.name'));
    setIsRenameFieldOpen(false);
  };

  useEffect(() => {
    setRename(get(_c, 'meta.name'));
  }, [_c]);

  const activeState = find(get(_c, 'meta.chainList'), (list) => {
    return list.isActive === true;
  });

  return (
    <ListItemButton
      key={_c.id}
      className="bg-secondary !border-b-2 !border-solid !border-[#d7d9d7] !p-2 !border-opacity-30"
      onClick={(e) => {
        e?.stopPropagation?.();
        setTimeout(() => {
          if (clickCount !== 2) {
            handleClick(_c.id);
          }
        }, 150);
      }}>
      <div
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            submitRename(event);
          }
        }}
        className="flex w-full pl-2 pr-2 items-center">
        <div className="flex flex-col w-[50px] flex-grow lg:w-[75%] ml-1.5">
          <div className="font-semibold text-lg w-full pt-1">
            {isRenameFieldOpen && (
              <div
                className="w-52 flex items-center opacity-70"
                onClick={(e) => {
                  e?.stopPropagation?.();
                }}>
                <Input
                  onChange={(e) => {
                    setRename(e.target.value);
                  }}
                  value={rename}
                  name="rename"
                  isDisabledLabel
                  size={INPUT_VARIANTS?.SMALL}
                  margin="mb-1"
                  ref={renameInputRef}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
                <HighlightOffIcon
                  onClick={cancelRename}
                  className="mb-1 ml-3 mr-1.5"
                  fontSize="small"
                  color="error"
                />
                <CheckCircleOutlineIcon
                  color="success"
                  onClick={submitRename}
                  className="mb-1"
                  fontSize="small"
                />
              </div>
            )}
            {!isRenameFieldOpen && (
              <div className="w-full" onClick={triggerRename}>
                <TextOverflowTruncate name={_c.meta?.name} />
              </div>
            )}
          </div>
          <div className="flex text-xs text-secondary items-center">
            <div className="mr-2 mb-2 flex">
              <CryptoIcon
                symbol={
                  !isNull(activeState?.network?.chainId)
                    ? getChainSymbol(activeState?.network?.chainId)
                    : 'NEAR'
                }
                size={16}
                className="mr-2"
              />
              <div className="font-medium text-sm">
                {activeState?.network?.chainName}
              </div>
            </div>

            {/* <Chip
              label={selectChainIcon(_c)?.symbolName}
              variant="outlined"
              size="small"
              sx={{
                height: '16px',
                fontSize: '0.55rem',
                color: `${selectChainIcon(_c)?.color}`,
                borderColor: `${selectChainIcon(_c)?.color}`,
              }}
            />
            <div>
              {trimFileName(
                CollectionService.getActiveContractAddress(_c?.meta),
                5,
                5
              )}
            </div> */}
          </div>
        </div>
        <div className="flex items-center justify-center ml-auto">
          <ShareABIs selectedCollection={_c} />
          <div>
            <Tooltip title="More Options">
              <IconWrapper>
                <MoreVertIcon
                  className="!w-full !h-full"
                  sx={{ opacity: '0.7' }}
                  fontSize="small"
                  ref={anchorRef}
                  onClick={handleOpen}
                />
              </IconWrapper>
            </Tooltip>
            <ABIListPopOver
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              _c={_c}
              setIsRenameFieldOpen={setIsRenameFieldOpen}
              renameInputRef={renameInputRef}
            />
          </div>
        </div>
      </div>
    </ListItemButton>
  );
};
export default ABIListItem;
