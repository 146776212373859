/**
 * get clone object or array
 * @param {(array|object)} data which is clone
 * @returns clone data
 */
const getClone = (data) => {
  if (!data) return null;
  return JSON.parse(JSON.stringify(data));
};

export default getClone;
