import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import noop from 'lodash/noop';
import { useMetaMask } from 'metamask-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { META_MASK } from '../constants/AppConstants';
import { TelemetryEvents } from '../constants/EventsConstants';
import Messages from '../constants/Messages';
import { sendToUpdateMetamaskChain } from '../services/EventService';
import TelemetryService from '../services/TelemetryService';
import CryptoIcon from './CryptoIcon';
import OverflowTruncate from './OverflowTruncate';

export const InstallMetaMask = ({ width }) => {
  return (
    <div className="flex justify-center items-center">
      <Tooltip title="Install Metamask">
        <a href={META_MASK.DOWNLOAD} target={'_blank'} rel="noreferrer">
          <Button
            sx={{
              width: width || '7rem p-2',
            }}
            variant="outlined"
            size="small"
            startIcon={<CryptoIcon symbol={'meta-icon'} size={20} />}>
            Install
          </Button>
        </a>
      </Tooltip>
    </div>
  );
};
const MetaMaskConnect = ({ width, chainId }) => {
  const { status, connect, account } = useMetaMask();

  if (status === 'initializing')
    return (
      <Button variant="outlined" size="small" onClick={noop}>
        Initializing Metamask
      </Button>
    );

  if (status === 'unavailable') {
    return <InstallMetaMask width={width} />;
  }

  if (status === 'notConnected') {
    return (
      <div className="flex justify-center items-center">
        <Tooltip title="Connect to Metamask">
          <Button
            sx={{
              width: width || '7rem p-2',
            }}
            variant="outlined"
            size="small"
            onClick={() => {
              TelemetryService.sendEvent(
                TelemetryEvents.ON_CLICK_CONNECT_WALLET,
                { wallet_name: 'metamask' }
              );
              connect().then(() => {
                setTimeout(() => {
                  sendToUpdateMetamaskChain(chainId);
                }, 1000);
              });
            }}
            startIcon={<CryptoIcon symbol={'meta-icon'} size={20} />}>
            Connect
          </Button>
        </Tooltip>
      </div>
    );
  }

  if (status === 'connecting')
    return (
      <Button variant="outlined" size="small" onClick={noop}>
        Connecting
      </Button>
    );

  return (
    <div className="flex">
      <CopyToClipboard
        text={account}
        onCopy={() => {
          toast.success(Messages.INFO.COPY_CLIPBOARD);
        }}>
        <Button
          sx={{
            width: '7rem p-2',
          }}
          variant="outlined"
          size="small"
          onClick={noop}>
          <CryptoIcon symbol={'meta-icon'} size={20} className="mr-2" />
          <OverflowTruncate name={account} />
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default MetaMaskConnect;
